import { TimeHTMLAttributes } from 'react';
import styles from './h-tag-fabric.module.scss';
import type { HTagFabricProps, HeadingAttributes } from './h-tag-fabric.types';


function getClassName(type: string, weight: number | string, tag: string) {
  if (type === 'title') {
    return `title-${tag}-weight-${weight}`;
  }
  if (type === 'subheader') {
    return `subheader-weight-${weight}`;
  }

  return '';
}

export const HTagFabric = ({
  type = 'title',
  className = '',
  weight = 1,
  tag = 'h3',
  children,
  ...props
}: HTagFabricProps): JSX.Element => {

  if (tag === 'h1') {
    const elementClasses = getClassName(type, weight, tag);

    return (
      <h1
        className={`${styles[elementClasses]} ${className}`}
        {...props as HeadingAttributes}
      >
        {children}
      </h1>
    );
  }

  if (tag === 'h2') {
    const elementClasses = getClassName(type, weight, tag);

    return (
      <h2
        className={`${styles[elementClasses]} ${className}`}
        {...props as HeadingAttributes}
      >
        {children}
      </h2>
    );
  }

  if (tag === 'h3') {
    const elementClasses = getClassName(type, weight, tag);

    return (
      <h3
        className={`${styles[elementClasses]} ${className}`}
        {...props as HeadingAttributes}
      >
        {children}
      </h3>
    );
  }

  if (tag === 'h4') {
    const elementClasses = getClassName(type, weight, tag);

    return (
      <h4
        className={`${styles[elementClasses]} ${className}`}
        {...props as HeadingAttributes}
      >
        {children}
      </h4>
    );
  }

  if (tag === 'h5') {
    const elementClasses = getClassName(type, weight, tag);

    return (
      <h5
        className={`${styles[elementClasses]} ${className}`}
        {...props as HeadingAttributes}
      >
        {children}
      </h5>
    );
  }

  if (tag === 'h6') {
    const elementClasses = getClassName(type, weight, tag);

    return (
      <h6
        className={`${styles[elementClasses]} ${className}`}
        {...props as HeadingAttributes}
      >
        {children}
      </h6>
    );
  }

  return <></>;
};
