import { useAppSelector } from './selector';

export const useEditorModeStateSelector = () => {
  return useAppSelector(({ user }) => user.isEditorMode);
};

export const useFavoriteObjectsSelector = () => {
  const favorites = useAppSelector(({ user }) => user.favoriteItems);

  return favorites.map((item) => Number(item));
};

export const useUserProfileInfo = () => useAppSelector(({ user }) => user.profile);

export const useAppStateUserRole = () => useAppSelector(({ user }) => user.role);

export const useIsClusterMarkerViewSelector = () =>
  useAppSelector(({ user }) => user.isClusterMarkerViewSelected);
