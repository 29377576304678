import type { TextElementProps } from './text-element.types';
import { TextElementFabric } from '../../utils';


export const SpanTag = (props: TextElementProps): JSX.Element => {
  return (
    <TextElementFabric tag='span' {...props}>
      {props.children}
    </TextElementFabric>
  );
};