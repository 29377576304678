import { Marker } from 'react-leaflet';
import styles from './layer-feature.styles.module.scss';
import { forwardRef, useEffect, useRef } from 'react';
import type { LayerFeatureTitleProps } from './layer-feature.types';
import L from 'leaflet';
import { Marker as MarkerType } from 'leaflet';

const MarkerElement = Marker as typeof Marker & any;

export const LayerFeatureTitle = forwardRef(
  (
    { coords, title = '', subheader = '', ...props }: LayerFeatureTitleProps,
    ref: any,
  ) => {
    useEffect(() => {
      if (ref.current) {
        ref.current.setIcon(
          L.divIcon({
            html: `
        <p class="${styles['layer-feature-title-p']}">
            ${title}
            ${
              !!subheader
                ? `
                <span class="${styles['layer-feature-title-p-subheader']}">
                    ${subheader}
                </span>`
                : ''
            }
        </p>`,
            className: styles['layer-feature-title'],
          }),
        );
      }
    }, [title, subheader]);

    return coords && <MarkerElement ref={ref} position={coords} {...props} />;
  },
);
