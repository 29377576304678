import { useEffect, useState } from 'react';


type TimeoutType = ReturnType<typeof setTimeout> | undefined;


export const useMountTransition = (isMounted: boolean, delay = 120) => {
  const [hasTransitionedIn, setHasTransitionedIn] = useState<boolean>(false);

  useEffect(() => {
    let timeoutId: TimeoutType;

    if (isMounted && !hasTransitionedIn) {
      timeoutId = setTimeout(() => setHasTransitionedIn(true), delay);
      // setHasTransitionedIn(true);
    } else if (!isMounted && hasTransitionedIn) {
      timeoutId = setTimeout(() => setHasTransitionedIn(false), delay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay, isMounted, hasTransitionedIn]);

  return hasTransitionedIn;
};