import { RefObject, useEffect } from 'react';

interface UseIntersectionObserverProps {
  target: RefObject<HTMLDivElement | null>;
  onIntersect: IntersectionObserverCallback;
  threshold?: number;
  rootMargin?: string;
}

export const useIntersectionObserver = ({
  target,
  onIntersect,
  threshold = 0.1,
  rootMargin = '0px',
}: UseIntersectionObserverProps) => {
  useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      rootMargin,
      threshold,
    });

    const { current } = target;

    if (current) {
      observer.observe(current);
      return () => {
        observer.unobserve(current);
      };
    }
  });
};
