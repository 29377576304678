import styles from './login-page.module.scss';
import loginPageBackground from '../../images/login-page-background.jpg';
import { LoginView } from '../../components';
import { useLoginUserMutation } from '../../state';
import type { LoginValues } from '../../types';


interface ErrorType {
  data: {
    message: string
  },
  status: number
}


export const LoginPage = (): JSX.Element => {
  const [loginUser, { isError, isLoading, isSuccess, error }] = useLoginUserMutation();

  const submitHandler = async (values: LoginValues) => {
    try {
      await  loginUser(values);
    } catch (e) {
      
    }
  };

  return (
    <>
      {/* <Helmet>
            <title>Вход</title>
        </Helmet> */}

      <div className={styles['login-wrapper']}>
        <img
          src={loginPageBackground}
          alt="Фоновая картинка"
          className={styles['login-background']}
        />
        {/* {!isMobile && (
          <div className={styles['login-logo']}>
            <img src={LogoDesktop} alt="Логотип" />
            <div className={styles['login-logo-text']}>
              Ваш комфорт —
              <br />
              наша работа!
            </div>
          </div>
        )} */}
        <LoginView
          error={error ? (error as ErrorType).data?.message : false}
          onLoginSubmit={submitHandler}
          isFormDisabled={isLoading}
        />
      </div>
    </>
  );
};
