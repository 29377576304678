import { memo } from 'react';
import './content.style.scss';
import { ObjectMarker } from '../marker';
import type { ObjectInterface } from '../../../../types';
import { useSelectObject } from '../../../../hooks/depended/useSelectObject';
import type { MarkerContentProps } from './content.types';
import { selectMarkerIconHandler } from 'utils';

export const MarkerContent = memo(
  ({
    items,
    types,
    dotsView = false,
    showName = true,
    isGazpromChild,
  }: MarkerContentProps) => {
    const [selectedObjectId, handleMarkerClick] = useSelectObject();

    return (
      <>
        {items.length &&
          items.map((item: ObjectInterface) => (
            <ObjectMarker
              key={item.id}
              item={item}
              icon={selectMarkerIconHandler(item.national_project_id)}
              isGazpromChild={isGazpromChild}
              type={types.find((el) => el.id === item.type_id)}
              onClick={handleMarkerClick}
              selected={item.id === selectedObjectId}
              macro={item.macro_region}
              dotsView={dotsView}
              showName={showName}
            />
          ))}
      </>
    );
  },
);
