import React, { ReactElement, useCallback, useMemo } from 'react';
import styles from './button.module.scss';
import cn from 'classnames';
import { createButton } from '../../utils';
import { ButtonProps } from '../../utils/button-fabric/button-fabric.types';


export const Button = ({
  onlyIcon = false,
  showLeftIcon = true,
  showRightIcon = false,
  leftIcon,
  rightIcon,
  elementType = 'button',
  children,
  className = '',
  isActive = false,
  text,
  refs,
  justifyContent = 'center',
  rounded = true,
  withBorder = false,
  typeBtn = 'primary',
  size = 'l',
  currentTitle,
  maxWidth = 'fill',
  ...rest
}: ButtonProps): JSX.Element => {
  const content = (
    <>
      {showLeftIcon && leftIcon}
      {text && !onlyIcon && <span className={styles['button-text']}>{text}</span>}
      {children && !onlyIcon && children}
      {showRightIcon && rightIcon}
    </>
  );
  
  const classNames = cn(styles.button, className, {
    [`${styles.onlyIcon}`]: onlyIcon,
    [`${styles.primary}`]: typeBtn === 'primary',
    [`${styles.secondary}`]: typeBtn === 'secondary',
    [`${styles.danger}`]: typeBtn === 'danger',
    [`${styles.dangerUncolored}`]: typeBtn === 'danger-uncolored',
    [`${styles.uncolored}`]: typeBtn === 'uncolored',
    [`${styles.elevation}`]: typeBtn === 'elevation',
    [`${styles.score}`]: typeBtn === 'primary-score',
    [`${styles.small}`]: size === 's',
    [`${styles.medium}`]: size === 'm',
    [`${styles.large}`]: size === 'l',
    [`${styles.rounded}`]: rounded,
    [`${styles.active}`]: isActive,
    [`${styles.withBorder}`]: withBorder,
    [`${styles.contentEnd}`]: justifyContent === 'end',
    [`${styles.contentStart}`]: justifyContent === 'start',
    [`${styles['w-content']}`]: maxWidth === 'content',
    
  });
  
  
  const SimpleLink = createButton(
    {
      elementType: elementType,
      classNames,
      content,
      currentTitle, 
    }) as () => ReactElement;
  
  return <SimpleLink {...rest} />;
};
