import * as Yup from 'yup';

export const profileValidationSchema = Yup.object({
  firstName: Yup.string()
    .min(2, 'Минимальное колличество символов - 2')
    .max(30, 'Максимальное колличество символов - 30'),
  lastName: Yup.string()
    .min(2, 'Минимальное колличество символов - 2')
    .max(30, 'Максимальное колличество символов - 30'),
  middleName: Yup.string()
    .min(2, 'Минимальное колличество символов - 2')
    .max(30, 'Максимальное колличество символов - 30'),
  password: Yup.string().min(2, 'Минимальное колличество символов - 2'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Пароли не совпадают'),
  username: Yup.string()
    .required('Обязательное поле')
    .min(3, 'Минимальное количество символов - 3'),
});
