import styles from './status-switcher.module.scss';
import { Switch } from '../common';
import { IStatusSwitcherProps } from './status-switcher.types';
import { IconProgress } from '../sidebar-filter/progress-icon';


export const StatusSwitcher = ({ editorMode, loading, checked, onChangeStatus }: IStatusSwitcherProps) => {
  
  const handleChangeStatus = (value : boolean) => {
    onChangeStatus(value);
  };
  
  
  return (
    <>
      {editorMode && <div className={styles['status-switcher']}>
        {loading ?
        <div className={styles['status-loading']}>
          <IconProgress  stroke={'#000000'}/>
        </div>
          :
        <Switch checked={checked} onToggle={handleChangeStatus} showLabel text={'Объект готов к демонстрации'} id={'status-switcher'}/>
        }
      </div>}
    
    </>
  );
};