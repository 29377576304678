import { createContext, useContext, useState } from 'react';
import type { Map } from 'leaflet';


type MapContextType = {
  map: Map | null,
  setMap:(m: Map | null) => void
};

export const MapContext = createContext<MapContextType>({
  map: null, 
  setMap: (m: Map | null) => {},
});

export const useMap = () => useContext(MapContext);


export const MapProvider = ({ children }: { children: JSX.Element }) => {
  const [map, setMap] = useState<Map | null>(null);
  
  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  );
};