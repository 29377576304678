import styles from './header-media.module.scss';
import { HeaderMediaProps } from './header-media.types';
import { Button, HeaderContainer } from '../../common';
import { ViewingContent } from '../../viewing-content';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CalendarMedia } from '../../calendar-media';
import { MapProfileMenu as ProfileMenu } from 'components/profile-menu';
import defaultProfileImage from 'images/default_avatar.png';
import {
  useObjectByProjectIdSelector,
  useProjectByIdSelector,
  useUserProfileInfo,
} from '../../../state';
import {
  LINK_MAP_OBJECT_GROUP_PATH,
  LINK_MAP_OBJECT_PATH,
  LINK_MAP_OBJECT_SEARCH_PARAMS_PATH,
  Paths,
  ScreenSize,
} from '../../../constants';
import { useBreakpointsComparison } from '../../../hooks';
import { useFlyToObjectOnMap } from '../../../hooks';
import { useCallback } from 'react';

export const HeaderMedia = ({
  children,
  showSideBar,
  isSidebarOpened = false,
}: HeaderMediaProps) => {
  const location = useLocation();
  const user = useUserProfileInfo();
  const navigate = useNavigate();
  const isMobile = useBreakpointsComparison(ScreenSize.MP);
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);

  const { objectID, projectID, groupID } = useParams();
  const flyTo = useFlyToObjectOnMap();
  const project = useProjectByIdSelector(projectID);
  const object = useObjectByProjectIdSelector(projectID);

  const getBackLink = () => {
    if (objectID) {
      if (groupID) {
        return LINK_MAP_OBJECT_GROUP_PATH(objectID, String(projectID));
      }
      return LINK_MAP_OBJECT_PATH(objectID);
    }

    return Paths.MAIN_PAGE;
  };

  const buttonSize = () => {
    if (isMobile) return 's';
    if (isTabletLandscape) return 'm';
    return 'l';
  };

  const handleClose = useCallback(() => {
    navigate({ pathname: getBackLink() });
  }, [objectID, projectID]);

  return (
    <HeaderContainer>
      <div
        className={`
        ${styles.wrapper} 
        ${styles['wrapper-content-start']}
      `}
      >
        {!isTabletLandscape ? (
          <ProfileMenu
            src={defaultProfileImage}
            name={user?.first_name}
            email={user?.username}
          />
        ) : (
          <CalendarMedia location={location} />
        )}
      </div>
      <div className={styles.wrapper}>
        <ViewingContent />
      </div>
      <div
        className={`
          ${styles.wrapper} 
          ${styles['wrapper-right-buttons-container']} 
        `}
      >
        {!isTabletLandscape && (
          <Button
            className={styles['wrapper-zones']}
            size={'l'}
            onClick={() => showSideBar && showSideBar(!isSidebarOpened)}
            elementType={'button'}
            typeBtn={'elevation'}
            text={'Зоны'}
            showRightIcon={true}
            rightIcon={
              isSidebarOpened ? (
                <i className="icon icon-close-3 icon-middle"></i>
              ) : (
                <i className="icon icon-list icon-middle"></i>
              )
            }
            maxWidth={'content'}
          />
        )}
        {!isTabletLandscape && <CalendarMedia location={location} />}
        <Button
          className={styles['wrapper-close-button']}
          size={buttonSize()}
          elementType={'button'}
          onClick={handleClose}
          typeBtn={'elevation'}
          onlyIcon
          showLeftIcon
          leftIcon={
            <i
              className={`icon icon-close-3 icon-${
                isTabletLandscape ? 'tiny' : 'middle'
              }`}
            ></i>
          }
        />
      </div>
    </HeaderContainer>
  );
};
