export const setInfoTemplateString = (arrayOfString: string[]) => {
  if (!arrayOfString || !arrayOfString.length) {
    return '';
  }
  return `${arrayOfString
    .map((str) => {
      if (!str.trim().length) {
        return '';
      } else {
        return `<p>${str}</p><br>`;
      }
    })
    .filter((el) => !!el.length)
    .join('')}`;
};
