import React from 'react';
import './spiner.scss';


const Spinner = () => {
  return (
    <div className="spinner">
      <div className="spinner-content"></div>
    </div>
  );
};
export default Spinner;
 