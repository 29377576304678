import { IconNavArrowProps } from './nav-arrow-icon.types';


export const IconNavArrow = ({ className } : IconNavArrowProps) => {
  return (
    <svg className={className} width="16" height="30" viewBox="0 0 16 30" fill='none'>
      <path d="M0.570816 28.8813C0.0800022 28.3905 0.0353828 27.6225 0.436958 27.0813L0.570816 26.9262L12.4958 15.0006L0.570815 3.0749C0.0800011 2.58408 0.0353816 1.81604 0.436957 1.27482L0.570815 1.11977C1.06163 0.628952 1.82967 0.584332 2.37089 0.985907L2.52595 1.11977L15.4292 14.023C15.92 14.5138 15.9646 15.2818 15.563 15.8231L15.4292 15.9781L2.52595 28.8813C1.98605 29.4212 1.11071 29.4212 0.570816 28.8813Z" />
    </svg>

  );
};