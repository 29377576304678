import React from 'react';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import './react-datepicker-styles.scss';
import './date-picker.scss';
import ru from 'date-fns/locale/ru';
import { DatePickerProps } from './date-picker-props';

registerLocale('ru', ru);
setDefaultLocale('ru');

export const DatePicker: React.FC<DatePickerProps> = ({
  className = '',
  onChange,
  ...rest
}) => {
  return (
    <div className={`date-picker ${className}`}>
      <ReactDatePicker
        dateFormat="dd.MM.yyyy"
        onChange={onChange}
        locale={'ru'}
        {...rest}
      />
    </div>
  );
};
