import { useCallback, useEffect, DependencyList } from 'react';


export const useDebouncedEffect = <E>(effect: () => E, deps: DependencyList, delay = 400) => {
  const callback = useCallback<() => E>(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};