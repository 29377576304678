import { useRef, useState } from 'react';
import { GeoJson } from '../geo-json';
import styles from './layer-feature.styles.module.scss';
import { LeafletMouseEvent } from 'leaflet';
import { getRateTextValue } from '../../../../utils';
import {
  setHoverFillColor,
  setHoverStrokeColor,
  setFillColor,
  setStrokeColor,
} from './utils';
import { FeatureTooltip } from '../feature-tooltip';
import { useEnableObjectFiltersSelector } from '../../../../state';
import { LayerFeatureTitle } from './layer-feature-title';
import { Marker as MarkerType } from 'leaflet';
import type { LayerFeatureProps } from './layer-feature.types';

export const LayerFeature = ({
  data,
  featureId,
  name,
  coords,
  getFeatureRating,
  info,
  className = '',
  isVisible = true,
  isGazpromChildActive,
  onClick = () => {},
}: LayerFeatureProps) => {
  const [isHover, setHover] = useState<boolean>(false);
  const titleRef = useRef<MarkerType | null>(null);
  const { degree_of_satisfaction: degreeOfSatisfaction } =
    useEnableObjectFiltersSelector();

  const rating = getFeatureRating();

  const popupHandler = (feature: any, layer: any) => {
    layer.on('click', (e: LeafletMouseEvent) => {
      const { lat, lng } = e.latlng;
      onClick([lat, lng]);
    });
    layer.on('mouseover', (e: LeafletMouseEvent) => {
      setHover(true);
    });
    layer.on('mouseout', (e: LeafletMouseEvent) => {
      setHover(false);
    });
  };

  return (
    <GeoJson
      className={`${styles['layer-feature']} ${className}`}
      data={data}
      fillOpacity={0.4}
      fillColor={
        isHover
          ? setHoverFillColor(rating, isGazpromChildActive)
          : setFillColor(rating, isGazpromChildActive)
      }
      color={
        isHover
          ? setHoverStrokeColor(rating, isGazpromChildActive)
          : setStrokeColor(rating, isGazpromChildActive)
      }
      isVisible={
        isVisible &&
        degreeOfSatisfaction.min <= Math.floor(rating) &&
        degreeOfSatisfaction.max >= Math.floor(rating)
      }
      weight={1}
      onEachFeature={popupHandler}
    >
      <LayerFeatureTitle
        onMouseEnter={() => setHover(true)}
        coords={coords}
        title={name}
        subheader={
          !!name ? getRateTextValue(Math.floor(rating)) : String(Math.floor(rating))
        }
        ref={titleRef}
      />
      <FeatureTooltip rate={Math.floor(rating)} name={name} body={info} />
    </GeoJson>
  );
};
