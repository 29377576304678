import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapInitialState as initialState } from './initial-state';
import type { LayerType } from '../../types';


const mapSlice = createSlice({
  initialState,
  name: 'map',
  reducers: {
    setMapLayer: (state, { payload }: PayloadAction<LayerType>) => {
      state.layer = payload;
    },
    getClickPosition: (state, { payload }: PayloadAction<any>) => {
      state.clickPosition = payload;
    },
    
    setMouseZoomActivity: (state, { payload }: PayloadAction<boolean>) => {
      state.isMouseZoomActive = payload;
    },
  },
});

export const { 
  getClickPosition, 
  setMouseZoomActivity,
  setMapLayer,
} = mapSlice.actions;

export const { reducer: mapReducer } = mapSlice;
