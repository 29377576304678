export const formatObjectToQueryString = (object: any) => {
  let queryString = '';

  object &&
    Object.entries(object).forEach(([key, value]: [string, unknown]) => {
      if (value !== undefined && value !== null && value !== '') {
        queryString += `${key}=${value as string}&`;
      }
    });
  return queryString;
};
