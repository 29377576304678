import { memo } from 'react';
import styles from './filter-checkbox.module.scss';
import { FilterCheckboxProps } from './filter-checkbox.types';
import { getCssVariableName } from '../../../utils';
import { ToolTip } from '../tooltip';
import { SpanTag } from '../text-element';

export const FilterCheckbox = memo<FilterCheckboxProps>(
  ({
    handleChange,
    isChecked = false,
    leftBorderColor = '',
    children,
    onClick,
    style,
    disabled = false,
    type = 'button',
    className = '',
  }: FilterCheckboxProps): JSX.Element => {
    return (
      <li onClick={onClick} className={`${styles.container} ${className}`}>
        <ToolTip
          className={styles['container-tooltip']}
          isVisible={typeof children === 'string' && children.length > 43}
          content={children}
        >
          <label
            className={`${styles.label} ${
              isChecked && type === 'button' ? styles.activeLabel : ''
            } label`}
            style={{ ...style }}
          >
            {type === 'checkbox' && (
              <svg
                className={`${styles['label-checkbox-icon']} ${
                  isChecked ? styles['label-checkbox-icon-active'] : ''
                }`}
                aria-hidden="true"
                viewBox="0 0 15 11"
                fill="none"
              >
                <path
                  d="M1 4.5L5 9L14 1"
                  strokeWidth="2"
                  stroke={isChecked ? '#fff' : 'none'}
                />
              </svg>
            )}
            {typeof children === 'string' && children.length > 43
              ? `${children.slice(0, 43)}...`
              : children}
            <input
              disabled={disabled}
              checked={isChecked}
              onChange={() => handleChange()}
              className={`${styles[`label-input-${type}`]} input`}
              type="checkbox"
            />
            <span className={`${styles[`label-checkmark-${type}`]} checkmark`} />
            {!!leftBorderColor && (
              <SpanTag
                className={styles['label-left-border']}
                style={{
                  backgroundColor: styles[getCssVariableName(leftBorderColor)],
                }}
              />
            )}
          </label>
        </ToolTip>
      </li>
    );
  },
  (prevState, newState): boolean => {
    if (prevState.isChecked !== newState.isChecked) return false;
    if (prevState.handleChange !== newState.handleChange) return false;
    return true;
  },
);
