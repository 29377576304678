import type { TextElementProps } from './text-element.types';
import { TextElementFabric } from '../../utils';


export const SubTag = (props: TextElementProps): JSX.Element => {
  return (
    <TextElementFabric tag='sub' {...props}>
      {props.children}
    </TextElementFabric>
  );
};