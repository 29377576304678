import { api } from './api';
import type { Media, ObjectInterface, ObjectProgram, ObjectType } from '../../types';

export const objectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getObjects: builder.mutation<{ status: number; data: any }, void>({
      query() {
        return {
          url: '/object',
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getObjectMedia: builder.mutation<any, string>({
      query(path: string) {
        return {
          url: `/media?media_path=${encodeURIComponent(path)}`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Media'],
      // transformResponse: (response: { data: any }, meta, arg) => response.data.blob(),
    }),
    getBaseObjects: builder.mutation<{ status: number; data: ObjectInterface[] }, void>({
      query() {
        return {
          url: '/obj?type=2&real_status=2',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
    getObjectsDepartments: builder.mutation<any, void>({
      query() {
        return {
          url: '/department',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
    getObjectsSubsidiary: builder.mutation<any, void>({
      query() {
        return {
          url: '/subsidiary',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
    getObjectsTypes: builder.mutation<ObjectType[], void>({
      query() {
        return {
          url: '/obj-type',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
    getObjectsProgram: builder.mutation<ObjectProgram[], void>({
      query() {
        return {
          url: 'obj-program',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
    addObject: builder.mutation<any, any>({
      query(body) {
        return {
          url: '/obj',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
    updateObjectData: builder.mutation<ObjectInterface[], UpdateObjectDataType>({
      query(body) {
        return {
          url: '/obj/update-field',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
    deleteObject: builder.mutation<any, any>({
      query({ id }) {
        return {
          url: `/obj/${id as string}`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
    updateObjectCoords: builder.mutation<any, any>({
      query({ id, value }) {
        return {
          url: '/obj/update-field',
          method: 'PUT',
          body: {
            id,
            value,
            type: 'coords',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
    addObjectImage: builder.mutation<AddObjectImageRespense, AddObjectImageType>({
      query({ image, id }) {
        const body = new FormData();
        body.append('img', image);
        body.append('obj_id', String(id));

        return {
          url: '/obj-media',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
    deleteObjectImage: builder.mutation<Media, { id: number }>({
      query({ id }) {
        return {
          url: `/obj-media/${id}`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Object'],
    }),
  }),
});

export interface UpdateObjectDataType {
  value: any;
  id: number;
  type: string;
}

interface AddObjectImageType {
  id: number;
  image: File;
}

interface AddObjectImageRespense {
  id: number;
  path: string;
}

export const {
  useGetObjectsMutation,
  useGetObjectMediaMutation,
  useGetBaseObjectsMutation,
  useGetObjectsTypesMutation,
  useAddObjectMutation,
  useGetObjectsProgramMutation,
  useUpdateObjectDataMutation,
  useAddObjectImageMutation,
  useDeleteObjectImageMutation,
  useGetObjectsDepartmentsMutation,
  useGetObjectsSubsidiaryMutation,
  useUpdateObjectCoordsMutation,
  useDeleteObjectMutation,
} = objectApi;
