import './creation-form.scss';
import SelectSearch from 'react-select-search';


const CreationFormSelect = props => {
  const { label, placeholder, value, onChange, options, name, isError, error, handleFilter, ...rest } = props;

  return (
    <label className="creation-form-input-container">
      <span className={`${isError ? 'creation-form-label-error' : ''}`}>{label}</span>
      <SelectSearch search filterOptions={handleFilter} options={options} name={name} onChange={onChange} value={value} placeholder={placeholder} />
      {isError && (
        <span className="creation-form-invalid-field">{error}</span>
      )}
    </label>
  );
};

export default CreationFormSelect;
