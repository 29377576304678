import type { ObjectsStateType } from '../../../types';
import { RangeValues } from '../../../constants';

export const objectInitialState: ObjectsStateType = {
  items: [],
  types: [],
  programs: [],
  departmants: [],
  subsidiary: [],
  selectedItem: null,
  filters: {
    macro_region: [],
    status: [],
    program: [],
    start_of_building: [],
    type_of_work: [],
    showOnlyFavorite: false,
    national_projects: [],
    directions: [],
    degree_of_satisfaction: { min: RangeValues.MIN, max: RangeValues.MAX },
    gazprom: [],
  },
  errors: {
    updateObject: null,
  },
};
