import { useState } from 'react';
import styles from './form-input.module.scss';
import { FormInputProps } from './form-input-types';


export const FormInput = ({ 
  isPassword = false, 
  label, 
  placeholder, 
  value = '', 
  type = 'text', 
  name,
  autoComplete = 'off', 
  onChange,
  ...props }: FormInputProps) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const passwordShow = isPasswordShow ? 'text' : 'password';


  return (
    <label className={styles['profile-form-input-container']}>
      {label}
      <input
        className={styles['profile-form-input']}
        type={isPassword ? passwordShow : type}
        placeholder={placeholder}
        value={value}
        name={name}
        autoComplete={autoComplete}
        onChange={onChange}
        {...props}
      />
      {isPassword && (
        <>
          <div
            className={styles[`profile-form-input-icon-password ${isPasswordShow ? 'hide' : 'show'}`]}
            onClick={() => setIsPasswordShow(!isPasswordShow)}
          />
        </>
      )}
    </label>
  );
};
