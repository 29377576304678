export const nationalProjects = [
  {
    value: 'Здравоохранение',
    id: 0,
  },
  {
    value: 'Демография',
    id: 1,
  },
  {
    value: 'Образование',
    id: 2,
  },
  {
    value: 'Жилье и городская среда',
    id: 3,
  },
  {
    value: 'Наука и университеты',
    id: 4,
  },
  {
    value: 'Туризм и индустрия гостеприимства',
    id: 5,
  },
  {
    value: 'Культура',
    id: 6,
  },
  {
    value: 'Экология',
    id: 7,
  },
]
