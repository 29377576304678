import styles from './analytics-view.module.scss';
import { Button, HeaderContainer } from '../../common';
import { setAnalyticsViewOpen } from '../../../state';
import { useAppDispatch } from '../../../state/selector';

export const AnalyticsViewHeader = () => {
  const dispatch = useAppDispatch();

  return (
    <HeaderContainer
      isSidebarOpened={true}
      className={styles['content-wrapper-container']}
    >
      <div
        style={{ justifyContent: 'start' }}
        className={`${styles['header-wrapper']} ${styles['content-wrapper-start']}`}
      ></div>
      <div className={styles['header-wrapper']}></div>
      <div className={styles['right-btns-wrapper']}>
        <div className={styles['view-switcher-wrapper']}></div>
        <Button
          size={'l'}
          style={{ width: 'fit-content' }}
          onClick={() => dispatch(setAnalyticsViewOpen(false))}
          elementType={'button'}
          typeBtn={'elevation'}
          text={'Закрыть'}
          showLeftIcon={true}
          leftIcon={<i className="icon icon-close-3 icon-middle"></i>}
        />
      </div>
    </HeaderContainer>
  );
};
