import { AvatarIconsProps } from './avatar-icons.types';


export const IconCamera = ({ className }: AvatarIconsProps)  =>{
  return (
    <svg className={className} width="20" height="18" viewBox="0 0 20 18" fill="#FCFCFC">
      <path d="M15.5043 5.59131C16.0566 5.59131 16.5043 6.03902 16.5043 6.59131C16.5043 7.10414 16.1183 7.52682 15.6209 7.58458L15.5043 7.59131C14.943 7.59131 14.4953 7.14359 14.4953 6.59131C14.4953 6.07847 14.8813 5.6558 15.3787 5.59804L15.5043 5.59131Z" fill="#FCFCFC"/>
      <path d="M9.99968 6.29053C7.82946 6.29053 6.07068 8.04931 6.07068 10.2195C6.07068 12.3897 7.82946 14.1485 9.99968 14.1485C12.1699 14.1485 13.9287 12.3897 13.9287 10.2195C13.9287 8.04931 12.1699 6.29053 9.99968 6.29053ZM9.99968 7.79053C11.3415 7.79053 12.4287 8.87774 12.4287 10.2195C12.4287 11.5613 11.3415 12.6485 9.99968 12.6485C8.65789 12.6485 7.57068 11.5613 7.57068 10.2195C7.57068 8.87774 8.65789 7.79053 9.99968 7.79053Z"/>
      <path d="M5.19382 1.92301C5.59577 1.19248 6.03303 0.704137 6.68188 0.445882C7.87089 -0.0268983 12.1293 -0.0268983 13.3174 0.445976C13.9659 0.704092 14.4033 1.19235 14.8055 1.92273L15.2578 2.78988C15.2681 2.80707 15.2776 2.82217 15.2862 2.83518L15.3246 2.88768C15.5306 3.11218 15.8754 3.24781 16.103 3.24781C18.255 3.24781 20 4.9924 20 7.14381V12.9388C20 15.683 17.7742 17.9088 15.03 17.9088H4.97C2.2251 17.9088 0 15.6833 0 12.9388V7.14381C0 4.9924 1.74498 3.24781 3.897 3.24781C4.12377 3.24781 4.46908 3.11199 4.67544 2.88768C4.67956 2.88319 4.68494 2.8765 4.69145 2.86764L4.74287 2.78876L5.19382 1.92301ZM13.4917 2.6464C13.2417 2.19256 13.0158 1.94041 12.7626 1.83965L12.6433 1.79999C11.6142 1.5091 8.03441 1.52232 7.23635 1.83965C6.98334 1.94036 6.75768 2.19237 6.50802 2.64613L6.12316 3.39022L6.02843 3.56165C5.94936 3.69318 5.87026 3.80421 5.77995 3.90261C5.27349 4.45312 4.52425 4.74782 3.897 4.74782L3.7329 4.75335C2.48575 4.83768 1.5 5.87604 1.5 7.14382V12.9388C1.5 14.855 3.05359 16.4088 4.97 16.4088H15.03C16.9458 16.4088 18.5 14.8546 18.5 12.9388V7.14382C18.5 5.82092 17.4267 4.74782 16.103 4.74782L15.9289 4.74054C15.3403 4.69211 14.6793 4.40298 14.2194 3.90195C14.1303 3.80485 14.0517 3.69461 13.9731 3.56419C13.9618 3.54548 13.9478 3.52085 13.9317 3.49171L13.8156 3.27396L13.4917 2.6464Z"/>
    </svg>
  );
};

export const IconEmptyProfile = ({ className }: AvatarIconsProps)  =>{
  return (
    <svg className={className} width="22" height="27" viewBox="0 0 22 27" fill="#06080E">
      <path d="M22 22.0037C22 26.4823 15.7222 27 11.0014 27L10.6636 26.9997C7.65584 26.9926 0 26.8068 0 21.9765C0 17.5893 6.02549 17.003 10.7104 16.9808L11.3392 16.9805C14.3467 16.9876 22 17.1734 22 22.0037ZM11.0014 19.0184C5.08333 19.0184 2.08333 20.013 2.08333 21.9765C2.08333 23.9576 5.08333 24.9618 11.0014 24.9618C16.9181 24.9618 19.9167 23.9671 19.9167 22.0037C19.9167 20.0225 16.9181 19.0184 11.0014 19.0184ZM11.0014 0C15.0681 0 18.375 3.23667 18.375 7.21526C18.375 11.1938 15.0681 14.4292 11.0014 14.4292H10.9569C6.89861 14.4169 3.61111 11.1789 3.62496 7.21118C3.62496 3.23667 6.93333 0 11.0014 0ZM11.0014 1.94037C8.02778 1.94037 5.6083 4.30605 5.6083 7.21526C5.59861 10.1149 8 12.4793 10.9611 12.4901H11.0014C13.9736 12.4901 16.3917 10.1231 16.3917 7.21526C16.3917 4.30605 13.9736 1.94037 11.0014 1.94037Z"/>
    </svg>
  );
};

export const IconClose = ({ className }: AvatarIconsProps)  =>{
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="#FCFCFC">
      <path d="M15.0711 2.19067C15.4195 1.84225 15.4195 1.27734 15.0711 0.92892C14.7226 0.580497 14.1577 0.580497 13.8093 0.92892L7.99998 6.73825L2.19067 0.928937C1.84225 0.580514 1.27734 0.580515 0.92892 0.928938C0.580497 1.27736 0.580497 1.84227 0.92892 2.19069L6.73823 8L0.928932 13.8093C0.58051 14.1577 0.580508 14.7226 0.928931 15.0711C1.27735 15.4195 1.84226 15.4195 2.19068 15.0711L7.99998 9.26175L13.8093 15.0711C14.1577 15.4195 14.7226 15.4195 15.0711 15.0711C15.4195 14.7227 15.4195 14.1577 15.0711 13.8093L9.26174 8L15.0711 2.19067Z" />
    </svg>
  );
};


