import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userApi } from '../api/user-api';
import { userInitialState as initialState } from './initial-state';
import type { TokenType } from '../../types';

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    logout: () => initialState,
    login: (state, { payload }: PayloadAction<TokenType>) => {
      state.token.accessToken = payload.access_token;
      state.token.refreshToken = payload.refresh_token;
    },
    addTokens: (
      state,
      { payload }: PayloadAction<{ accessToken: string; refreshToken: string }>,
    ) => {
      const { accessToken, refreshToken } = payload;
      state.token = {
        accessToken,
        refreshToken,
      };
    },
    toggleEditorMode: (state) => {
      state.isEditorMode = !state.isEditorMode;
    },
    setClusterMarkerView: (state, { payload }) => {
      state.isClusterMarkerViewSelected = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.loginUser.matchFulfilled,
      (state, { payload }) => {
        state.token.accessToken = payload.access_token;
        state.token.refreshToken = payload.refresh_token;
      },
    );
    builder.addMatcher(
      userApi.endpoints.getUserInfo.matchFulfilled,
      (state, { payload }) => {
        state.profile = payload;
      },
    );
    // builder.addMatcher(
    //   userApi.endpoints.addUserProfileImage.matchFulfilled,
    //   (state, { payload }: any) => {
    //     if (state.profile) state.profile.profile_image = payload.data.path;
    //   },
    // );
    builder.addMatcher(
      userApi.endpoints.updateUserData.matchFulfilled,
      (state, { payload }: any) => {
        state.profile = payload.data;
      },
    );
    builder.addMatcher(
      userApi.endpoints.getFavoriteUserItems.matchFulfilled,
      (state, { payload }) => {
        state.favoriteItems = payload;
      },
    );
    builder.addMatcher(
      userApi.endpoints.toggleFavoriteUserItem.matchFulfilled,
      (state, { payload }: any) => {
        state.favoriteItems = payload;
      },
    );
  },
});

export const { reducer: userReducer } = userSlice;
export const { logout, login, toggleEditorMode, setClusterMarkerView, addTokens } =
  userSlice.actions;
