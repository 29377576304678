import React, { useRef, useState, memo, useEffect } from 'react';
import { Formik, FormikHelpers, Field, Form, FieldProps, FormikProps } from 'formik';
import { validationSchema } from '../../utils';
import styles from './login-view.module.scss';
import Logo from '../../../images/gazprom-blue-logo.svg';
import { LoginViewProps } from './login-view.types';
import { H1Tag, PasswordInput, PTag, SearchInput, Input } from '../../common';
import { Button } from '../../common/button';
import { useAppSelector } from '../../../state';
import { LoginValues } from '../../../types';
import { IconProgress } from '../../sidebar-filter/progress-icon';
import logo from '../../../images/logo-CPS.svg';

export const LoginView = memo(
  ({
    error = false,
    onLoginSubmit,
    isFormDisabled = false,
    titleText = 'Добро пожаловать',
  }: LoginViewProps): JSX.Element => {
    const initialValues = {
      username: '',
      password: '',
    } as LoginValues;

    const onSubmit = async (values: LoginValues, actions: FormikHelpers<LoginValues>) => {
      return onLoginSubmit(values, actions);
    };

    const checkValidateWarningMessage = (obj: Record<string, unknown>, key: string) => {
      const isWarning = !Object.keys(obj).length;
      if (isWarning) return obj[key];
      return false;
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ isValid, dirty, ...rest }: FormikProps<any>) => (
          <Form className={styles['login-form']}>
            <div>
              <img className={styles['login-form-logo']} src={logo} alt="Лого" />
              <H1Tag>{titleText}</H1Tag>
              <PTag>Рады видеть Вас снова!</PTag>
              <Field name={'username'}>
                {/* <Field name={'username'}> */}
                {({ field, form: { touched, errors }, meta }: FieldProps) => (
                  <Input
                    {...field}
                    type={'text'}
                    label="Имя пользователя"
                    placeholder="Ввести имя"
                    disabled={isFormDisabled}
                    error={!!meta.error ? meta.error : null}
                  />
                )}
              </Field>
              <Field name={'password'}>
                {({ field, form: { touched, errors }, meta }: FieldProps) => (
                  <PasswordInput
                    {...field}
                    label="Пароль"
                    placeholder="Ввести пароль"
                    disabled={isFormDisabled}
                    error={!!meta.error ? meta.error : null}
                  />
                )}
              </Field>
              {/*<Button*/}
              {/*  className={styles['login-form-button']}*/}
              {/*  elementType="button"*/}
              {/*  typeBtn="primary"*/}
              {/*  refs={btnRef}*/}
              {/*  type="submit"*/}
              {/*  showLeftIcon={isFormDisabled}*/}
              {/*  leftIcon={<IconProgress stroke={'#000'}/>}*/}
              {/*  // rightIcon={<i className="icon icon-comand icon-big"></i>}*/}
              {/*  disabled={!(isValid && dirty) || isFormDisabled || false}*/}
              {/*>*/}
              {/*  Войти*/}
              {/*</Button>*/}
              <button
                type="submit"
                className={styles['login-form-button']}
                disabled={!(isValid && dirty) || isFormDisabled || false}
              >
                {isFormDisabled && <IconProgress stroke={'#000'} />}
                Войти
              </button>

              {!!error && <PTag className={styles['login-form-error']}>{error}</PTag>}
            </div>
            {/*
      {isMobile && (
        <div className={styles['login-logo']}>
          <img src={Logo} alt="Логотип" />
          <div className={styles['login-logo-text']}>
            Ваш комфорт —
            <br />
            наша работа!
          </div>
        </div>
      )} */}
          </Form>
        )}
      </Formik>
    );
  },
);
