import React from 'react';
import { useAppDispatch } from '../../state';
import { IServerErrorProps } from './server-error-modal.types';
import { useObjectUpdateErrorSelector } from '../../state/selector';
import { Button } from '../common';
import styles from './server-error-modal.module.scss';


export const ServerErrorModal = ({ isError, onClose }:IServerErrorProps): JSX.Element => {

  const dispatch = useAppDispatch();

  const errorStatus = useObjectUpdateErrorSelector() as number;

  const errorNumber = isError ? errorStatus : null;

  const chooseErrorMessage = (value: number | null) => {
    switch (errorNumber) {
      case 422:
        return 'Ошибка! Превышен лимит по весу или загружен файл неверного формата. Допустимый формат фотографии: jpg, png, jpeg. Размер файла не должен превышать 15 МБ';
      case 500:
        return 'Сервис временно не доступен, попробуйте позже';
      default:
        // return 'Ой, ошибка! Попробуйте позже или перезагрузите страницу';
        return 'Некорректные данные';
    }
  };

  const errorMessage = chooseErrorMessage(errorNumber);

  return (
    <div className={styles['modal-wrapper']}>
      <div className={styles['modal-wrapper-window']}>
        <div className={styles['message-server-error-wrapper']}>
          <Button
            className={styles['message-server-error-close-btn']}
            onClick={() => {
              if (onClose) {
                onClose(false);
              }
            }}
            elementType={'button'}
            typeBtn={'primary'}
            size={'s'}
            showLeftIcon
            leftIcon={<i className={'icon icon-close-3 icon-tiny'}/>}
          />
          <div className={styles['message-server-error-head']}>
            <div className={styles['message-server-error-head-title']}>
              {errorMessage}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
