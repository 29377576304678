import { useState } from 'react';
import './profile-form.scss';
import { useFormik } from 'formik';
import { FormInput } from './form-input';
import { useUserProfileInfo } from '../../state/selector';
import { useUpdateUserDataMutation } from '../../state';
import { profileValidationSchema as validationSchema } from '../../utils';

export const ProfileForm = () => {
  const user = useUserProfileInfo();
  const [updateUserData, { isSuccess, isError }] = useUpdateUserDataMutation();
  const [isPasswordChange, setIsPasswordChange] = useState(false);

  const togglePasswordChange = () => {
    setIsPasswordChange(true);
  };

  const formik = useFormik({
    initialValues: {
      firstName: user?.first_name ? user?.first_name : '',
      lastName: user?.last_name ? user?.last_name : '',
      middleName: user?.middle_name ? user?.middle_name : '',
      username: user?.username ? user?.username : '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const { firstName, lastName, middleName, username, password } = values;

      const preparedObject = {
        id: user?.id,
        first_name: String(firstName),
        last_name: String(lastName),
        middle_name: String(middleName),
        password,
        username,
      };

      updateUserData(preparedObject);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="profile-form">
        {isSuccess && (
          <div className={'profile-form-success'}>Данные успешно изменены!</div>
        )}
        <div className="profile-form-section">
          <div className={'profile-form-section-item'}>
            <FormInput
              label="Имя"
              name={'firstName'}
              placeholder="Ввести имя"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="name"
            />
            <div className={'profile-form-section-error'}>{`${
              formik?.errors?.firstName && formik?.touched?.firstName
                ? formik?.errors?.firstName
                : ''
            }`}</div>
          </div>
          <div className={'profile-form-section-item'}>
            <FormInput
              label="Фамилия"
              name={'lastName'}
              placeholder="Ввести фамилию"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete={'family-name'}
            />
            <div className={'profile-form-section-error'}>{`${
              formik?.errors?.lastName && formik?.touched?.lastName
                ? formik?.errors?.lastName
                : ''
            }`}</div>
          </div>
        </div>
        <div className="profile-form-section">
          <div className={'profile-form-section-item'}>
            <FormInput
              isPassword={true}
              name={'password'}
              type={'password'}
              label="Пароль"
              placeholder="Сменить пароль"
              disabled={!isPasswordChange}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className={'profile-form-section-error'}>{`${
              formik?.errors?.password && formik?.touched?.password
                ? formik?.errors?.password
                : ''
            }`}</div>
          </div>
          {isPasswordChange ? (
            <div className={'profile-form-section-item'}>
              <FormInput
                isPassword={true}
                name={'confirmPassword'}
                type={'password'}
                label="Повторите пароль"
                placeholder="Повторите пароль"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className={'profile-form-section-error'}>{`${
                formik?.errors?.confirmPassword && formik?.touched?.confirmPassword
                  ? formik?.errors?.confirmPassword
                  : ''
              }`}</div>
            </div>
          ) : (
            <button
              className="profile-form-button change-password-button"
              onClick={() => togglePasswordChange()}
            >
              Сменить пароль
            </button>
          )}
        </div>
        <div className="profile-form-section">
          <div className={'profile-form-section-item'}>
            <FormInput
              label="Логин"
              name={'username'}
              type={'text'}
              autoComplete="username"
              placeholder="Введите логин"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className={'profile-form-section-error'}>{`${
              formik?.errors?.username && formik?.touched?.username
                ? formik?.errors?.username
                : ''
            }`}</div>
          </div>
        </div>
        <button
          // disabled={!formik.isValid}
          type={'submit'}
          className={`profile-form-button-save ${formik.isValid ? 'no-errors' : ''}`}
        >
          Изменить профиль
        </button>
      </div>
    </form>
  );
};
