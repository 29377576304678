import styles from './layers-switcher.module.scss'
import { LayersSwitchButton } from './layers-switcher-button'
import type { LayerSwitcherProps } from './layers-switcher.types'
import { Layers } from '../../constants'

export const LayerSwitcher = ({
  className = '',
  selectLayerHandler,
  selectedLayer,
}: LayerSwitcherProps) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <LayersSwitchButton
        checked={selectedLayer !== Layers.D}
        onClick={() => selectLayerHandler(Layers.D)}>
        Округа
      </LayersSwitchButton>
      <LayersSwitchButton
        checked={selectedLayer !== Layers.R}
        onClick={() => selectLayerHandler(Layers.R)}>
        Регионы
      </LayersSwitchButton>
      <LayersSwitchButton
        checked={selectedLayer !== Layers.O}
        onClick={() => selectLayerHandler(Layers.O)}>
        Объекты
      </LayersSwitchButton>
    </div>
  )
}
