import React from 'react';
import styles from './card.module.scss';
import { CardProps } from './card.types';

export const Card = ({ className, children, selected, ...props }: CardProps) => {
  return (
    <div
      aria-selected={selected}
      className={`
        ${styles.container} 
        ${selected ? styles.selected : ''} 
        ${className || ''}
      `}
      {...props}
    >
      {children}
    </div>
  );
};
