import { ChangeEvent } from 'react';
import './profile-header.scss';
import { useAddUserProfileImageMutation } from '../../state';
import defaultProfileImage from '../../images/default_avatar.png';
import { useUserProfileInfo } from '../../state/selector';
import { useAppDispatch } from '../../state/selector';

export const ProfileHeader = () => {
  const [addUserProfileImage] = useAddUserProfileImageMutation();
  const user = useUserProfileInfo();
  const dispatch = useAppDispatch();

  const handleChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const field = target;
    if (field.value) {
      const image = (field.files as any)[0];
      addUserProfileImage({ id: user!.id, image });
    }
  };

  return (
    <>
      <div className="profile-header">
        <form>
          <label
            className="profile-header_avatar"
            style={
              user
                ? // user && user.profile_image
                  {
                    backgroundImage: `url(${
                      process.env.REACT_APP_BACKEND_IMAGE_PATH as string
                    }${''}`,
                  }
                : { backgroundColor: 'rgba(0, 0, 0, .8)' }
            }
            htmlFor="file-add"
          >
            <div className="profile-header_mask" />
            <input
              className="profile-header_input"
              id="file-add"
              type="file"
              name="img"
              onChange={handleChange}
            />
          </label>
        </form>
        <div className="profile-header_info">
          <h4 className="profile-header-name">{`${user?.first_name || ''} ${
            user?.last_name || ''
          }`}</h4>
          <div className="profile-header-email">{user && user.username}</div>
        </div>
      </div>
    </>
  );
};
