import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { windowsInitialState as initialState } from './initial-state';
import type { MapViewType } from '../../types';
import {
  GalleryType,
  PhotoType,
} from '../../components/photos-switcher/photos-switcher.types';


const windowsSlice = createSlice({
  initialState,
  name: 'windows',
  reducers: {
    setCreateObjectModal: (state,  { payload }: PayloadAction<boolean>) => {
      state.isCreateObjectModalOpen = payload;
    },
    toggleInfoTableFullScreen: (state) => {
      state.isInfoTableFullScreen = !state.isInfoTableFullScreen;
    },
    setMonitoringOpen: (state,  { payload }: PayloadAction<boolean>) => {
      state.isMonitoringOpen = payload;
    },
    setAnalyticsViewOpen: (state,  { payload }: PayloadAction<boolean>) => {
      state.isAnalyticsViewOpen = payload;
    },
    setGalleryObjectId: (state,  { payload }: PayloadAction<PhotoType | null>) => {
      state.galleryObjectId = payload;
    },
    setGalleryType: (state,  { payload }: PayloadAction<GalleryType | null>) => {
      state.whichMediaGallery = payload;
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setEditField: (state,  { payload }: PayloadAction<string | null>) => {
      state.editField = payload;
    },
    toggleCoordsEditor: (state) => {
      state.isOpenCoordsEditor = !state.isOpenCoordsEditor;
    },
    toggleShowCalendar: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowCalendar = payload;
    },
    changeMapView: (state, { payload }:  PayloadAction<MapViewType>) => {
      state.mapView = payload;
    },
    setDeleteObject: (state,  { payload }: PayloadAction<boolean>) => {
      state.isDeleteObject = payload;
    },
  },
});

export const { 
  toggleInfoTableFullScreen,
  setEditField,
  toggleCoordsEditor,
  toggleSidebar,
  setGalleryObjectId,
  setGalleryType,
  setMonitoringOpen,
  toggleShowCalendar,
  setCreateObjectModal,
  setAnalyticsViewOpen,
  setDeleteObject,
} = windowsSlice.actions;

export const { reducer: windowsReducer } = windowsSlice;