import { useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import './view-wrapper.scss';
import type { ViewWrapperProps } from './view-wrapper.types';


export const ViewWrapper = ({ children, className, parentClassName = 'map-container', ...props }: ViewWrapperProps) => {
  const el = useMemo(() => document.createElement('div'), []);
  const parent: HTMLDivElement | null = document.querySelector(`.${parentClassName}`);

  useEffect(() => {
    const classList = ['portal-container'];

    if (className) className.split(' ').forEach((item) => classList.push(item));
    classList.forEach((item) => el.classList.add(item));

    parent?.appendChild(el);

    return () => {
      parent?.removeChild(el);
    };
  }, [parent, el, parentClassName, className]);

  return parent && createPortal(children, el);
};
