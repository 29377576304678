import { Paths } from '../constants';


export const levelList = {
  layers: [
    {
      name: 'macroRegion',
      label: 'Макрорегионы',
      icon: '',
      iconSelect: '',
      layer_type: 'macro_region',
      filters: [
        { id: 0, name:'Другие регионы' }, 
        { id: 1, name:'Витрина' }, 
        { id: 2, name:'Юг и Черноземье' }, 
        { id: 3, name:'Коридор Этан' }, 
        { id: 4, name:'Дальний Восток' },
      ],
    },
    {
      name: 'program',
      label: 'Программа',
      icon:  '',
      iconSelect: '',
      layer_type: 'program_id',
      filters: [
        { id: 1, name:'Восстановление ОКН' }, 
        { id: 2, name:'Социальная программа' },
      ],
    },
    {
      name: 'objects',
      label: 'Стадии реализации',
      icon: '',
      iconSelect: '',
      layer_type: 'real_status',
      filters:[
        { id: 2, name:'Активные' }, 
        { id: 1, name:'Перспективные' }, 
      ],
    },
  ],
  profilePage: [
    {
      name: 'bio',
      label: 'Мои данные',
      icon: '',
      iconSelect: '',
      layer_type: 'bio',
      link: Paths.PROFILE,
    },
    {
      name: 'favourites',
      label: 'Избранные объекты',
      icon: '',
      iconSelect: '',
      layer_type: 'favourites',
      link: Paths.PROFILE_FAVORITES,
    },
    {
      name: 'exit',
      label: 'Выйти',
      icon: '',
      iconSelect: '',
      layer_type: 'exit',
    },
  ],
};