import styles from '../profile-menu-map-page.module.scss';
import { H4Tag, SubTag, Profile } from '../../../common';
import { ProfileMenuMapPageHeaderProps } from '../profile-menu-map-page.types';

export const ProfileMenuHeader = ({
  isMenuOpened,
  src,
  name = 'name',
  email = 'johndoe@example.com',
}: ProfileMenuMapPageHeaderProps) => {
  return (
    <div className={styles['profile-menu-header']}>
      <div className={styles['profile-menu-header-avatar']}>
        <Profile isProfileMenuOpened={isMenuOpened} src={src} />
      </div>
      <div className={styles['profile-menu-header-content']}>
        <H4Tag className={styles.name}>{name}</H4Tag>
        <SubTag className={styles.email}>{email}</SubTag>
      </div>
    </div>
  );
};
