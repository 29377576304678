import { api } from './api';
import type { UserType, TokenType, LoginValues } from '../../types';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation<TokenType, LoginValues>({
      query({ password, username }) {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        return {
          url: '/login',
          method: 'POST',
          body: formData,
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
    getUserInfo: builder.mutation<UserType, void>({
      query() {
        return {
          url: '/user/profile',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
    updateUserData: builder.mutation<any, any>({
      query(formValues) {
        const form = new FormData();
        form.append('id', formValues.id);
        form.append('first_name', formValues.first_name);
        form.append('middle_name', formValues.middle_name);
        form.append('last_name', formValues.last_name);
        form.append('username', formValues.username);
        form.append('password', formValues.password);

        return {
          url: '/user/profile',
          method: 'PUT',
          body: form,
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
    addUserProfileImage: builder.mutation<any, any>({
      query({ id, image }) {
        const body = new FormData();
        body.append('id', id);
        body.append('img', image);

        return {
          url: '/auth/user',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      // invalidatesTags: ['User'],
    }),
    refreshUserToken: builder.mutation<UserType, void>({
      query() {
        return {
          url: '/refresh-token',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
    getFavoriteUserItems: builder.mutation<number[], void>({
      query() {
        return {
          url: '/auth/user/objects/get',
          method: 'POST',
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
    toggleFavoriteUserItem: builder.mutation<{ id: number }, { id: number }>({
      query(body) {
        return {
          url: '/auth/user/object/toggle',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useLoginUserMutation,
  useGetUserInfoMutation,
  useUpdateUserDataMutation,
  useAddUserProfileImageMutation,
  useRefreshUserTokenMutation,
  useGetFavoriteUserItemsMutation,
  useToggleFavoriteUserItemMutation,
} = userApi;
