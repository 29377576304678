import { useEffect, useMemo, useState } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import styles from './editor-view.module.scss';
import { Button, EditBar } from '../../common';
import { EditorViewProps } from './editor-view.types';
import { useAppDispatch, useAppObjects } from '../../../state/selector/selector';
import { ObjectInterface } from '../../../types';
import {
  setEditField,
  useEditFieldSelector,
  useUpdateObjectDataMutation,
} from '../../../state';
import { useSelectedObjectSelector } from '../../../history-state/selectors';

const getFieldValue = (
  item: ObjectInterface | null | undefined,
  necessaryKey: string | null,
): string => {
  if (item && necessaryKey) {
    const itemEntries = (Object.entries(item) as any).find(
      ([key, value]: [string, string]) => {
        if (key === necessaryKey) {
          return value;
        }
      },
    );

    return Array.isArray(itemEntries) ? itemEntries[1] : '';
  }
  return '';
};
export const EditorView = ({ children, setText, label }: EditorViewProps) => {
  const selectedObjectId = useSelectedObjectSelector();
  const objects = useAppObjects();
  const dispatch = useAppDispatch();
  const [updateObjectData, { isLoading }] = useUpdateObjectDataMutation();

  const item = useMemo(
    (): ObjectInterface | null | undefined =>
      objects.find((el: ObjectInterface) => {
        return el.id === selectedObjectId;
      }),
    [objects, selectedObjectId],
  );

  const necessaryKey = useEditFieldSelector();
  const [editorContent, setEditorContent] = useState<string>(() =>
    getFieldValue(item, necessaryKey),
  );

  const updateObjectDataHandler = () => {
    if (item) {
      updateObjectData({
        id: item.id,
        type: necessaryKey as string,
        value: editorContent,
      }).then(() => {
        dispatch(setEditField(null));
      });
    }
  };

  const editorConfig = useEditor({
    extensions: [StarterKit, Underline],
    content: editorContent,
    onUpdate({ editor }: any) {
      setEditorContent(editor.getHTML());
    },
  });

  useEffect(() => {
    const value = getFieldValue(item, necessaryKey);
    setEditorContent(value);
    if (editorConfig && editorConfig.commands.setContent) {
      try {
        editorConfig.commands.setContent(value);
      } catch (e) {
        console.warn(e);
      }
    }
  }, [item, necessaryKey, editorConfig]);

  return (
    <div className={styles.container}>
      <div className={styles.editBarWrapper}>
        <EditBar
          nameButtonSave={label}
          onToggleBold={() => {
            editorConfig?.chain().toggleBold().focus().run();
          }}
          isBoldActive={editorConfig?.isActive('bold') || false}
          onToggleItalic={() => editorConfig?.chain().toggleItalic().focus().run()}
          isItalicActive={editorConfig?.isActive('italic') || false}
          onToggleUnderline={() => editorConfig?.chain().toggleUnderline().focus().run()}
          isUnderlineActive={editorConfig?.isActive('underline') || false}
          onToggleLineThrough={() =>
            editorConfig?.chain().focus().toggleStrike().focus().run()
          }
          isLineThroughActive={editorConfig?.isActive('strike') || false}
          onUndo={() => editorConfig?.chain().undo().run()}
          onRedo={() => editorConfig?.chain().redo().run()}
          onSave={updateObjectDataHandler}
          isLoading={isLoading}
        />
        <Button
          size={'l'}
          elementType={'button'}
          onlyIcon={true}
          typeBtn={'uncolored'}
          onClick={() => dispatch(setEditField(null))}
          showLeftIcon
          leftIcon={<i className={'icon icon-close-3 icon-middle'} />}
        />
      </div>
      <EditorContent className={styles.editor} editor={editorConfig} />
    </div>
  );
};
