import './analytics-view-table.style.scss';
import type { AnalyticsViewProps, RowDataType } from './analytics-view.types';
import { TableContainer } from './analitics-view-table-container';
import React, { useEffect, useState } from 'react';
import { useObjectAnalyticsByObjectIdSelector, useUpdateObjectDataMutation } from '../../../state';
import { OptionalRows } from './analitics-view-table-container/table-container.types';
import { createRowsAndCols, morphToSendTableData } from './utils';
import { initialState } from './api';
import { ButtonGroup } from '../../common';


export const AnalyticsViewTable = ({ objectId, editable }: AnalyticsViewProps) => {
  const objectAnalytics = useObjectAnalyticsByObjectIdSelector(objectId);
  const [updateObjectData, { isLoading }] = useUpdateObjectDataMutation();
  const initialStateTableData = initialState && createRowsAndCols(initialState, editable);

  const [rows, setRows] = useState<OptionalRows[]>(() => initialStateTableData.rows);
  const [cols, setCols] = useState<string[]>(() => initialStateTableData.cols);

  function updateObjectDataHandler(val: any) {
    try {
      updateObjectData({
        id: Number(objectId),
        type: 'analytics',
        value: val,
      }).then(() => {
        console.log('table data saved');
      });
    } catch (err: any) {
      throw new Error('Error occured ', err.toString());
    }
  }


  function handleSaveTableData() {
    const morphedData = morphToSendTableData(cols, rows);
    updateObjectDataHandler(morphedData);
  }

  useEffect(() => {
    if (objectAnalytics) {
      const analyticsTableData =
        objectAnalytics && createRowsAndCols(objectAnalytics, editable);
      setRows(analyticsTableData.rows);
      setCols(analyticsTableData.cols);
    }
  }, [objectAnalytics]);

  const handleUpdateTableCell = ({ rowIndex, value, columnId }: RowDataType) => {
    setRows((old: any[]) =>
      old.map((row: any, index: number) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex]!,
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const handleUpdateTableCols = (idx: number, value: string) => {
    setCols([...cols.slice(0, idx), value, ...cols.slice(idx + 1)]);
  };

  const handleUpdateTableData = (cols: string[], rows: OptionalRows[]) => {
    setRows(rows);
    setCols(cols);
  };

  
  const handleAddRow = (idx: number) => {
    const updatedRows = [...rows.slice(0, idx + 1), cols.reduce((acc, curr, idx) => ({ ...acc, [`${idx}`]: '' }), {}), ...rows.slice(idx + 1) ];
    setRows([...updatedRows]);
  };
  
  
  // const morphedData = morphToSendTableData(initialStateTableData.cols, initialStateTableData.rows);
  // updateObjectDataHandler(morphedData);
  // updateObjectDataHandler(null);
  const handleDeleteRow = (idx: number) => {
    const updatedRows = rows.filter((row, i) => i !== idx);
    setRows([...updatedRows]);
  };

  const handleAddColumn = (idx: number) => {
    const updateRow = (row: OptionalRows, idx: number) => {
      const entries = Object.entries(row);
      const beginning = entries.slice(0, idx + 1);
      const end = entries.slice(idx + 1).map((arr) => [arr[0] = String(Number(arr[0]) + 1), arr[1]]);
      const newRow = [...beginning, [`${idx + 1}`, ''], ...end];
    
      return Object.fromEntries(newRow);
    };
    
    const updatedRows = rows.map((row) => updateRow(row, idx));
    const updatedCols = [...cols.slice(0, idx + 1), '', ...cols.slice(idx + 1)];
    setCols([...updatedCols]);
    setRows([...updatedRows]);
  };

  const handleDeleteColumn = (idx: number) => {
    const updateRow = (row: OptionalRows, idx: number) => {
      const entries = Object.entries(row);
      const beginning = entries.filter((col, i)=> i !== idx);
      const end = entries.slice(idx + 1).map((arr) => [arr[0] = String(Number(arr[0]) - 1), arr[1]]);
      const newRow = [...beginning, ...end];
    
      return Object.fromEntries(newRow);
    };
    const updatedRows = rows.map((row) => updateRow(row, idx));
    const updatedCols = cols.filter((col, i)=> i !== idx);
    setCols([...updatedCols]);
    setRows([...updatedRows]);
  };
  
  
  const editColumnMenu = (idx : number) => {
    return (
      <ButtonGroup
        direction={'row'}
        gap={4}
        maxWidth={'fill'}
        withBackground={false}
        justifyContent={'between'}
      >
        <button
          disabled={isLoading}
          className={'analitics-view-table-edit-button add'}
          onClick={() => handleAddColumn(idx)}
        >
          <i className="icon icon-plus"></i>
        </button>
    
        <button
          disabled={isLoading}
          className={'analitics-view-table-edit-button delete'}
          onClick={() => handleDeleteColumn(idx)}
        >
          <i className="icon icon-close-3"></i>
        </button>
      </ButtonGroup>
    );
  };
  
  const editRowMenu = (idx : number) => {
    return (
      <ButtonGroup
        direction={'column'}
        gap={4}
        maxWidth={'fill'}
        withBackground={false}
        justifyContent={'between'}
      >
        <button
          disabled={isLoading}
          className={'analitics-view-table-edit-button add'}
          onClick={() => handleAddRow(idx)}
        >
          <i className="icon icon-plus"></i>
        </button>
        <button
          disabled={isLoading}
          className={'analitics-view-table-edit-button delete'}
          onClick={() => handleDeleteRow(idx)}
        >
          <i className="icon icon-close-3"></i>
        </button>
      </ButtonGroup>
    );
  };
  
  return (
    <div className={'analitics-view-table-wrapper'}>
      {editable && (
        <ButtonGroup
          direction={'row'}
          gap={8}
          maxWidth={'fill'}
          withBackground={false}
          justifyContent={'end'}
        >
          <button
            disabled={isLoading}
            onClick={handleSaveTableData}
            className={'analitics-view-table-save-button'}
          >
            Сохранить изменения{' '}
          </button>
        </ButtonGroup>
      )}

      {/* <button onClick={() => editor.chain().focus().mergeOrSplit().run()}>Объединить/Разделить</button> */}

      <TableContainer
        objectId={objectId}
        rows={rows}
        cols={cols}
        editable={editable}
        handleUpdateTableCell={handleUpdateTableCell}
        handleUpdateTableData={handleUpdateTableData}
        handleUpdateTableCols={handleUpdateTableCols}
        editColumnMenu={editColumnMenu}
        editRowMenu={editRowMenu}
        //saveDraftOnBlur={saveDraftOnBlur}
      />
    </div>
  );
};
