import { useEffect, useMemo, useState } from 'react';

import { HeaderMap } from './header-map';
import {
  useAppDispatch,
  useAppStateUserRole,
  useEditFieldSelector,
  useEditorModeStateSelector,
} from '../../../state/selector';
import {
  useSelectedObjectSelector,
  useSidebarState,
  useViewModeSelector,
} from '../../../history-state/selectors';
import { useAppObjects } from '../../../state/selector/selector';
import { setEditField, useUpdateObjectDataMutation } from '../../../state';
import { ObjectInterface } from '../../../types';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';

export const HeaderMapWrapper = ({ isScrolling = false, isLoading = false }) => {
  const [isSidebarOpen, setSidebarOpen] = useSidebarState();
  const editorMode = useEditorModeStateSelector();
  const role = useAppStateUserRole();
  const [viewMode, setViewMode] = useViewModeSelector();
  const selectedObject = useSelectedObjectSelector();

  useEffect(() => {
    !isSidebarOpen && setSidebarOpen(true);
  }, []);

  const selectedObjectId = useSelectedObjectSelector();
  const objects = useAppObjects();
  const dispatch = useAppDispatch();
  const [updateObjectData, { isLoading: isLoadingObjectData }] =
    useUpdateObjectDataMutation();

  const item = useMemo(
    (): ObjectInterface | null | undefined =>
      objects.find((el: ObjectInterface) => {
        return el.id === selectedObjectId;
      }),
    [objects, selectedObjectId],
  );

  const getFieldValue = (
    item: ObjectInterface | null | undefined,
    necessaryKey: string | null,
  ): string => {
    if (item && necessaryKey) {
      const itemEntries = (Object.entries(item) as any).find(
        ([key, value]: [string, string]) => {
          if (key === necessaryKey) {
            return value;
          }
        },
      );

      return Array.isArray(itemEntries) ? itemEntries[1] : '';
    }
    return '';
  };

  const necessaryKey = useEditFieldSelector();
  const [editorContent, setEditorContent] = useState<string>(() =>
    getFieldValue(item, necessaryKey),
  );

  const updateObjectDataHandler = () => {
    if (item) {
      updateObjectData({
        id: item.id,
        type: necessaryKey as string,
        value: editorContent,
      }).then(() => {
        dispatch(setEditField(null));
      });
    }
  };

  const editorConfig = useEditor({
    extensions: [StarterKit, Underline],
    content: editorContent,
    onUpdate({ editor }: any) {
      setEditorContent(editor.getHTML());
    },
  });

  useEffect(() => {
    const value = getFieldValue(item, necessaryKey);
    setEditorContent(value);
    if (editorConfig && editorConfig.commands.setContent) {
      try {
        editorConfig.commands.setContent(value);
      } catch (e) {
        console.warn(e);
      }
    }
  }, [item, necessaryKey, editorConfig]);

  return (
    <HeaderMap
      isScrolling={isScrolling}
      isLoading={isLoading}
      editorMode={editorMode}
      role={role}
      view={viewMode}
      onSelectViewMode={(view) => setViewMode(view)}
      showSideBar={() => setSidebarOpen(!isSidebarOpen)}
      isSidebarOpened={isSidebarOpen}
      selectedObject={selectedObject}
    />
  );
};
