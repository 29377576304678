import { format } from 'date-fns';


export const formatDate = (date: string | Date): string => {
  return format(new Date(date), 'dd.MM.yyyy');
};

export const formatToDate = (value: string | undefined) => {
  if (value) {
    const [y, m, d]  = value?.split('-');

    return `${d}.${m}.${y}`;
  }
  return '';
};