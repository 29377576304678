import { useState } from 'react';
import { Sidebar } from './sidebar';
import { ObjectInterface } from '../../types';
import {
  useAppDispatch,
  useDeviceSelector,
  useEditorModeStateSelector,
  useFilteredObjectsSelector,
  useGetProjectSelector,
} from '../../state';
import { useSelectObject } from '../../hooks/depended/useSelectObject';
import {
  clearProject,
  setEditField,
  setGalleryObjectId,
  setMonitoringOpen,
  UpdateObjectDataType,
  useUpdateObjectDataMutation,
} from '../../state';
import { BottomSheet } from '../common/bottom-sheet';
import { SidebarContainer } from '../common';
import { useObjectMediaState, useSidebarState } from '../../history-state/selectors';
import { batch } from 'react-redux';
import type { Group as GroupType } from 'types/monitoring';
import { GalleryType } from 'components/photos-switcher/photos-switcher.types';
import { useSearchParams } from 'react-router-dom';

export const SidebarWrapper = () => {
  const [isSidebarOpen, handleChangeSidebarState] = useSidebarState();
  const isEditorMode = useEditorModeStateSelector();
  const filteredObjects = useFilteredObjectsSelector();
  const [group, setGroup] = useState<GroupType | null>(null);
  const [searchObjectMedia, setSearchObjectMedia] = useObjectMediaState();
  const [searchParams, setSearchParams] = useSearchParams();

  const closeGalleryHandler = () => {
    if (searchObjectMedia) {
      searchParams.delete(searchObjectMedia.type);
      setSearchParams(searchParams);
    }
  };

  const [selectedObjectId, setSelectedObjectId] = useSelectObject();
  const dispatch = useAppDispatch();
  const [updateObjectData, { isLoading: isObjectDataLoaded }] =
    useUpdateObjectDataMutation();
  const { isMobile } = useDeviceSelector();
  const project = useGetProjectSelector();

  const handleUpdateObjectData = (values: UpdateObjectDataType) => {
    return updateObjectData(values);
  };

  const bottomIndex = 0.48;

  const selectImageHandler = (
    item: string | number,
    galleryType: GalleryType | undefined,
  ) => {
    setSearchObjectMedia(item, galleryType);
  };

  return (
    <>
      {isSidebarOpen && !!selectedObjectId && !isMobile && (
        <SidebarContainer>
          <Sidebar
            object={filteredObjects.find(
              (el: ObjectInterface) => el.id === selectedObjectId,
            )}
            onCloseClick={() => {
              closeGalleryHandler();
              setSelectedObjectId(null);
              batch(() => {
                dispatch(clearProject());
                dispatch(setMonitoringOpen(false));
              });
            }}
            onImageClick={selectImageHandler}
            group={group}
            setGroup={setGroup}
            editorMode={isEditorMode}
            onEditField={(field) => dispatch(setEditField(field))}
            onChangeObject={handleUpdateObjectData}
            isObjectUpdating={isObjectDataLoaded}
            isMonitoringActive={!!project}
          />
        </SidebarContainer>
      )}

      <BottomSheet
        open={isSidebarOpen && !!selectedObjectId && isMobile}
        onDismiss={() => {
          setSelectedObjectId(null);
        }}
        blocking={false}
        defaultSnap={({ maxHeight }: { maxHeight: number }) => maxHeight * bottomIndex}
        snapPoints={({ maxHeight }: { maxHeight: number }) => [
          maxHeight * bottomIndex,
          maxHeight,
        ]}
      >
        <Sidebar
          object={filteredObjects.find(
            (el: ObjectInterface) => el.id === selectedObjectId,
          )}
          onCloseClick={() => {
            setSelectedObjectId(null);
          }}
          onImageClick={selectImageHandler}
          group={group}
          setGroup={setGroup}
          editorMode={isEditorMode}
          onEditField={(field) => dispatch(setEditField(field))}
          onChangeObject={handleUpdateObjectData}
          isObjectUpdating={isObjectDataLoaded}
        />
      </BottomSheet>
    </>
  );
};
