import { SidebarHeaderProps } from './sidebar.types'
import { Button, SpanTag, H4Tag, ToolTip } from '../common'
import styles from './sidebar.module.scss'

export const SidebarHeader = ({
  title = '',
  onCloseClick,
  subtitle,
}: SidebarHeaderProps): JSX.Element => {
  return (
    <div className={styles['sidebar-header']}>
      <div className={styles['sidebar-header-top']}>
        <div className={styles['sidebar-header-top-title']}>
          <ToolTip
            className={styles['card-header-tooltip']}
            isVisible={Number(title.length) > 70}
            content={title}>
            <H4Tag
              className={styles['sidebar-header-top-title-text']}
              weight={2}>
              {title}
            </H4Tag>
          </ToolTip>
          <Button
            size={'l'}
            elementType={'button'}
            className={styles['sidebar-header-top-close-button']}
            onlyIcon={true}
            typeBtn={'uncolored'}
            onClick={onCloseClick}
            showLeftIcon
            leftIcon={<i className={'icon icon-close-3 icon-middle'} />}
          />
        </div>
        {subtitle && subtitle.length ? (
          <div className={styles['sidebar-header-top-subtitle']}>
            <ToolTip
              className={styles['card-header-tooltip']}
              isVisible={Number(subtitle.length) > 90}
              content={subtitle}>
              <SpanTag className={styles['sidebar-header-top-subtitle-text']}>
                {subtitle}
              </SpanTag>
            </ToolTip>
          </div>
        ) : null}
      </div>
    </div>
  )
}
