import './profile-page.scss';
import { Button } from '../../components/common';
import { useAppDispatch } from '../../state';
import { logout } from '../../state';
import { UserInfoContent } from './user-info-content';
import { useNavigate } from 'react-router-dom';


export const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div className={'profile-page-container-outer'}>
      <div className={'profile-page-container-inner'}>
        <div className={'button-back'}>
          <Button
            onClick={() => navigate(-1)}
            elementType={'button'}
            typeBtn={'primary'}
            // showLeftIcon={true}
            // leftIcon={
            //     mapView === 'map' 
            //       ? <i className="icon icon-map icon-middle"></i> 
            //       : <i className="icon icon-list icon-middle"></i>
            // }
            text={'Закрыть'}
          />
          <Button
            text={'Выйти'}
            elementType={'button'}
            typeBtn={'danger'}
            showLeftIcon={true}
            justifyContent={'start'}
            onClick={() => dispatch(logout())}
            leftIcon={<i className="icon icon-log-out icon-middle"></i>}
          />
        </div>
        <UserInfoContent />
      </div>
    </div>
  );
};
