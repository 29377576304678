import type { ObjectInterface, ObjectType } from '../../../../types';
import L from 'leaflet';
import { getMacroColor } from './';
import { getMacroHoveredColor, getRatingColor, getRatingHoveredColor } from './market';
import { isActive } from '@tiptap/react';
import { MarkerIconSun } from 'icons';

export const checkObjMacro = (object: ObjectInterface) => {
  if (object?.macro_region !== '0') return true;
};

interface ObjTest extends ObjectInterface {
  icon_select?: any;
}

export const getObjectIcon = (
  objectType: ObjectType | undefined,
  item: ObjTest,
  radius = 24,
  selected = false,
  disabled = false,
  heatColor: any = true,
  macro_region: any = false,
  dotsView = false,
  showName = false,
  markerSrc = '',
  isGazpromChild = false,
) => {
  // const color = heatColor ? getHeatColor(parseInt(item.rating.rate))
  //   : macro ? getMacroColor(item.macro_region)
  //     : 'pink';
  // const color = item.rating ? getHeatColor(parseInt(item.rating.rate)) : 'pink';

  //const color = getMacroColor(Number(macro_region));
  // const hoveredColor = getMacroHoveredColor(Number(macro_region));

  //                src=${objectType ? objectType.iconSelect! : ''}
  const color = getRatingColor(Number(item.rating));
  const hoveredColor = getRatingHoveredColor(Number(item.rating));

  const activeIcon = L.divIcon({
    className: 'marker-sphere active',
    html: `<div 
            class="marker-circle-base active-marker"
            style="
              margin-top: -${radius - 2}px;  
              right: 9px; 
              background-color: ${color};
              --hoveredcolor: ${color}; 
              "
            >
            <div class="marker-sphere-text">
              <img 
                src=${markerSrc}
                alt=${objectType ? objectType.name : ''}
                class="object-logo" 
              />
              </div>
              <div 
                class="marker-sphere-corner"
                style="
                  left: 18px; //${radius / 5.5}px
                  border: ${radius / 2}px solid transparent;
                  border-top: ${radius}px solid ${color};
                  width: 21px;
                  height: 0px;
                  bottom: -24px;
                  border: 10px solid transparent;
                  border-top: 17px solid ${color};
                "> </div>
          </div>
      <span 
          style="
            position: absolute;
            display: flex!important;
            justify-content: center;
            width: 150px;
            margin-top: 28px;
            left: -45px;
            text-align: center;
            font-weight: 600;
            color: ${color};
            font-size: 12px;
            text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
            word-break: break-word;
            line-height: 14px;
            letter-spacing: -0.4px;
            cursor: pointer;
          "
        >
          ${
            item.name.length > 45
              ? `${item.name.trim().slice(0, 45)}...`
              : item.name.trim()
          }
      </span>`,
    iconSize: [radius * 0.5, radius * 1.5],
    iconAnchor: [(radius * 1.5) / 2, (radius * 1.5) / 2],
  });
  const icon = L.divIcon({
    className: 'marker-sphere',
    html: `<div class="marker-circle-base"
              style="
                display: flex;
                justify-content: center;
                right: 9px;
                ${
                  dotsView
                    ? `margin-left: ${radius * 0.3}px;
    margin-top: -${radius * 0.01}px;`
                    : `margin-top: -${radius * 0.4}px;`
                }
                background-color: ${color}; 
                width: ${dotsView ? 18 : radius}px; 
                height: ${dotsView ? 18 : radius}px;
                box-shadow: 0px 0px 0px 2px #e3e3e3 inset;
                --hoveredcolor: ${hoveredColor};
              "
              >
              ${
                !dotsView
                  ? `
                <div class="marker-sphere-text">
                <img 
                   src=${markerSrc}
                  alt="${objectType ? objectType.name : ''}"
                  class="object-logo"
                />
              </div>
                `
                  : ''
              }
            ${
              showName
                ? `<span
            class="marker-object-title" 
          style="
            position: absolute;
            display: flex!important;
            justify-content: center;
            width: 150px;
            margin-top: 48px;
            color: ${color};
            font-size: 12px;
            text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
            word-break: break-word;
             --hoveredcolor: ${hoveredColor};
          "
        >
          ${
            item.name.length > 45
              ? `${item.name.trim().slice(0, 45)}...`
              : item.name.trim()
          }
      </span>`
                : ''
            }
          </div>`,
    iconSize: [radius, radius],
    iconAnchor: [radius / 2, radius / 2],
  });

  const gazpromIcon = L.divIcon({
    className: 'marker-gazprom',
    html: `<div class="marker-gazprom-base"
              style="
                display: flex;
                justify-content: center;
                ${
                  dotsView
                    ? `margin-left: ${radius * 0.3}px;
                      margin-top: -${radius * 0.01}px;`
                    : `margin-top: -${radius * 0.4}px;`
                }
              "
              >
              ${
                !dotsView
                  ? `
                <div class="marker-gazprom-body">
                  <div class="marker-gazprom-sphere">
                    <svg width="100%" height="60" viewBox="0 0 59 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_733_12145)">
                        <path d="M24.3084 14.1387C22.2356 14.1387 20.2093 14.7533 18.4859 15.9049C16.7624 17.0565 15.4191 18.6933 14.6259 20.6083C13.8327 22.5233 13.6251 24.6306 14.0295 26.6635C14.4339 28.6965 15.432 30.5639 16.8977 32.0296C18.3634 33.4953 20.2308 34.4934 22.2638 34.8978C24.2968 35.3022 26.404 35.0947 28.319 34.3014C30.234 33.5082 31.8708 32.1649 33.0224 30.4415C34.174 28.718 34.7887 26.6917 34.7887 24.6189C34.7856 21.8403 33.6805 19.1764 31.7157 17.2116C29.7509 15.2468 27.087 14.1417 24.3084 14.1387Z" fill="#FFFF01"/>
                        <path d="M34.1582 32.9158L49.6845 48.4421" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M37.2627 24.1653L56.8817 24.1653" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M23.8555 37.5737L23.8555 57.3355" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M14 34.3105L5 41.3105" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M35.7109 15.8369L45.0267 6.52112" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M2.54004 24.1653L11.4339 24.1653" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M10.8682 9.62622L15.5261 14.2841" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M24.8555 1.42871L24.8555 9.75651" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_733_12145">
                        <rect width="59" height="59" fill="white" transform="translate(0 0.310547)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div 
                class="marker-sphere-corner"></div>
                </div>
                `
                  : ''
              }
            ${
              showName
                ? `<span
            class="marker-object-title" 
          style="
            position: absolute;
            display: flex!important;
            justify-content: center;
            width: 150px;
            margin-top: 48px;
            color: #0091FE;
            font-size: 12px;
            text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
            word-break: break-word;

          "
        >
          ${
            item.name.length > 45
              ? `${item.name.trim().slice(0, 45)}...`
              : item.name.trim()
          }
      </span>`
                : ''
            }
          </div>`,
    iconAnchor: [radius / 2, radius / 2],
  });

  const gazpromActiveIcon = L.divIcon({
    className: 'marker-gazprom',
    html: `<div class="marker-gazprom-base"
              style="
                display: flex;
                justify-content: center;
                ${
                  dotsView
                    ? `margin-left: ${radius * 0.3}px;
                      margin-top: -${radius * 0.01}px;`
                    : `margin-top: -${radius * 0.4}px;`
                }
              "
              >
              ${
                !dotsView
                  ? `
                <div class="marker-gazprom-body">
                  <div class="marker-gazprom-sphere-active">
                    <svg width="100%" height="60" viewBox="0 0 59 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_733_12145)">
                        <path d="M24.3084 14.1387C22.2356 14.1387 20.2093 14.7533 18.4859 15.9049C16.7624 17.0565 15.4191 18.6933 14.6259 20.6083C13.8327 22.5233 13.6251 24.6306 14.0295 26.6635C14.4339 28.6965 15.432 30.5639 16.8977 32.0296C18.3634 33.4953 20.2308 34.4934 22.2638 34.8978C24.2968 35.3022 26.404 35.0947 28.319 34.3014C30.234 33.5082 31.8708 32.1649 33.0224 30.4415C34.174 28.718 34.7887 26.6917 34.7887 24.6189C34.7856 21.8403 33.6805 19.1764 31.7157 17.2116C29.7509 15.2468 27.087 14.1417 24.3084 14.1387Z" fill="#FFFF01"/>
                        <path d="M34.1582 32.9158L49.6845 48.4421" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M37.2627 24.1653L56.8817 24.1653" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M23.8555 37.5737L23.8555 57.3355" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M14 34.3105L5 41.3105" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M35.7109 15.8369L45.0267 6.52112" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M2.54004 24.1653L11.4339 24.1653" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M10.8682 9.62622L15.5261 14.2841" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                        <path d="M24.8555 1.42871L24.8555 9.75651" stroke="#FFFF01" stroke-width="2" stroke-linecap="round"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_733_12145">
                        <rect width="59" height="59" fill="white" transform="translate(0 0.310547)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div 
                class="marker-sphere-corner"></div>
                </div>
                `
                  : ''
              }
            ${
              showName
                ? `<span
            class="marker-object-title" 
          style="
            position: absolute;
            display: flex!important;
            justify-content: center;
            width: 150px;
            margin-top: 48px;
            color: #0091FE;
            font-size: 12px;
            text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
            word-break: break-word;

          "
        >
          ${
            item.name.length > 45
              ? `${item.name.trim().slice(0, 45)}...`
              : item.name.trim()
          }
      </span>`
                : ''
            }
          </div>`,
    iconAnchor: [radius / 2, radius / 2],
  });

  if (isGazpromChild) {
    return selected ? gazpromActiveIcon : gazpromIcon;
  }

  return selected ? activeIcon : icon;
};
