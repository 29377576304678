import { ObjectInterface } from '../../../types';
import type { Map } from 'leaflet';
import { LatLng as LatLngType } from 'leaflet';
import { Map as MapConstants } from '../../../constants';


export const buildSearchResults = (
  items: [] | ObjectInterface[],
  inputQueryValue: string,
  isEditorMode: boolean,
): {
  firstSuggestionId: number | null
  suggestions: [] | ObjectInterface[]
} => {
  const filterObjectsByQuery = (item: ObjectInterface, query = '') => {

    const string = `${item.name ? item.name.toLowerCase() + ' ' : ''}${item.place ? item.place.toLowerCase() + ' ' : ''}${item.owner ? item.owner.toLowerCase() + ' ' : ''}`;

    return string.includes(query.toLowerCase());
  };
  
  
  const filteredResults = items.filter((item) => {
    
    return filterObjectsByQuery(item, inputQueryValue.toLowerCase());
  });

  return {
    firstSuggestionId: (filteredResults && filteredResults[0]?.id) || null,
    suggestions: filteredResults,
  };
};


export const flyToChoosenSuggestionItem = (coordinates: number[], map: Map | null) => {
  if (map) {
    map.flyTo(coordinates as unknown as LatLngType, MapConstants.FLY_TO_ZOOM_VALUE, { duration: 0.5 });
  }
};