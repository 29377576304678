import { useState, memo } from 'react';
import styles from './profile-menu-map-page.module.scss';
import { ProfileMenuMapPageProps } from './profile-menu-map-page.types';
import { ProfileMenuContent, ProfileMenuHeader, ProfileMenuTrigger } from './components';
import { useDropdown } from '../../../hooks';


export const ProfileMenuMapPageDesktop = memo(({ 
  src = 'https://avatars.mds.yandex.net/get-zen_doc/5262161/pub_61cb1853c97cb1018f443f83_61cb43b819129b335b14c1d9/scale_1200', 
  name = 'Name',
  email = 'johndoe@example.com' } : ProfileMenuMapPageProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  

  const { renderDropdown } = useDropdown({
    header: <ProfileMenuHeader
      src={src}
      name={name}
      email={email}
    />,
    body: <ProfileMenuContent />,
    trigger:<ProfileMenuTrigger
      src={src}
      isMenuOpened={isMenuOpened}
    />,
  });

  return  <div className={styles['profile-menu']}>{renderDropdown()}</div>;
});
