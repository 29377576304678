import styles from './viewing-content.module.scss';
import { ViewingContentProps } from './viewing-content.types';
import { CONTENT_TYPES, ScreenSize } from '../../constants';
import { nanoid } from 'nanoid';
import { Button } from '../common';
import { useBreakpointsComparison } from '../../hooks';

export const ViewingContentDesktop = ({
  handleClickContentBtn,
  activeView,
  isContentBtnDisabled,
}: ViewingContentProps) => {
  const isDesktopSmall = useBreakpointsComparison(ScreenSize.DS);
  const isTabletLandcape = useBreakpointsComparison(ScreenSize.TL);

  const { TIMELAPSE, ...rest } = CONTENT_TYPES;

  return (
    <div className={styles['viewing-content-container']}>
      {Object.entries(rest).map((type) => {
        const isDisabled = !Boolean(
          isContentBtnDisabled && isContentBtnDisabled(type[1]?.TYPE),
        );

        return isDisabled ? null : (
          <Button
            key={nanoid()}
            onClick={() => handleClickContentBtn && handleClickContentBtn(type[1].TYPE)}
            size={!isTabletLandcape ? 'l' : 'm'}
            elementType={'button'}
            typeBtn={'elevation'}
            text={type[1].NAME}
            onlyIcon={isDesktopSmall}
            showLeftIcon={true}
            isActive={type[1].TYPE === activeView}
            leftIcon={type[1].ICON}
          />
        );
      })}
    </div>
  );
};
