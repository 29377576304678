import { useCallback, useEffect, useRef, memo } from 'react';
import styles from './multi-range-slider.module.scss';
import { SpanTag } from '../common';
import type { MultiRangeSliderProps } from './multi-range-slider.types';

export const MultiRangeSlider = memo(
  ({ min, max, minVal, maxVal, onChange }: MultiRangeSliderProps) => {
    const minValRef = useRef(min);
    const maxValRef = useRef(max);
    const range = useRef<HTMLInputElement>(null);

    // Convert to percentage
    const getPercent = useCallback(
      (value: number) => Math.round(((value - min) / (max - min)) * 100),
      [min, max],
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(maxVal);
      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(maxVal);
      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }, [maxVal, getPercent]);

    return (
      <div className={styles.multi__range}>
        <div className={styles.inputs__wrapper}>
          <input
            className={`${styles.text__input} ${styles.text__input_left}`}
            type="text"
            value={minVal}
            onChange={(event) => {
              const value = Number(event.target.value);
              if (typeof value === 'number' && value >= min && value <= maxVal - 1) {
                onChange({ min: value, max: maxVal });
                minValRef.current = value;
              }
            }}
          />
          <div className={styles.container}>
            <input
              type="range"
              min={min}
              max={max}
              value={minVal}
              onChange={(event) => {
                const value = Math.min(Number(event.target.value), maxVal - 1);
                onChange({ min: value, max: maxVal });
                minValRef.current = value;
              }}
              className={`${styles.thumb} ${styles['thumb--left']}`}
              style={{ zIndex: minVal > max - 100 ? 5 : 1 }}
            />
            <input
              type="range"
              min={min}
              max={max}
              value={maxVal}
              onChange={(event) => {
                const value = Math.max(Number(event.target.value), minVal + 1);
                onChange({ min: minVal, max: value });
                maxValRef.current = value;
              }}
              className={`${styles.thumb} ${styles['thumb--right']}`}
            />
            <div className={styles.slider__track} />
            <div ref={range} className={styles.slider__range} />
          </div>

          <input
            type="text"
            className={`${styles.text__input} ${styles.text__input_right}`}
            value={maxVal}
            onChange={(event) => {
              const value = Number(event.target.value);
              if (typeof value === 'number' && value >= minVal + 1 && value <= max) {
                onChange({ min: minVal, max: value });
                maxValRef.current = value;
              }
            }}
          />
        </div>
        <div className={styles.slider__marks_info}>
          <SpanTag weight={3}>Очень плохо</SpanTag>
          <SpanTag weight={3}>Очень хорошо</SpanTag>
        </div>
      </div>
    );
  },
);
