import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './monitoring-login-page.module.scss';
// import loginPageBackgroundDesktop from '../../images/login-page-background.png';
import loginPageBackground from '../../images/login-page-background.jpg';
import LogoDesktop from '../../images/gazprom-white-logo.svg';
import { LoginView } from '../../components';
import { useLoginSphereUserMutation, useLoginUserMutation } from '../../state';
import type { LoginValues } from '../../types';

interface ErrorType {
  data: {
    message: string;
  };
  status: number;
}

export const MonitoringLogin = (): JSX.Element => {
  const [loginError, setLoginError] = useState<string | null>(null);
  const isMobile = false;
  const [loginUser, { isLoading, isSuccess, isError, error }] =
    useLoginSphereUserMutation();
  const navigate = useNavigate();

  const submitHandler = async (values: LoginValues) => {
    try {
      const loginHandler = await loginUser(values);
    } catch (e) {}
  };

  useEffect(() => {
    if (isSuccess) {
      // navigate({ pathname: '/admin' }, { replace: true });
    }
  }, [isSuccess]);

  return (
    <>
      {/* <Helmet>
            <title>Вход</title>
        </Helmet> */}

      <div className={styles['login-wrapper']}>
        <img
          src={loginPageBackground}
          alt="Фоновая картинка"
          className={styles['login-background']}
        />
        {/* {!isMobile && (
          <div className={styles['login-logo']}>
            <img src={LogoDesktop} alt="Логотип" />
            <div className={styles['login-logo-text']}>
              Ваш комфорт —
              <br />
              наша работа!
            </div>
          </div>
        )} */}
        <LoginView
          error={error ? (error as ErrorType).data.message : false}
          onLoginSubmit={submitHandler}
          isFormDisabled={isLoading}
          titleText={'Добро пожаловать в систему мониторинга!'}
        />
      </div>
    </>
  );
};
