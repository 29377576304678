import './sidebar.module.scss';
import styles from './sidebar.module.scss';
import type { SidebarSwitchTabButtonsProps } from './sidebar.types';
import { Button } from '../common';


export const SidebarSwitchTabButtons = ({
  isMonitoringActive,
  setMonitoring,
  setInfo,
  isMonitoringDisabled,
}: SidebarSwitchTabButtonsProps): JSX.Element => {

  return (
    <>
      <Button
        typeBtn={!isMonitoringActive ? 'primary' : 'elevation'}
        style={{ cursor: !isMonitoringActive ? 'default' : 'pointer' }}
        elementType={'button'}
        className={styles[isMonitoringActive ? 'unselected-button' : '']}
        onClick={setInfo as () => void}
        size={'s'}
      >
                Информация
      </Button>
      <Button
        typeBtn={isMonitoringActive ? 'primary' : 'elevation'}
        style={{ cursor: isMonitoringActive || isMonitoringDisabled  ? 'default' : 'pointer' }}
        elementType={'button'}
        className={styles[!isMonitoringActive ? 'unselected-button' : '']}
        onClick={setMonitoring as () => void}
        size={'s'}
        disabled={isMonitoringDisabled}
      >
                Мониторинг
      </Button>
    </>
  );
};
