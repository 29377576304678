import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import './geo-json.styles.scss'
import { GeoJSON } from 'react-leaflet'
import type { GeoJsonProps } from './geo-json.types'
import { getFeatureClasses } from '../layers/utils'

const CustomGeoJson = GeoJSON as any

export const GeoJson = ({
  data,
  fillColor = '#fff',
  fillOpacity = 0.4,
  weight = 1,
  color = '#fff',
  invert = false,
  stroke,
  isVisible = true,
  className = '',
  style = {},
  children,
  ...props
}: GeoJsonProps) => {
  const [, updateState] = useState<Record<string, unknown>>()
  useEffect(() => updateState({}), [className])
  if (!isVisible) return null

  return (
    <div>
      <CustomGeoJson
        className={`${className} geo-json`}
        data={data}
        invert={invert}
        style={{
          fillColor,
          fillOpacity,
          weight,
          color,
          stroke,
          ...style,
        }}
        {...props}>
        {children}
      </CustomGeoJson>
    </div>
  )
}
