import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import styles from './use-dropdown.module.scss';


type UseDropdownProps = {
  header?: JSX.Element;
  body?: JSX.Element;
  trigger?: JSX.Element;
};


export const useDropdown = ({ header, body, trigger }: UseDropdownProps) => {
  const [isDropdownOpened, setDropdownOpen] = useState<boolean>(false);

  const triggerRef = useRef<HTMLDivElement | null>(null);
  const nodeRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutSideOfDropdown = useCallback(
    (e: MouseEvent) => {
      if (triggerRef.current && triggerRef.current.contains(e.target as Node)) {
        return setDropdownOpen(!isDropdownOpened);
      }
      if (nodeRef.current && !nodeRef.current.contains(e.target as Node)) {
        return setDropdownOpen(false);
      }
    },
    [isDropdownOpened, setDropdownOpen],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutSideOfDropdown, true);
    return () =>
      document.removeEventListener('click', handleClickOutSideOfDropdown, true);
  }, [isDropdownOpened, handleClickOutSideOfDropdown]);

  const DropdownMenuContent = forwardRef<HTMLDivElement, any>((props, ref) => {
    return (
      <div className={styles['dropdown-menu-content']} ref={ref}
        // onClick={()=> setDropdownOpen(false)}
      >
        {header}
        {body}
      </div>
    );
  });

  const DropdownTrigger = forwardRef<HTMLDivElement, any>((props, ref) => {
    
    return (
      <div className={styles['dropdown-menu-trigger']} ref={ref}>
        {trigger}
      </div>
    );
  });

  return {
    renderDropdown: () => {
      return (
        <>
          {isDropdownOpened && <DropdownMenuContent ref={nodeRef} />}
          <DropdownTrigger ref={triggerRef} />
        </>
      );
    },
    isDropdownOpened,
    setDropdownOpen,
    triggerRef,
    nodeRef,
  };
};
