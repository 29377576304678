import type { ImageProps } from './image.types';

export const Image = ({ alt, thumb, src, onLoad = () => {}, ...props }: ImageProps) => {
  return (
    <>
      {src ? (
        <img
          onLoad={(e) => {
            onLoad(e);
          }}
          style={{
            width: '100%',
            height: '100%',
          }}
          alt={alt}
          src={src}
          {...props}
        />
      ) : (
        <img alt={alt} src={thumb} {...props} />
      )}
    </>
  );
};
