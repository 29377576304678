import styles from './p-tag.module.scss';
import type { PTagProps } from './p-tag.types';


export const PTag = ({
  weight = 1,
  children,
  className = '',
  x = '4',
  ...props
}: PTagProps): JSX.Element => {
  return (
    <p
      className={`${styles[`p-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};