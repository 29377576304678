import L from 'leaflet';
import { getMacroType, average } from './';
import type { MarkerClusterGroup as MarkerClusterGroupType } from 'leaflet';
import { getClusterColor } from './market';

export const createClusterCustomIcon = (cluster: MarkerClusterGroupType) => {
  const rating = average(
    cluster
      .getAllChildMarkers()
      .map((marker) => (marker.options as Record<string, unknown>).rating),
  );

  const color = getClusterColor(Math.floor(Number(rating)));

  const icon = L.divIcon({
    html: `<div>${cluster.getChildCount()}</div>`,
    className: `marker-cluster-custom macro-${color}`,
    iconSize: L.point(40, 40, true),
  });

  return icon;
};

export const createClusterGazpromIcon = (cluster: MarkerClusterGroupType) => {
  return L.divIcon({
    html: `<div>${cluster.getChildCount()}</div>`,
    className: `marker-cluster-custom macro-gazprom`,
    iconSize: L.point(40, 40, true),
  });
};

export const createClusterCustomMacroIcon =
  (color: number) => (cluster: MarkerClusterGroupType) => {
    const macroView = getMacroType(Number(color));

    return L.divIcon({
      html: `<div>${cluster.getChildCount()}</div>`,
      className: `marker-cluster-custom macro-${macroView}`,
      iconSize: L.point(40, 40, true),
    });
  };
