import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import  throttle from 'lodash/throttle';
import { useRef } from 'react';


export const useSearchParamsAsObject = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const ref = useRef(0);
  // ref.current = ref.current + 1;
  // console.log('ref', ref);

  // const searchParamsNew = new URLSearchParams(location.search.replace('?', ''));
  //
  // const setSearchParamsNew = (params: Record<any, any>) => {
  //   // const searchParamsNew1 = new URLSearchParams(location.search.replace('?', ''));
  //   //
  //   // Object.entries(params).forEach(([key, value]: [string, string]) => {
  //   //   searchParamsNew1.set(key, value);
  //   // });
  //   //
  //   // try {
  //   //   navigate({ pathname: `${location.pathname}`, search: searchParamsNew1.toString() });
  //   // } catch (e) {
  //   //   console.log(e);
  //   // }
  // };

  const handleSetSearchParams = throttle(setSearchParams, 3000);

  return [Object.fromEntries(searchParams.entries()), handleSetSearchParams] as const;
};
