import { useEffect, useMemo, useState } from 'react';
import { GeoJsonObject } from 'geojson';
import type { RegionsLayerProps } from './layers.types';
import {
  getActiveRegionIdsListFromObjects,
  getDistrictRegionsMacroRating,
} from '../../../../utils';
import { JSON_TEST_INFO } from '../../../../constants';
import styles from './layers.styles.module.scss';
import { useFilteredObjectsSelector } from '../../../../state';
import { LayerFeature } from '../layer-feature';
import { getActiveRegions } from '../api';
import { useMap } from 'context';

export const RegionsLayer = ({
  flyToRegion,
  isShortTitle,
  isGazpromChildActive,
  getCenterOfLayer,
}: RegionsLayerProps) => {
  const items = useFilteredObjectsSelector();
  const activeRegionsList = getActiveRegionIdsListFromObjects(items);
  const [regionsList, setRegionsList] = useState<any[]>([]);
  const { map } = useMap();

  useEffect(() => {
    const req = async () => {
      const items = await getActiveRegions(activeRegionsList);
      setRegionsList(items);
    };

    if (items.length && !regionsList.length) {
      req();
    }
  }, [items]);

  return (
    <>
      {regionsList.length &&
        regionsList.map((item: any, index: number) => {
          // const coords = getCenterOfLayer(item.data.features[0]);

          return (
            <LayerFeature
              key={index}
              featureId={item.id}
              isGazpromChildActive={isGazpromChildActive}
              data={item.data as GeoJsonObject}
              info={JSON_TEST_INFO}
              getFeatureRating={() => getDistrictRegionsMacroRating(items, item.id)}
              isVisible={activeRegionsList.includes(item.id)}
              name={map && map?.getZoom() >= 5 ? item.name : ''}
              coords={item.coords || getCenterOfLayer(item.data.features[0])}
              onClick={flyToRegion}
            />
          );
        })}
    </>
  );
};
