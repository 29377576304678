import { DesktopWidth } from '../constants';


export const getDeviceWidthObj = (width: number) => {
  
  const documentWidth = width;
  const isMobile = documentWidth < 768;
  const isDesktop = documentWidth > 768;
  const isTablet = documentWidth < 768 && documentWidth > 550;
  const isLargeDesktop = documentWidth < 1300;
  const isMobileOnly = documentWidth < 550;

  return {
    isLargeDesktop: isLargeDesktop,
    isMobile: isMobile,
    isDesktop: isDesktop,
    isTablet: isTablet,
    isMobileOnly: isMobileOnly,
  };
};