import { useIsAnalyticsViewOpenSelector } from '../../../state';
import { AnalyticsView } from './analytics-view';
import { useParams } from 'react-router-dom';
import { useEditorModeStateSelector } from '../../../state';


export const AnalyticsViewWrapper = () => {
  const isEditorMode = useEditorModeStateSelector();
  const isAnalyticsViewOpen = useIsAnalyticsViewOpenSelector();
  const { objectID } = useParams();

  return (
    <>
      {
      isAnalyticsViewOpen 
        ? <AnalyticsView 
          editable={isEditorMode}
          objectId={objectID} 
        />
        : <></>
      }
    </>
  );
};

