import { useEffect, useState } from 'react';
import { GeoJsonObject } from 'geojson';
import type { DistrictsLayerProps } from './layers.types';
import { JSON_TEST_INFO } from '../../../../constants';
import {
  getActiveDistrinctRegionsIdsListFromObjects,
  getDistrictMacroRating,
  isDistrictActive,
} from '../../../../utils';
import { useDistrictFilteredObjects } from '../../../../state';
import { LayerFeature } from '../layer-feature';
import { getActiveDistricts } from '../api';

export const DistrictsLayer = ({
  flyToDistrict,
  isGazpromChildActive,
  getCenterOfLayer,
}: DistrictsLayerProps) => {
  const items = useDistrictFilteredObjects();
  const districtFilteredObjects = getActiveDistrinctRegionsIdsListFromObjects(items);
  const [districtsList, setDistrictsList] = useState<any[]>([]);

  useEffect(() => {
    const req = async () => {
      const items = await getActiveDistricts(districtFilteredObjects);
      setDistrictsList(items);
    };

    if (items.length && !districtsList.length) {
      req();
    }
  }, [items]);

  return (
    <>
      {districtsList && districtsList.length
        ? districtsList.map((item: any, index: number) => {
            const coords = getCenterOfLayer(item.data.features[0]);

            return (
              <LayerFeature
                key={index}
                featureId={item.regions}
                isGazpromChildActive={isGazpromChildActive}
                data={item.data as GeoJsonObject}
                info={JSON_TEST_INFO}
                getFeatureRating={() => getDistrictMacroRating(items, item.regions)}
                name={item.name}
                isVisible={isDistrictActive(item.regions, districtFilteredObjects)}
                onClick={flyToDistrict}
                coords={coords}
              />
            );
          })
        : null}
    </>
  );
};
