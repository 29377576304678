import type { DistanceValueType } from '../map-view.types';


export const getDistanceValue = (zoomValue: number): DistanceValueType => {
  if (zoomValue <= 5) {
    return 'far';
  }
  if ((zoomValue === 6) || (zoomValue === 7)) {
    return 'middle';
  }
  return 'close';
};
