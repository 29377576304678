import { Tooltip } from 'react-leaflet'
import { ObjectRating, PTag } from '../../../common'
import type { FeatureTooltipProps } from './feature-tooltip.types'
import styles from './feature-tooltip.styles.module.scss'

const TooltipElement = Tooltip as typeof Tooltip & any

export const FeatureTooltip = ({ rate, name, body }: FeatureTooltipProps) => {
  return (
    <TooltipElement
      direction="bottom"
      sticky
      opacity={1}
      className={styles.container}>
      <ObjectRating
        text={name}
        stage={Number(rate)}
        style={{ margin: 0, padding: 0, height: 'auto' }}
      />
      <PTag x={'3.5'} weight={1} className={styles['container-body']}>
        {/* { body } */}
        Распределение бюджета:
        <ul style={{ padding: 0 }}>
          <li>Образование — 50%</li>
          <li>Научная деятельность — 30%</li>
          <li>Здравоохранение — 20%</li>
        </ul>
      </PTag>
    </TooltipElement>
  )
}
