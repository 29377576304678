import type { ClusterSwitcherButtonProps } from './layers-switcher.types';
import { Button } from '../common';
import { useBreakpointsComparison } from '../../hooks';
import { ScreenSize } from '../../constants';


export const LayersSwitchButton = ({
  checked = false,
  children,
  ...props
}: ClusterSwitcherButtonProps) => {
  const isMobile = useBreakpointsComparison(ScreenSize.MP);

  return (
    <Button 
      size={isMobile ? 's' : 'l'}
      typeBtn={!checked ? 'primary' : 'elevation'}
      elementType={'button'}
      {...props}
    >
      {children}
    </Button>
  );
};
