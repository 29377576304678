import type { H1TagProps } from './h-tags.types';
import { HTagFabric } from '../../utils';


export const H4Tag = (props: H1TagProps): JSX.Element => {
  return (
    <HTagFabric tag='h4' {...props}>
      {props.children}
    </HTagFabric>
  );
};