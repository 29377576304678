import { Breakpoints } from '../constants';
import { useDeviceWidthTracker } from './use-device-width-tracker';
import { useState } from 'react';


export const useBreakpointsComparison = (
  breakpointKey: keyof typeof Breakpoints,
  direction: 'isLower' | 'isGreater' | undefined = 'isLower',
): boolean => {
  const [breakpoint, setBreakpoint] = useState(window?.innerWidth);
  useDeviceWidthTracker(() => {
    if (window) {
      setBreakpoint(window.innerWidth);
    } 
  });
  
  const isLower = breakpoint <= Breakpoints[breakpointKey];

  return direction === 'isLower' ? isLower : !isLower;
};
