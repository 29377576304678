import React from 'react';
import { ViewSwitcherProps } from './view-switcher.types';
import { Button, ButtonGroup } from '../common';
import { useDeviceSelector } from '../../state/selector';
import styles from './view-switcher.module.scss';


export const ViewSwitcher = ({
  editorMode = false,
  role = 'redactor',
  view = 'map',
  onSelectViewMode,
}: ViewSwitcherProps) => {
  const { isMobile } = useDeviceSelector();

  const viewModes = {
    0: 'map',
    1: 'list',
    2: 'table',
  };

  if (role === 'redactor' && editorMode) {
    return (
      <ButtonGroup className={styles.container} direction={'row'}>
        <Button
          isActive={view === 'map'}
          onClick={() => onSelectViewMode(viewModes[0])}
          elementType={'button'}
          typeBtn={'uncolored'}
          showLeftIcon={true}
          leftIcon={<i className="icon icon-map icon-middle"></i>}
        />
        <Button
          isActive={view === 'list'}
          onClick={() => onSelectViewMode(viewModes[1])}
          elementType={'button'}
          typeBtn={'uncolored'}
          showLeftIcon={true}
          leftIcon={<i className="icon icon-list icon-middle"></i>}
        />
        {/*<Button*/}
        {/*  isActive={view === 'table'}*/}
        {/*  onClick={() => onSelectViewMode(viewModes[2])}*/}
        {/*  elementType={'button'}*/}
        {/*  typeBtn={'uncolored'}*/}
        {/*  showLeftIcon={true}*/}
        {/*  leftIcon={<i className="icon icon-table icon-middle"></i>}*/}
        {/*/>*/}
      </ButtonGroup>
    );
  }

  return (
    <Button
      size={'l'}
      onClick={
        view === 'map'
          ? () => onSelectViewMode(viewModes[1])
          : () => onSelectViewMode(viewModes[0])
      }
      elementType={'button'}
      typeBtn={'elevation'}
      showRightIcon={true}
      rightIcon={
        view === 'list' ? (
          <i className="icon icon-map icon-middle"></i>
        ) : (
          <i className="icon icon-list icon-middle"></i>
        )
      }
      text={
        view === 'list'
          ? `${!isMobile ? 'Объекты на' : 'На'} карте`
          : `${!isMobile ? 'Объекты списком' : 'Списком'}`
      }
    />
  );
};
