import React, { useCallback, useLayoutEffect, useState } from 'react';
import { filterObjects, useAppDispatch, useDeviceSelector } from '../../state';
import {
  useEditorModeStateSelector,
  useFavoriteObjectsSelector,
  useEnableObjectFiltersSelector,
  resetFilters,
} from '../../state';
import { useAppObjects } from '../../state/selector/selector';
import { SidebarFilter } from './sidebar-filter';
import { BottomSheet } from '../common/bottom-sheet';
import { SidebarContainer } from '../common';
import {
  useSelectedObjectSelector,
  useSidebarState,
} from '../../history-state/selectors';
import { IDegreeOfSatisfaction } from '../../types';

export const SidebarFilterWrapper = () => {
  const dispatch = useAppDispatch();
  const isEditorMode = useEditorModeStateSelector();
  const objects = useAppObjects();
  const filters = useEnableObjectFiltersSelector();
  const [loading, setLoading] = useState<boolean>(false);
  const favorites = useFavoriteObjectsSelector();
  const [isSidebarOpen, setSidebarOpen] = useSidebarState();
  const selectedObjectId = useSelectedObjectSelector();
  const { isMobile } = useDeviceSelector();

  const submitFiltersHandler = (
    selectedMacroRegions: number[],
    selectedProgram: number[],
    selectedStartOfBuilding: number[],
    selectedTypeOfWork: number[],
    selectedRealisationStatus: number[],
    showOnlyFavorite: boolean,
    selectedNationalProjects: number[],
    selectedDirections: number[],
    selectedDegreeOfSatisfaction: IDegreeOfSatisfaction,
    selectedGazpromFilters: number[],
  ) => {
    dispatch(
      filterObjects({
        macro_region: selectedMacroRegions,
        program: selectedProgram,
        start_of_building: selectedStartOfBuilding,
        type_of_work: selectedTypeOfWork,
        status: selectedRealisationStatus,
        showOnlyFavorite,
        national_projects: selectedNationalProjects,
        directions: selectedDirections,
        degree_of_satisfaction: selectedDegreeOfSatisfaction,
        gazprom: selectedGazpromFilters,
      }),
    );
  };

  const resetFiltersHandler = () => {
    dispatch(resetFilters());
  };

  useLayoutEffect(() => {
    resetFiltersHandler();
  }, [isEditorMode, dispatch]);

  useLayoutEffect(() => {
    setLoading(!(objects && objects.length > 0));
  }, [objects]);
  const bottomIndex = 0.48;

  return (
    <>
      {isSidebarOpen && !selectedObjectId && !isMobile && (
        <SidebarContainer>
          <SidebarFilter
            objects={objects}
            favorites={favorites}
            editorMode={isEditorMode}
            loading={loading}
            onFilter={submitFiltersHandler}
            resetFiltersHandler={resetFiltersHandler}
            externalShowOnlyFavorite={filters.showOnlyFavorite}
            externalMacroRegions={filters.macro_region}
            externalStartOfBuilding={filters.start_of_building}
            externalTypeOfWork={filters.type_of_work}
            externalProgram={filters.program}
            externalRealisationStatus={filters.status}
            externalNationalProjects={filters.national_projects}
            externalDirections={filters.directions}
            externalDegreeOfSatisfaction={filters.degree_of_satisfaction}
            externalGazpromFilters={filters.gazprom}
          />
        </SidebarContainer>
      )}

      <BottomSheet
        open={isSidebarOpen && !selectedObjectId && isMobile}
        onDismiss={() => {
          setSidebarOpen(false);
        }}
        blocking={false}
        defaultSnap={({ maxHeight }: { maxHeight: number }) => maxHeight * bottomIndex}
        snapPoints={({ maxHeight }: { maxHeight: number }) => [
          maxHeight * bottomIndex,
          maxHeight,
        ]}
      >
        <SidebarFilter
          objects={objects}
          favorites={favorites}
          editorMode={isEditorMode}
          loading={loading}
          onFilter={submitFiltersHandler}
          resetFiltersHandler={resetFiltersHandler}
          externalShowOnlyFavorite={filters.showOnlyFavorite}
          externalMacroRegions={filters.macro_region}
          externalStartOfBuilding={filters.start_of_building}
          externalTypeOfWork={filters.type_of_work}
          externalProgram={filters.program}
          externalRealisationStatus={filters.status}
          externalNationalProjects={filters.national_projects}
          externalDirections={filters.directions}
          externalDegreeOfSatisfaction={filters.degree_of_satisfaction}
          externalGazpromFilters={filters.gazprom}
        />
      </BottomSheet>
    </>
  );
};

export default SidebarFilterWrapper;
