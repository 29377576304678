import { RatingGrade } from '../../../../../constants';

export const setFillColor = (rating: number, isGazpromChild: boolean) => {
  if (isGazpromChild) return '#0091FE';
  if (rating >= RatingGrade.G_10) return '#4DE111';
  if (rating >= RatingGrade.G_9) return '#9CE111';
  if (rating >= RatingGrade.G_8) return '#C5E111';
  if (rating >= RatingGrade.M && rating <= RatingGrade.G) return '#F2AB1C';
  return '#F32121';
};

export const setHoverFillColor = (rating: number, isGazpromChild: boolean) => {
  if (isGazpromChild) return '#0091FE';
  if (rating >= RatingGrade.G_10) return '#44C70F';
  if (rating >= RatingGrade.G_9) return '#83BE0E';
  if (rating >= RatingGrade.G_8) return '#A6BE0E';
  if (rating >= RatingGrade.M && rating <= RatingGrade.G) return '#C88A0D';
  return '#c50707';
};

export const setStrokeColor = (rating: number, isGazpromChild: boolean) => {
  if (isGazpromChild) return '#0075CD';
  if (rating >= RatingGrade.G_8) return '#5A9A27';
  if (rating >= RatingGrade.M && rating <= RatingGrade.G) return '#A8960B';
  return '#BA3C10';
};

export const setHoverStrokeColor = (rating: number, isGazpromChild: boolean) => {
  if (isGazpromChild) return '#0075CD';
  if (rating >= RatingGrade.G_8) return '#458215';
  if (rating >= RatingGrade.M && rating <= RatingGrade.G) return '#966709';
  return '#921212';
};
