export const initialState = [
  [
    'Вид работ',
    'Срок начала',
    'Срок окончания',
    '% выполнения',
    'Предел затрат, млн',
    'План затрат на 2022, млн',
    'Факт затрат на , млн',
  ],
  ['Предварительные работы', '', '', '0', '0', '0', '0'],
  ['ПД', '', '', '0', '0', '0', '0'],
  ['РД', '', '', '0', '0', '0', '0'],
  ['СМР', '', '', '0', '0', '0', '0'],
];
