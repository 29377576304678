export const program = {
  0: 'Другое',
  1: 'Восстановление ОКН',
  2: 'Социальная программа',
}

export const macroRegion = {
  0: 'Другие регионы',
  1: 'Витрина',
  2: 'Юг-Черноземье',
  3: 'Северо-запад',
  4: 'Дальний Восток',
}

export const Heading = {
  passability: 'Вместимость храма: ',
  employeeVisits: 'Количество сотрудников ПАО «Газпром»: ',
  necessaryInvest: 'Объём благотворительности, руб: ',
  distanceDistrictCenter: 'До административного здания филиала: ',
  owner: 'Администрация: ',
  place: 'Адрес: ',
  macroRegionValue: 'Макрорегион: ',
  programValue: 'Программа: ',
  typeOfWork: 'Тип строительства: ',
  realizationPeriod: 'Срок реализации: ',
  name: 'Объект: ',

  rating: 'Удовлетворённость: ',
  nationalProject: 'Национальный проект: ',
  direction: 'Направление: ',
}

export const START_OF_BUILDING = {
  0: 'Дата не указана',
  1: '2021',
  2: '2022',
  3: '2023',
}

export const TYPE_OF_WORK = {
  0: 'Тип не указан',
  1: 'Новое строительство',
  2: 'Завершение строительства',
  3: 'Ремонт и восстановление',
}

export enum RatingGrade {
  G_10 = 10,
  G_9 = 9,
  G_8 = 8,
  G = 8.5, // great
  M = 3, // middle
}

export const JSON_TEST_INFO = `
Распределение бюджета:
Образование — 50%
Научная деятельность — 30%
Здравоохранение — 20%`
