import { BTag } from '../text-element';
import cn from 'classnames';
import { RatingGrade } from '../../../constants';
import { H4Tag } from '../h-tags';
import type { ObjectRatingProps } from './object-rating.types';
import styles from './object-rating.module.scss';


export const ObjectRating = ({
  text = 'Инвестиционная привлекательность',
  showText = true,
  stage = '?',
}: ObjectRatingProps): JSX.Element => {
  const investmentAttractivnessClasses = cn(styles['investment-attractivness'], {
    [styles['attractivness-high']]: stage >= RatingGrade.G,
    [styles['attractivness-middle']]: stage >= RatingGrade.M && stage <= RatingGrade.G,
    [styles['attractivness-low']]: stage <= RatingGrade.M,
  });

  return (
    <div className={styles.container}>
      <div className={investmentAttractivnessClasses}>
        <BTag>{stage}</BTag>
      </div>
      {showText && <H4Tag 
        className={styles['attractivness-text']}
        weight={2}
      >{text}</H4Tag>}
    </div>
  );
};
