import styles from './header-container.module.scss';
import { HeaderContainerProps } from './header-container.types';
import cn from 'classnames';
import React from 'react';

export const HeaderContainer = ({
  isSidebarOpened,
  children,
  className = '',
  view,
  isLoading,
  isScrolling,
}: HeaderContainerProps) => {
  const rightBtnsClasses = cn(styles['header-container'], {
    [`${styles.opened}`]: isSidebarOpened,
  });

  return (
    <header
      className={`${styles['header-container']} ${className} ${
        view === 'list' && isScrolling ? styles['header-list-view-bg'] : ''
      }`}
    >
      {children}
    </header>
  );
};
