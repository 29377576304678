import styles from '../profile-menu-map-page.module.scss';
import { ProfileMenuButtons } from './profile-menu-buttons';


export const ProfileMenuContent = () => {
  
  return (
    <div className={styles['profile-menu-content']}>
      <ProfileMenuButtons />
    </div>
  );
};
