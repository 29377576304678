import { useState } from 'react';

import { FilterCheckbox, FilterGroup, PTag, SpanTag } from 'components';
import { SunIcon, GazpromChildrenIcon } from 'icons';

import styles from './gazprom-filter-button.styles.module.scss';

import type { GazpromFilterButtonProps } from './gazprom-filter-button.types';

export const GazpromFilterButton = ({
  children,
  options,
  onChange,
  activeFiltersList,
  isActive,
}: GazpromFilterButtonProps) => {
  const [isOpen, setOpen] = useState<boolean>(() => {
    const items = options.filter((item) => activeFiltersList.includes(item.id));
    if (items.length) return true;
    return false;
  });

  const isFilterActive = isActive || isOpen;

  return (
    <div className={styles.container}>
      <PTag
        className={`${styles['container-toggle']} ${
          isFilterActive ? styles['container-toggle-opened'] : ''
        }`}
        onClick={() => setOpen(!isOpen)}
      >
        <GazpromChildrenIcon
          className={`${styles['container-toggle-icon-gazprom']} ${
            isFilterActive ? styles['container-toggle-icon-gazprom-active'] : ''
          }`}
        />
        <SunIcon
          className={`${styles['container-toggle-icon-sun']} ${
            isFilterActive ? styles['container-toggle-icon-sun-active'] : ''
          }`}
        />
      </PTag>
      <FilterGroup
        className={`${styles['container-list']} ${
          isOpen ? styles['container-list_opened'] : ''
        }`}
      >
        {options.length &&
          options.map((item: { value: string; id: number }) => {
            return (
              // <CustomCheckbox key={item.id} />
              <FilterCheckbox
                handleChange={() => onChange(item.id)}
                key={item.id}
                type={'checkbox'}
                isChecked={activeFiltersList.includes(item.id)}
                className={styles['container-list-item']}
              >
                {item.value}
              </FilterCheckbox>
            );
          })}
      </FilterGroup>
    </div>
  );
};
