import styles from './profile.module.scss';
import { ProfileProps } from './profile.types';
import { Avatar } from '../avatar';
import React from 'react';


export const Profile = ({ children, isProfileMenuOpened = false, src } : ProfileProps) => {
  
  return <div className={styles['profile-container']}>
    <Avatar src={src}/>
    {/*<IconClose className={styles.icon}/>*/}
  </div>;
};

//{isProfileMenuOpened ? <IconClose className={styles.icon} /> : <IconCamera className={styles.icon} />}