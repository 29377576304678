import { useEffect } from 'react';
import { filterObjects, useAppDispatch } from '../../state';
import { useSearchParams } from 'react-router-dom';
import { useEnableObjectFiltersSelector } from '../../state/selector';
import batchUpdateHistory from '../../utils/batch-update-history';
import { usePrevious } from '../../hooks';
import isEqual from 'lodash/isEqual';
import { RangeValues } from '../../constants';

export const HistorySelectedFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const filters = useEnableObjectFiltersSelector();
  const prevFilters = usePrevious(filters);

  useEffect(() => {
    const getFilterValueFromQuery = (queryName: string): number[] => {
      if (searchParams.get(queryName)) {
        return searchParams
          .get(queryName)
          ?.split(',')
          .map((el) => Number(el)) as number[];
      }
      return [];
    };

    const macroRegions = getFilterValueFromQuery('macro_region');
    const program = getFilterValueFromQuery('program');
    const startDate = getFilterValueFromQuery('start_of_building');
    const typeOfWork = getFilterValueFromQuery('type_of_work');
    const status = getFilterValueFromQuery('status');
    const nationalProjects = getFilterValueFromQuery('national_projects');
    const directions = getFilterValueFromQuery('directions');
    const gazprom = getFilterValueFromQuery('gazprom');
    const minRating = searchParams.get('min_rating') || 0;
    const maxRating = searchParams.get('max_rating') || 10;

    dispatch(
      filterObjects({
        macro_region: macroRegions,
        program,
        start_of_building: startDate,
        type_of_work: typeOfWork,
        status,
        showOnlyFavorite: false,
        national_projects: nationalProjects,
        directions: directions,
        degree_of_satisfaction: {
          min: Number(minRating),
          max: Number(maxRating),
        },
        gazprom,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!isEqual(filters, prevFilters)) {
      batchUpdateHistory(
        [
          ['macro_region', filters.macro_region],
          ['program', filters.program],
          ['start_of_building', filters.start_of_building],
          ['type_of_work', filters.type_of_work],
          ['status', filters.status],
          ['show_favorite', filters.showOnlyFavorite],
          ['national_projects', filters.national_projects],
          ['directions', filters.directions],
          ['min_rating', filters.degree_of_satisfaction.min],
          ['max_rating', filters.degree_of_satisfaction.max],
          ['gazprom', filters.gazprom],
        ],
        searchParams,
        setSearchParams,
      );
    }
  }, [filters, searchParams, setSearchParams, prevFilters]);

  return null;
};
