import React, { useEffect, useRef } from 'react';
import styles from './search-dropdown.module.scss';
import { SearchDropdownProps } from './search-dropdown.types';
// import { SearchScoping } from '../search-scoping';
// import { Button } from '../button';
import { SearchResults } from '../search-results';
import { SearchItem } from '../search-item';

export const SearchDropdown = ({
  inputQueryValue,
  className,
  children,
  handleFilterClick,
  hideDropdown,
  items,
  activeSuggestion,
  handleMouseEnterOnSearchSuggestion,
  ...props
}: SearchDropdownProps) => {
  const searchResultsContainerRef = useRef<HTMLDivElement | null>(null);
  const searchItemContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const resultsContainer = searchResultsContainerRef.current;
    const resultsItemContainer = searchItemContainerRef.current;

    if (resultsContainer !== null && resultsItemContainer !== null) {
      document.addEventListener('keydown', (e: KeyboardEvent) => {
        const { code } = e;
        if (code === 'ArrowUp') {
          searchResultsContainerRef.current?.scrollBy({
            left: 0,
            top: -60,
            behavior: 'smooth',
          });
        }
        if (code === 'ArrowDown') {
          searchResultsContainerRef.current?.scrollBy({
            left: 0,
            top: 100,
            behavior: 'smooth',
          });
        }
      });
    }
  }, []);
  return (
    <div className={styles.container}>
      <SearchResults
        titleSuccess={items.length > 0}
        searchResultsContainerRef={searchResultsContainerRef}
      >
        {items.slice(0, 5).map((r, idx) => (
          <SearchItem
            inputQueryValue={inputQueryValue}
            handleMouseEnterOnSearchSuggestion={handleMouseEnterOnSearchSuggestion}
            searchItemContainerRef={searchItemContainerRef}
            hideDropdown={hideDropdown}
            name={r.name}
            initiator={r.owner}
            place={r.place}
            key={r.id}
            idx={idx}
            item={r}
            isActiveSuggestion={activeSuggestion === idx}
            activeSuggestion={activeSuggestion}
          />
        ))}
      </SearchResults>
    </div>
  );
};
