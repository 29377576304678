import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { BottomSheet } from '../bottom-sheet';
import { useComponentVisible, usePrevious } from '../../../hooks';
import styles from './dropdown.module.scss';


interface DropdownProps {
  trigger: ReactNode;
  header?: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
  props?: Record<string, any>;
  onClose?: () => void;
  onOpen: () => void;
  isBottomSheet: boolean;
  isVisible: boolean;
  shouldCloseOnSelect?: boolean;
  bottomSheetHeight?: number;
}

export const Dropdown = ({
  trigger,
  header,
  body,
  footer,
  props,
  onClose,
  onOpen,
  isBottomSheet,
  isVisible,
  shouldCloseOnSelect = true,
  bottomSheetHeight,
}: DropdownProps) => {
  
  const [ref, open, setOpen] = useComponentVisible(() => isVisible);
  const prevOpen = usePrevious(open);
  
  const onTriggerClick = useCallback(() => setOpen(!open), [open, setOpen]);
  
  useEffect(() => {
    if (open !== undefined && open !== prevOpen) {
      if (open) {
        !!onOpen && onOpen();
      } else {
        !!onClose && onClose();
      }
    }
  }, [open, prevOpen, onOpen, onClose]);
  
  useEffect(() => {
    if (isVisible !== undefined) {
      if (isVisible !== prevOpen) {
        setOpen(isVisible);
      }
    }
  }, [isVisible, setOpen, prevOpen]);
  
  const onContentLeave = useCallback(() => setOpen(false), [setOpen]);
  
  
  const content = useMemo(() => (
    <>
      {header && <div className={styles['dropdown-header']}>{header}</div>}
      <div className={styles['dropdown-body']} onClick={() => shouldCloseOnSelect && onContentLeave()}>
        {body}
      </div>
      {footer && <div className={styles['dropdown-footer']}>{footer}</div>}
    </>
  ), [body, footer, header, onContentLeave, shouldCloseOnSelect]);
  
  const dropdown = useMemo(() => {
    return open && <div
      ref={ref}
      className={styles['dropdown-content']}
      {...props}
    >
      {content}
    </div>;
  }, [open, ref, props, content]);
  
  const localBottomSheetHeight = bottomSheetHeight ? bottomSheetHeight > 100 ? 1 : bottomSheetHeight < 0 ? 0 : bottomSheetHeight / 100 : 0.4;
  
  return useMemo(() => (
    <div className={styles[`dropdown-wrapper ${open ? 'open' : ''}`]}>
      <div onClick={onTriggerClick} className={styles['dropdown-trigger']}>
        {trigger}
      </div>
      {isBottomSheet ? <BottomSheet open={open}
        onDismiss={onContentLeave}
        blocking={true}
        style={{ zIndex: 2000 }}
        defaultSnap={({ maxHeight }: { maxHeight: number }) => maxHeight * localBottomSheetHeight}
        snapPoints={({ maxHeight }: { maxHeight: number }) => [maxHeight * localBottomSheetHeight, maxHeight]}>{content}</BottomSheet> : dropdown}
    
    </div>
  ), [open, onTriggerClick, trigger, isBottomSheet, onContentLeave, content, dropdown, localBottomSheetHeight]);
};

