import { useAppSelector } from './selector';
import {
  ContentTypes,
  Group,
  LineData,
  ProjectData,
  SelectedLineContentPayload,
} from '../../types/monitoring/monitoring-content-types';
import { CONTENT_TYPES } from '../../constants';
import { useMemo } from 'react';
import { simplifyArr } from '../../utils';
import { array } from 'yup';


export const useSelectedLineContentSelector = (lineID: string): any => {
  const selectedLineContent = useAppSelector(
    ({ monitoring }) => monitoring.selectedLineContent,
  );
  
  return selectedLineContent &&
  (Number(selectedLineContent.lineID) === Number(lineID) ||
    Number(selectedLineContent.lineId) === Number(lineID))
    ? selectedLineContent
    : null;
};

export const useGetProjectSelector = () => useAppSelector(({ monitoring }) => monitoring.project);

export const usePanoramaSelectedLineContentSelector = (lineID: string) => {
  const selectedLineContent = useSelectedLineContentSelector(lineID);
  
  return (
    selectedLineContent &&
    selectedLineContent.payload &&
    selectedLineContent.payload.find(
      (content: SelectedLineContentPayload) =>
        content.contentType === CONTENT_TYPES.PANORAMA.TYPE && content.data.length > 0,
    )
  );
};
export const useAeroPanoramaSelectedLineContentSelector = (lineID: string) => {
  const selectedLineContent = useSelectedLineContentSelector(lineID);
  
  return (
    selectedLineContent &&
    selectedLineContent.payload &&
    selectedLineContent.payload.find(
      (content: SelectedLineContentPayload) =>
        content.contentType === CONTENT_TYPES.AERO_PANORAMA.TYPE && content.data.length > 0,
    )
  );
};
export const useImageSelectedLineContentSelector = (lineID: string) => {
  const selectedLineContent = useSelectedLineContentSelector(lineID);
  
  return (
    selectedLineContent &&
    selectedLineContent.payload &&
    selectedLineContent.payload.find(
      (content: SelectedLineContentPayload) =>
        content.contentType === CONTENT_TYPES.IMAGE.TYPE && content.data.length > 0,
    )
  );
};
export const useVideoSelectedLineContentSelector = (lineID: string) => {
  const selectedLineContent = useSelectedLineContentSelector(lineID);
  
  return (
    selectedLineContent &&
    selectedLineContent.payload &&
    selectedLineContent.payload.find(
      (content: SelectedLineContentPayload) =>
        content.contentType === CONTENT_TYPES.VIDEO.TYPE && content.data.length > 0 ||
        content.contentType === CONTENT_TYPES.TIMELAPSE.TYPE && content.data.length > 0,
    )
  );
};
export const useAerialSelectedLineContentSelector = (lineID: string) => {
  const selectedLineContent = useSelectedLineContentSelector(lineID);
  
  return (
    selectedLineContent &&
    selectedLineContent.payload &&
    selectedLineContent.payload.find(
      (content: SelectedLineContentPayload) =>
        content.contentType === CONTENT_TYPES.AERIAL.TYPE && content.data.length > 0,
    )
  );
};
export const useTimelapseSelectedLineContentSelector = (lineID: string) => {
  const selectedLineContent = useSelectedLineContentSelector(lineID);
  
  return (
    selectedLineContent &&
    selectedLineContent.payload &&
    selectedLineContent.payload.find(
      (content: SelectedLineContentPayload) =>
        content.contentType === CONTENT_TYPES.TIMELAPSE.TYPE && content.data.length > 0,
    )
  );
};

export const useContentByTypeSelectedLineContentSelector = (
  lineID: string,
  contentType: ContentTypes,
) => {
  const selectedLineContent = useSelectedLineContentSelector(lineID);
  
  return (
    selectedLineContent &&
    selectedLineContent.payload &&
    selectedLineContent.payload.find((content: SelectedLineContentPayload) => {
      return content.contentType === contentType && content.data.length > 0;
    })
  );
};


export const useProjectByIdSelector = (id: string | undefined) => {
  const projects = useAppSelector(({ monitoring }) => monitoring.projects);
  const projectById =
    id &&
    !!projects &&
    !!projects.length &&
    projects.find((project: ProjectData) => project.id === parseInt(id));
  
  return projectById;
};
export const useLineFromProjectsByIdSelector = (
  projectID: string | undefined,
  lineID: string | undefined,
) => {
  const project = useProjectByIdSelector(projectID);
  
  return useMemo(() => {
    if (lineID) {
      const line =
        project &&
        project.lines &&
        project.lines.find((pr: LineData) => pr.id === parseInt(lineID));
      if (line) {
        return line;
      } else {
        return (
          project &&
          project.groups &&
          simplifyArr(
            project.groups
              .map((group: Group) =>
                group.lines.find((line: LineData) => line.id === parseInt(lineID)),
              )
              .filter((group: any) => !!group),
          )[0]
        );
      }
    }
    return null;
  }, [project, lineID]);
};

export const useLineImagesFullscreen = () => {
  return useAppSelector(({ monitoring }) => monitoring.isImagesFullscreen);
};


export const useAvailableDatesByTypeSelectedLineContentSelector = (
  lineID: string,
  contentType: string | undefined,
) => {
  const selectedLineContent = useSelectedLineContentSelector(lineID);
  const content =
    selectedLineContent &&
    selectedLineContent.payload.find(
      (content: SelectedLineContentPayload) => content.contentType === contentType,
    );
  
  return content && content.availableDates;
};


export const useAvailableDatesAllContentSelector = (
  lineID: string,
) => {
  const selectedLineContent = useSelectedLineContentSelector(lineID);
  const payload = selectedLineContent && selectedLineContent.payload && selectedLineContent.payload.length > 0 ? selectedLineContent.payload : null;
  
  const reduce = payload ? payload.reduce((acc: [], curr: Record<string, any>) => {
   
    if (curr.availableDates) {
      const simply = curr.availableDates.reduce((acc: [], curr: any) => [...acc, curr.date], []);
      
      const curFiltered = simply.filter((el: any) => {
        
        const findEl =  acc.find((af:any) => af === el);

        return !findEl;
      });
      
      return [...acc, ...curFiltered];
    }
    
    return acc;
  }, []) : [];
  
  const sortReduce =  reduce.sort((a : string, b : string) =>
    new Date(b).getTime() - new Date(a).getTime(),
  );

  return sortReduce;
};
