import styles from './input.module.scss';
import type { TextInputProps } from './input.types';
import { useRef, FC, useId } from 'react';


export const Input:FC<TextInputProps> = ({
  error = null,
  label = '',
  className,
  id,
  ...props
}): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  const localId = useId();

  return (
    <div className={styles['input-field-label']}>
      <label htmlFor={id || localId} className={`${styles['input-field-label-text']} ${error ? styles.error : ''}`}>
        {label}
      </label>
      <div className={styles['input-field-input-wrapper']}>
        <input
          id={id || localId}
          ref={ref}
          className={`${styles['input-field-input']} ${error ? styles.error : ''} ${className ? className : ''}`}
          {...props}
        />
      </div>
    </div>
  );
};
