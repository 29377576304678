import './creation-form.scss';
import { Button } from '../../../common';


const CreationForm = ({ children, title, onSubmit, isHidden, onCancel, isActive, isBlocked }) => {

  return (
    <div className={`creation-form-wrapper ${isHidden ? 'invisible' : ''}`}>
      <div className="creation-form-section">
        <h3 className="creation-form-header">{title}</h3>
      </div>
      <div className="creation-form-section body">{children}</div>
      <div className="creation-form-section footer">
        <Button 
          className={'creation-form-cancel-button'}
          elementType={'button'} 
          typeBtn={'secondary'}
          children={'Отменить'} 
          showLeftIcon 
          onClick={onCancel}
          style={{ gap: 4 }}
        />
        <Button 
          className={`creation-form-submit-button ${isActive && !isBlocked ? 'active' : ''}`}
          elementType={'button'} 
          typeBtn={'secondary'}
          children={'Добавить'} 
          showLeftIcon 
          disabled={!isActive}
          onClick={onSubmit}
          style={{ gap: 4 }}
        />
      </div>
    </div>
  );
};

export default CreationForm;
