import { BrowserRouter as Router } from 'react-router-dom';
import { Router as Routes } from '../../router';
import { useDebouncedEffect } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../state';
import { identifyTheDevice } from '../../state';


export const App = () => {
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector(({ user }) => user.token);

  useDebouncedEffect(() => {
    const updateSize = (): void => {
      
      dispatch(identifyTheDevice(document.body.clientWidth));
    };
    window.addEventListener('resize', updateSize);
    return (): void => window.removeEventListener('resize', updateSize);
  }, []);
 
  return (
    <>
      <Routes isAuth={!!accessToken} />
    </>
  );
};
