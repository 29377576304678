import styles from './map-zoom-buttons.module.scss'
import { ButtonGroup, Button, ToolTip } from '../common'
import type { MapZoomProps } from './map-zoom-buttons.types'
import { useMap } from '../../context'
import { Map as MapConstants } from '../../constants/map'
import { useFlyToObjectOnMap } from '../../hooks'
import { setMapLayer, useAppDispatch } from '../../state'
import { Layers as LayersList } from '../../constants'

export const MapZoomButtons = () => {
  const { map } = useMap()
  const flyTo = useFlyToObjectOnMap()
  const dispatch = useAppDispatch()

  const zoomInHandler = () => {
    if (map) {
      map.setZoom(map.getZoom() + 1)
    }
  }

  const zoomOutHandler = () => {
    if (map) {
      map.setZoom(map.getZoom() - 1)
    }
  }

  const resetZoom = () => {
    if (map) {
      flyTo(
        [MapConstants.DEFAULT_LAT, MapConstants.DEFAULT_LNG] as [
          number,
          number,
        ],
        MapConstants.MIN_ZOOM,
        0.5,
      )
      dispatch(setMapLayer(LayersList.D))
    }
  }

  return (
    <>
      <ButtonGroup className={styles.container} direction={'column'}>
        <Button
          onClick={zoomInHandler}
          elementType={'button'}
          typeBtn={'uncolored'}
          showLeftIcon={true}
          leftIcon={<i className="icon icon-plus icon-middle"></i>}
          disabled={map?.getZoom() === MapConstants.MAX_ZOOM}
        />
        <div className={styles['h-line']} />
        <Button
          onClick={zoomOutHandler}
          elementType={'button'}
          typeBtn={'uncolored'}
          showLeftIcon={true}
          leftIcon={<i className="icon icon-minus icon-middle"></i>}
          disabled={map?.getZoom() === MapConstants.MIN_ZOOM}
        />
      </ButtonGroup>
      <ButtonGroup className={styles.container__reset} direction={'column'}>
        <ToolTip
          className={styles['container__reset-tooltip']}
          // delayHide={10000}
          isVisible
          placement={'right'}
          content={'Подняться к общему плану'}>
          <Button
            onClick={resetZoom}
            elementType={'button'}
            typeBtn={'uncolored'}
            style={
              map?.getZoom() === MapConstants.MIN_ZOOM
                ? { cursor: 'not-allowed' }
                : {}
            }
            onlyIcon
            showLeftIcon={true}
            leftIcon={<i className="icon icon-aim icon-middle"></i>}
            disabled={map?.getZoom() === MapConstants.MIN_ZOOM}
          />
        </ToolTip>
      </ButtonGroup>
    </>
  )
}
