import type { H1TagProps } from './h-tags.types';
import { HTagFabric } from '../../utils';


export const H1Tag = (props: H1TagProps): JSX.Element => {
  return (
    <HTagFabric tag='h1' {...props}>
      {props.children}
    </HTagFabric>
  );
};