import './object-creation-view.scss';
import { useCreateObjectModalSelector } from '../../../state/selector';
import { ObjectCreationForm } from './object-creation-form/object-creation-form';


export const ObjectCreationView = () =>{
  const isObjectModalOpen = useCreateObjectModalSelector();
  
  return (
    <>
      {isObjectModalOpen ? (
        <ObjectCreationForm />
      ) : null
      }
    </>);
};
