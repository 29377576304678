/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import './table-container.scss';
import {
  ColumnDef,
  ColumnResizeMode,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table';
import { OptionalRows, TableContainerProps } from './table-container.types';
import { Button, ButtonGroup } from '../../../common';


export const TableContainer = ({
  rows,
  cols,
  editable,
  handleUpdateTableCell,
  handleUpdateTableData,
  handleUpdateTableCols,
  editColumnMenu,
  editRowMenu,
}: TableContainerProps) => {
  const defaultColumns = useMemo<ColumnDef<OptionalRows>[]>(
    () => cols.map((col, idx) => ({ header: col, accessorKey: `${idx}` })),
    [cols, rows],
  );

  // const [columns] = React.useState<typeof defaultColumns>(() => [
  //   ...defaultColumns,
  // ]);

  const defaultColumn: Partial<ColumnDef<OptionalRows>> = {
    cell: ({ getValue, row: { index }, column: { id }, table }) => {
      const initialValue = getValue();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [value, setValue] = useState(initialValue);

      const onBlur = () => {
        table.options.meta?.updateData(index, Number(id), String(value));
      };

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      return editable ? (
        <>
          {id === '0' && editRowMenu(Number(index))}
          <textarea
            rows={5}
            style={{
              textAlign: 'center',
              minHeight: 'fit-content',
            }}
            className={'cell-value-analytics-table'}
            value={value as string}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
          />
        </>
        
      ) : value ? (
        <div className={'not-editable-content'}>
          <span>{value as ReactNode}</span>
        </div>
      ) : null;
    },
  };

  function useSkipper() {
    const shouldSkipRef = React.useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = React.useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    React.useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip] as const;
  }

  const [columnResizeMode, setColumnResizeMode] =
    React.useState<ColumnResizeMode>('onChange');

  const refreshData = () => handleUpdateTableData(cols, rows);

  useEffect(() => {
    refreshData();
  }, [rows, cols, defaultColumns]);

  const rerender = React.useReducer(() => ({}), {})[1];
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data: rows,
    columns: defaultColumns,
    defaultColumn,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
    // Provide our updateData function to our table meta
    meta: {
      updateData: (rowIndex: number, columnId: number, value: string) => {
        // Skip age index reset until after next rerender

        skipAutoResetPageIndex();
        handleUpdateTableCell({ rowIndex, columnId, value });
      },
    },
    debugTable: true,
  });

  return (
    <div className={'table-container-analytics-table'}>
      <div className={'table-wrap-analytics-table'}>
        <div
          {...{
            className: 'divTable-analytics-table',
          }}
        >
          <div className="thead-analytics-table">
            {table.getHeaderGroups().map((headerGroup) => (
              <div
                {...{
                  key: headerGroup.id,
                  className: 'tr-analytics-table tr-analytics-table-header',
                }}
              >
                {headerGroup.headers.map((header, idx) => (
                  <div
                    {...{
                      key: header.id,
                      className: 'th-analytics-table',
                      style: {
                        paddingTop: `${editable ? '20px' : '0'}`,
                      },
                    }}
                  >
                    {editable ? (
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '4px',
                      }}>
                        {editColumnMenu(idx)}
                        <textarea
                          className={'cell-value-analytics-table'}
                          value={cols[idx]}
                          onChange={(e) => handleUpdateTableCols(idx, e.target.value)}
                        //onBlur={onBlur}
                        />
                      </div>
                    ) : (
                      flexRender(header.column.columnDef.header, header.getContext())
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div
            {...{
              className:'tbody-analytics-table',
            }}
          >
            {table.getRowModel().rows.map((row) => (
              <div
                {...{
                  key: row.id,
                  className: 'tr-analytics-table',
                }}
              >
                {row.getVisibleCells().map((cell, idx) => {
                  return (
                    <div
                      {...{
                        key: cell.id,
                        className: `td-analytics-table ${
                          cell.column.id === '0' ? 'td-analytics-table-row-type' : ''
                        }`,
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
      { rows.length > 10 && <div className={'analytics-table-footer'}>
        <ButtonGroup direction={'row'} gap={8} maxWidth={'fill'} withBackground={false} justifyContent={'start'}>
          <Button
            maxWidth={'content'}
            typeBtn={'elevation'}
            elementType={'button'}
            text={'В начало'}
            onClick={() => table.setPageIndex(0)}
            size={'m'}
            disabled={!table.getCanPreviousPage()}
          />
          <Button
            maxWidth={'content'}
            typeBtn={'elevation'}
            elementType={'button'}
            showRightIcon
            rightIcon={<i className="icon icon-arrow-2-left icon-middle"></i>}
            onClick={() => table.previousPage()}
            size={'s'}
            onlyIcon
            disabled={!table.getCanPreviousPage()}
          />
          <Button
            maxWidth={'content'}
            typeBtn={'elevation'}
            elementType={'button'}
            showRightIcon
            onlyIcon
            rightIcon={<i className="icon icon-arrow-2-right icon-middle"></i>}
            onClick={() => table.nextPage()}
            size={'s'}
            disabled={!table.getCanNextPage()}
          />
          <Button
            maxWidth={'content'}
            typeBtn={'elevation'}
            elementType={'button'}
            text={'В конец'}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            size={'m'}
            disabled={!table.getCanNextPage()}
          />
        </ButtonGroup>
        <div>Строк : {table.getRowModel().rows.length}</div>
        <span className={''}>
          <div>Страница</div>
          <strong>
            {Number(table.getState().pagination.pageIndex) + 1} из{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span style={{
          backgroundColor: '#fafafa',
        }}>
          | К странице:
          <input
            type="number"
            defaultValue={Number(table.getState().pagination.pageIndex) + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className={''}
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Показывать {pageSize}
            </option>
          ))}
        </select>
      </div>}
      {/*<div style={{ marginTop: '20px' }}>*/}
      {/*  <ButtonGroup direction={'row'} gap={8} maxWidth={'fill'} withBackground={false} justifyContent={'start'}>*/}
      {/*    <Button*/}
      {/*      maxWidth={'content'}*/}
      {/*      typeBtn={'elevation'}*/}
      {/*      elementType={'button'}*/}
      {/*      text={'Обновить страницу'}*/}
      {/*      onClick={() => rerender()}*/}
      {/*      size={'l'}*/}
      {/*    />*/}
      
      {/*    <Button*/}
      {/*      maxWidth={'content'}*/}
      {/*      typeBtn={'elevation'}*/}
      {/*      elementType={'button'}*/}
      {/*      text={'Обновить данные'}*/}
      {/*      onClick={() => refreshData()}*/}
      {/*      size={'l'}*/}
      {/*    />*/}
      {/*  </ButtonGroup>*/}
      {/*</div>*/}
    </div>
  );
};
