import { MiniCarouselIconsProps } from './mini-carousel-icons.types';


export const IconCamera = ({ className }: MiniCarouselIconsProps)  =>{
  return (
    <svg className={className}  width="32" height="28" viewBox="0 0 32 28" stroke="none" fill="#FCFCFC">
      <path d="M24.6495 8.64347C25.5174 8.64347 26.221 9.34702 26.221 10.2149C26.221 11.0208 25.6143 11.685 24.8328 11.7758L24.6495 11.7863C23.7675 11.7863 23.064 11.0828 23.064 10.2149C23.064 9.40901 23.6706 8.74481 24.4521 8.65404L24.6495 8.64347Z" />
      <path d="M15.9994 9.74224C12.5891 9.74224 9.82528 12.506 9.82528 15.9164C9.82528 19.3267 12.5891 22.0905 15.9994 22.0905C19.4098 22.0905 22.1736 19.3267 22.1736 15.9164C22.1736 12.506 19.4098 9.74224 15.9994 9.74224ZM15.9994 12.0994C18.1079 12.0994 19.8164 13.8079 19.8164 15.9164C19.8164 18.0249 18.1079 19.7334 15.9994 19.7334C13.8909 19.7334 12.1824 18.0249 12.1824 15.9164C12.1824 13.8079 13.8909 12.0994 15.9994 12.0994Z" />
      <path d="M8.44736 2.879C9.079 1.73103 9.76613 0.963627 10.7857 0.557797C12.6542 -0.185143 19.346 -0.185143 21.2129 0.557945C22.232 0.963556 22.9194 1.73082 23.5515 2.87856L24.2621 4.24123C24.2784 4.26823 24.2933 4.29197 24.3067 4.3124L24.3671 4.39491C24.6909 4.7477 25.2327 4.96082 25.5904 4.96082C28.9721 4.96082 31.7142 7.70233 31.7142 11.0831V20.1895C31.7142 24.5019 28.2166 27.9995 23.9042 27.9995H8.09564C3.78224 27.9995 0.285645 24.5024 0.285645 20.1895V11.0831C0.285645 7.70233 3.02775 4.96082 6.4095 4.96082C6.76586 4.96082 7.30848 4.74739 7.63277 4.39491C7.63924 4.38785 7.6477 4.37734 7.65792 4.36342L7.73873 4.23947L8.44736 2.879ZM21.4868 4.01576C21.094 3.30258 20.739 2.90634 20.3412 2.74801L20.1537 2.68568C18.5365 2.22857 12.9111 2.24935 11.6571 2.74801C11.2595 2.90626 10.9049 3.30229 10.5125 4.01532L9.90775 5.18462L9.75889 5.45401C9.63463 5.66069 9.51034 5.83517 9.36842 5.9898C8.57255 6.85488 7.39518 7.31799 6.4095 7.31799L6.15164 7.32667C4.19182 7.45919 2.64279 9.0909 2.64279 11.0831V20.1896C2.64279 23.2007 5.08414 25.6424 8.09565 25.6424H23.9042C26.9147 25.6424 29.3571 23.2001 29.3571 20.1896V11.0831C29.3571 9.00428 27.6704 7.31799 25.5904 7.31799L25.3168 7.30655C24.3919 7.23045 23.3531 6.7761 22.6305 5.98876C22.4904 5.83617 22.3668 5.66294 22.2433 5.458C22.2256 5.4286 22.2035 5.38989 22.1782 5.3441L21.9958 5.00192L21.4868 4.01576Z"/>
    </svg>
  );
};

export const IconEdit = ({ className }: MiniCarouselIconsProps)  =>{
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32"  stroke="none" fill="#FCFCFC">
      <path d="M4.25093 5.27903C3.74458 5.27903 3.25896 5.48018 2.90091 5.83822C2.54287 6.19627 2.34172 6.68189 2.34172 7.18824V27.749C2.34172 28.2553 2.54287 28.741 2.90091 29.099C3.25896 29.4571 3.74458 29.6582 4.25093 29.6582H24.8117C25.318 29.6582 25.8036 29.4571 26.1617 29.099C26.5197 28.741 26.7209 28.2553 26.7209 27.749V19.9065C26.7209 19.3388 27.1812 18.8785 27.7489 18.8785C28.3167 18.8785 28.777 19.3388 28.777 19.9065V27.749C28.777 28.8006 28.3592 29.8092 27.6156 30.5529C26.8719 31.2965 25.8633 31.7143 24.8117 31.7143H4.25093C3.19927 31.7143 2.19069 31.2965 1.44705 30.5529C0.703415 29.8092 0.285645 28.8006 0.285645 27.749V7.18824C0.285645 6.13658 0.703414 5.128 1.44705 4.38436C2.19069 3.64072 3.19927 3.22296 4.25093 3.22296H12.0934C12.6612 3.22296 13.1214 3.68322 13.1214 4.25099C13.1214 4.81876 12.6612 5.27903 12.0934 5.27903H4.25093Z"/>
      <path d="M24.0847 0.586811C24.4862 0.185337 25.1371 0.185337 25.5386 0.586811L31.4131 6.46131C31.8146 6.86278 31.8146 7.5137 31.4131 7.91517L16.7269 22.6014C16.5341 22.7942 16.2726 22.9025 15.9999 22.9025H10.1254C9.55766 22.9025 9.09739 22.4423 9.09739 21.8745V16C9.09739 15.7273 9.2057 15.4659 9.3985 15.2731L24.0847 0.586811ZM11.1535 16.4258V20.8465H15.5741L29.2323 7.18824L24.8117 2.76761L11.1535 16.4258Z" />
    </svg>
  );
};