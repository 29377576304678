export const realStatus = [
  {
    id: 0,
    value: 'Проект реализован',
  },
  {
    id: 1,
    value: 'В процессе реализации',
  },
];
