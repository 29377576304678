import styles from './button-group-divider.module.scss';
import { ButtonGroupDividerProps } from './button-group-divider.types';
import cn from 'classnames';


export const ButtonGroupDivider = ({
  direction,
}: ButtonGroupDividerProps): JSX.Element => {
  const buttonGroupDividerClasses = cn(styles['button-group-divider'], {
    [styles['button-group-divider-col']]: direction === 'column',
    [styles['button-group-divider-row']]: direction === 'row',
  });

  return <div className={buttonGroupDividerClasses}></div>;
};
