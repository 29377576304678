import { FooterContainer } from '../common';
import { useAppDispatch, setMapLayer } from '../../state';
import styles from './footer.module.scss';
import { LayerSwitcher } from '../layers-switcher';
import { LayerType } from '../../types';
import { useViewModeSelector, useViewLayerState } from 'history-state';

export const Footer = () => {
  const dispatch = useAppDispatch();
  const [selectedLayer, setLayer] = useViewLayerState();
  const [viewMode] = useViewModeSelector();

  const selectLayerHandler = (value: LayerType) => {
    setLayer(value);
    dispatch(setMapLayer(value));
  };

  return (
    <FooterContainer>
      <div className={styles['footer-wrapper']}>
        <div className={styles['footer-wrapper-menu']}>
          {viewMode === 'map' && (
            <LayerSwitcher
              selectedLayer={selectedLayer}
              selectLayerHandler={selectLayerHandler}
            />
          )}
        </div>
      </div>
    </FooterContainer>
  );
};
