import { useCallback, useEffect, useRef, useState } from 'react';


export function useComponentVisible(
  initialIsVisible: () => boolean,
  callBack = (event: { target: Node | null }) => {},
  additionalIf = true,
) {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialIsVisible);
  const ref = useRef<any | null>(null);

  const handleClickOutside = useCallback(
    (event: { target: Node | null }) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        isComponentVisible &&
        additionalIf
      ) {
        setIsComponentVisible(false);
        if (callBack && typeof callBack === 'function') callBack(event);
      }
    },
    [additionalIf, callBack, isComponentVisible],
  ) as any;

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return [ref, isComponentVisible, setIsComponentVisible] as const;
}
