import React from 'react';
import { BottomSheet as ReactBottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import './bottom-sheet.scss';
import '../../../fonts/inter.css';
import { BottomSheetProps } from './bottom-sheet.types';


export const BottomSheet = ({ children, open, ...props }: BottomSheetProps) => (
  <ReactBottomSheet open={open} expandOnContentDrag={true} {...props}>
    {children}
  </ReactBottomSheet>
);
