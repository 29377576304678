/* eslint-disable */
import { ObjectInterface } from '../../../types';
import {
  program,
  macroRegion,
  TYPE_OF_WORK,
  nationalProjects,
  nonprofit,
  directions,
  realStatus,
} from '../../../constants';

const suffixes = new Map([
  ['1', 'балл'],
  ['2', 'балла'],
  ['3', 'баллов'],
]);

function getRightForm(num: number) {
  let n = Math.trunc(Math.abs(num)) % 100,
    n1 = n % 10;
  if (n > 4 && n < 21) return `${num} ${suffixes.get('3')}`;
  if (n1 === 1) return `${num} ${suffixes.get('1')}`;
  if (n1 > 1 && n1 < 5) return `${num} ${suffixes.get('2')}`;
  return `${num} ${suffixes.get('3')}`;
}

export const getContentValues = (
  object: ObjectInterface,
): Record<string, string | null> => {
  return {
    passability: object?.common_popularity
      ? `${object?.common_popularity} человек`
      : null,
    employeeVisits: object?.population ? `${object?.population} человек` : null,
    necessaryInvest: object?.money_needs ? `${object?.money_needs}` : null,
    distanceDistrictCenter: object?.distance ? `${object?.distance} км` : null,
    programValue:
      typeof object?.program_id === 'number' ? program[object.program_id] : null,
    macroRegionValue:
      typeof object?.macro_region === 'number' ? macroRegion[object.macro_region] : null,
    owner: object?.owner && object?.owner.length ? object.owner : null,
    typeOfWork: object?.type_of_work
      ? Object.values(TYPE_OF_WORK)[object.type_of_work]
      : TYPE_OF_WORK['0'],
    realizationPeriod: object?.realization_period ? object?.realization_period : null,
    rating: object?.rating ? `${getRightForm(Math.floor(Number(object?.rating)))}` : null,
    nationalProject:
      object && typeof object.national_project_id === 'number'
        ? nationalProjects[Number(object.national_project_id)].value
        : null,
    place:
      object && object.place && typeof object.place === 'string' ? object.place : null,
    name: object?.name,
    direction:
      object && typeof object.direction_id === 'number'
        ? [...nonprofit, ...directions][Number(object.direction_id)].value
        : null,
    realizationStatus:
      object && typeof object?.realization_status === 'number'
        ? realStatus[Number(object.realization_status)].value
        : null,
  };
};
