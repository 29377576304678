import { TileLayer } from 'react-leaflet'
import { MAP_PATH } from '../../../constants'
import { useRef } from 'react'
import L, { TileLayer as TileLayerType } from 'leaflet'

const TileLayerElement = TileLayer as any

export const MapTileLayer = () => {
  const mapLayerRef = useRef<TileLayerType | null>(null)

  return (
    <>
      <TileLayerElement
        ref={mapLayerRef}
        bounds={
          new L.LatLngBounds(
            new L.LatLng(-89.98, -180),
            new L.LatLng(89.98, 180),
          )
        }
        url={MAP_PATH}
      />
    </>
  )
}
