import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LineData, ProjectData } from '../../types/monitoring/monitoring-content-types';
import { monitoringApi } from '../api';


interface LinesState {
  projects: ProjectData[],
  project: ProjectData | null,
  projectLines: LineData[];
  selectedLineContent: Record<string, any> | null;
  isImagesFullscreen: boolean;
  isMapFullScreen: boolean;
  savedViewAngle: { yaw: number; pitch: number };
  viewPoint: number;
  lastViewDate: string;
  contentIsPending: boolean;
}

const initialState: LinesState = {
  projects: [],
  project: null,
  projectLines: [],
  selectedLineContent: null,
  isImagesFullscreen: false,
  isMapFullScreen: false,
  savedViewAngle: {
    yaw: 0,
    pitch: 0,
  },
  viewPoint: 1,
  lastViewDate: '',
  contentIsPending: false,
};

export const monitoringSlice = createSlice({
  name: 'lines',
  initialState: initialState,
  reducers: {
    toggleImageFullscreen: (state: LinesState, action) => {
      state.isImagesFullscreen = action.payload;
    },
    toggleMapFullscreen: (state: LinesState, action) => {
      state.isMapFullScreen = action.payload;
    },
    clearProject: (state: LinesState) => {
      state.project = null;
    },
    clearSelectedLine: (state: LinesState) => {
      state.savedViewAngle = {
        yaw: 0,
        pitch: 0,
      };
      state.viewPoint = 1;
      state.lastViewDate = '';
      state.selectedLineContent = null;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      monitoringApi.endpoints.getAllLinesByProject.matchFulfilled,
      (state: LinesState, action: PayloadAction<any>) => {
        state.projectLines = action.payload;
      });
    builder.addMatcher(
      monitoringApi.endpoints.getAllLinesByProject.matchPending,
      (state: LinesState, action: PayloadAction<any>) => {
        state.projectLines = [];
      });
    builder.addMatcher(
      monitoringApi.endpoints.getLineContent.matchPending,
      (state: LinesState) => {
        state.contentIsPending = true;
        state.selectedLineContent = null;
      },
    );
    builder.addMatcher(
      monitoringApi.endpoints.getLineContent.matchFulfilled,
      (state: LinesState, action: PayloadAction<any>) => {
        state.contentIsPending = false;
        state.selectedLineContent = action.payload;
      },
    );
    builder.addMatcher(
      monitoringApi.endpoints.getProject.matchPending,
      (state: LinesState) => {
        state.project = null;
      },
    );
    builder.addMatcher(
      monitoringApi.endpoints.getProject.matchFulfilled,
      (state: LinesState, { payload }: PayloadAction<any>) => {
        state.project = payload.payload;
      },
    );
  },
});
export const { toggleImageFullscreen, clearSelectedLine, toggleMapFullscreen, clearProject  } = monitoringSlice.actions;
export const { reducer: monitoringReducer } = monitoringSlice;