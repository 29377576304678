import { districts } from './districts';

export const getActiveDistricts = async (activeRegionsIdsList: number[]) => {
  const result = await Promise.all(
    districts.map(async (item: any) => {
      if (activeRegionsIdsList.some((regionId) => item.regions.includes(regionId))) {
        return {
          ...item,
          data: await import(`./${item.id}.json`).then((data) => {
            return data.default;
          }),
        };
      }
      return;
    }),
  );

  return result.filter((item: any) => !!item);
};
