import { FilterDataType } from '../../../types';
import { START_OF_BUILDING, TYPE_OF_WORK } from '../../../constants';

export const macroRegions: FilterDataType[] = [
  {
    id: 1,
    leftBorderColor: '$macro-region-pink',
    text: 'Витрина',
    show: true,
  },
  {
    id: 3,
    leftBorderColor: '$macro-region-blue',
    text: 'Северо-запад',
    show: true,
  },
  {
    id: 2,
    leftBorderColor: '$macro-region-orange',
    text: 'Юг-Черноземье',
    show: true,
  },
  {
    id: 4,
    leftBorderColor: '$macro-region-dark-green',
    text: 'Дальний Восток',
    show: true,
  },
  {
    id: 0,
    text: 'Другое',
    show: false,
  },
];

export const programs: FilterDataType[] = [
  {
    id: 0,
    text: 'Не установлено',
    show: false,
  },
  {
    id: 1,
    text: 'Объекты культурного наследия',
    show: true,
  },
  {
    id: 2,
    text: 'Объекты социальной значимости',
    show: true,
  },
];

export const realisationStatuses: FilterDataType[] = [
  {
    id: 0,
    text: 'Не установлено',
    show: false,
  },
  {
    id: 2,
    text: 'Реализованные',
    show: true,
  },
  {
    id: 1,
    text: 'В работе',
    show: true,
  },
];

export const startDate = [
  {
    id: 0,
    text: START_OF_BUILDING[0],
    show: true,
  },
  {
    id: 1,
    text: START_OF_BUILDING[1],
    show: true,
  },
  {
    id: 2,
    text: START_OF_BUILDING[2],
    show: true,
  },
  {
    id: 3,
    text: START_OF_BUILDING[3],
    show: true,
  },
];

export const typeOfWork = [
  {
    id: 0,
    text: TYPE_OF_WORK[0],
    show: true,
  },
  {
    id: 1,
    text: TYPE_OF_WORK[1],
    show: true,
  },
  {
    id: 2,
    text: TYPE_OF_WORK[2],
    show: true,
  },
  {
    id: 3,
    text: TYPE_OF_WORK[3],
    show: true,
  },
];
