export const gazpromFilters = [
  {
    value: 'Физкультурно-оздоровительные комплексы',
    id: 1,
  },
  {
    value: 'Мультифункциональные спортивные площадки',
    id: 2,
  },
  {
    value: 'Пришкольные стадионы',
    id: 3,
  },
];
