const getLastCharacter = (string: string) => {
  if (string.length === 1) return string
  return string.charAt(string.length - 1)
}

export const getRateTextValue = (rate: number | string) => {
  const lastCharacter = Number(getLastCharacter(String(rate)))

  if (lastCharacter === 0) return `${rate} баллов`
  if (lastCharacter === 1) return `${rate} балл`
  if (lastCharacter < 5) return `${rate} балла`
  return `${rate} баллов`
}
