import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


interface IdDateQuery {
  lineID: number,
  contentDate: string,
}

interface IdDateQueryWithType extends IdDateQuery {
  type: string,
}


export const monitoringAuthApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api-monitoring',
  }),
  
  tagTypes: ['Auth'],
  
  endpoints: (builder) => ({
    loginSphereUser: builder.mutation({
      query({ email = '', password = '' }: any) {
        return {
          url: '/api/login',
          method: 'POST',
          body: {
            email,
            password,
          },
          credentials: 'include',
        };
      },
      invalidatesTags: ['Auth'],
    }),
    
  }),
});

export const {
  useLoginSphereUserMutation,
} = monitoringAuthApi;


