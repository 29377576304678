import { SelectInputTypes } from './select-input.types';
import React, { FC } from 'react';
import styles from './select-input.module.scss';

 
export  const SelectInput: FC<SelectInputTypes> = ({ children, ...props }) => {
  return <div className={styles['select-wrapper']}>
    <select className={styles['select-input']} {...props}>{children}</select>
    <i
      className={`icon icon-arrow-2-down icon-low ${styles['select-arrow']} ${styles['select-arrow-down']}`}
    ></i>
  </div>;
};