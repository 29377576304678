import React, { useState } from 'react';
import { Button } from '../button';
import styles from './snack-bar.module.scss';
import { ICountDownProps, ISnackBarProps } from './snack-bar.types';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';


export const SnackBar = ({
  title = '',
  titleButton = '',
  showTimer = true,
  showButton = true,
  onOpen,
  onClose,
  onDelete,
  typeBtn = 'primary',
  duration = 5,
}: ISnackBarProps): JSX.Element => {

  const handleOnComplete = (e:number) => {
    onDelete ? onDelete(true) : '';
  };

  const countDown = ({ remainingTime }: ICountDownProps) => {
    if (remainingTime === 0) return '';
    return (
      <span className={styles['snack-bar-wrapper-timer-value']}>{remainingTime}</span>
    );
  };

  return (
    <>
      {onOpen && <div className={styles['snack-bar']}>
        <div className={styles['snack-bar-wrapper']}>
          {showTimer &&
          <div className={styles['snack-bar-wrapper-timer']}>
            <CountdownCircleTimer
              isPlaying size={26}
              trailColor={'#00000000'}
              strokeWidth={2}
              duration={duration}
              colors={'#9747FF'}
              onComplete={(e) => handleOnComplete(e)}>
              {countDown}
            </CountdownCircleTimer>
          </div>}
          <div className={styles['snack-bar-wrapper-title']}>{title}</div>
          {showButton && <Button
            elementType={'button'}
            className={styles['snack-bar-wrapper-button']}
            typeBtn={typeBtn}
            children={titleButton}
            size={'s'}
            onClick={() => onClose ? onClose(false) : ''}
          />}
        </div>
      </div>}
    </>
  );
};