import type { UserType } from '../../../types';


export const userInitialState: UserInitialStateType = {
  token: {
    accessToken: null,
    refreshToken: null,
  },
  role: 'redactor',
  isEditorMode: false,
  favoriteItems: [],
  isClusterMarkerViewSelected: true,
  profile: null,
  blockingProfilePanel: false,
  viewMode: 'map',
};
  
interface UserInitialStateType {
  token: Token,
  role: 'user' | 'redactor'
  isEditorMode: boolean,
  favoriteItems: number[],
  isClusterMarkerViewSelected: boolean,
  profile: UserType | null,
  blockingProfilePanel: boolean,
  viewMode: 'map' | 'list' | 'table'
}

interface Token {
  accessToken: string | null,
  refreshToken: string | null,
}