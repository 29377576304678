import { useAppDispatch } from '../../state';
import { useCallback } from 'react';
import { LINK_MAP_OBJECT_PATH, LINK_MAP_PATH } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { history } from '../../utils/history';
import { useSelectedObjectSelector } from '../../history-state/selectors';


export const useSelectObject = () => {
  const dispatch = useAppDispatch();
  const selectedObjectId = useSelectedObjectSelector();
  const navigate = useNavigate();
  
  const setObjectId = useCallback((value: number | null): void | null => {
    const queryParams = new URLSearchParams(history.location.search.replace('?', ''));
    if (value) {
      queryParams.set('isSidebarOpen', '1');
      
      navigate({ pathname: LINK_MAP_OBJECT_PATH(value), search: queryParams.toString() });
    } else {
      navigate({ pathname: LINK_MAP_PATH, search: queryParams.toString() });
    } 
  }, [dispatch]);
  
  return [selectedObjectId, setObjectId] as const;
};