import { IEditBarProps } from './edit-bar.types';
import styles from './edit-bar.module.scss';
import React from 'react';
import { ButtonGroup } from '../button-group';
import { ButtonGroupDivider } from '../button-group-divider';
import { IconProgress } from '../../sidebar-filter/progress-icon';


export const EditBar = ({
  nameButtonSave,
  onSave,
  onToggleBold,
  isBoldActive = false,
  onToggleItalic,
  isItalicActive,
  onToggleUnderline,
  isUnderlineActive,
  onToggleLineThrough,
  isLineThroughActive,
  onUndo,
  onRedo,
  isLoading = false,
}: IEditBarProps): JSX.Element => {
  
  return (
    
    <ButtonGroup direction={'row'} className="custom-toolbar toolbar ql-toolbar ql-snow">
      <button
        className={`${styles.button} ${styles['button-uncolored']} ${isBoldActive ? styles.active : '' }`}
        onClick={onToggleBold}
      >B</button>
      <button
        className={`${styles.button} ${styles['button-uncolored']} ${isItalicActive ? styles.active : '' }`}
        style={{ fontStyle: 'italic' }}
        onClick={onToggleItalic}
      >i</button>
      <button
        className={`${styles.button} ${styles['button-uncolored']} ${isUnderlineActive ? styles.active : '' }`}
        style={{ textDecoration: 'underline' }}
        onClick={onToggleUnderline}
      >U</button>
      <button
        className={`${styles.button} ${styles['button-uncolored']} ${isLineThroughActive ? styles.active : '' }`}
        style={{ textDecoration: 'line-through' }}
        onClick={onToggleLineThrough}
      >S</button>
      {/*<Button*/}
      {/*  elementType={'button'}*/}
      {/*  typeBtn={'uncolored'}*/}
      {/*  text={'A'}*/}
      {/*  rightIcon={<i className={'icon icon-arrow-2-down'}/>}*/}
      {/*/>*/}
      <ButtonGroupDivider
        direction={'column'}
      />
      <button
        onClick={onUndo}
        // disabled={!cancel}
        className={`${styles.button} ${styles['button-uncolored']}`}
      ><i className={'icon icon-arrow-3-left'}/></button>
      <button
        onClick={onRedo}
        // disabled={!repeat}
        className={`${styles.button} ${styles['button-uncolored']}`}
      ><i className={'icon icon-arrow-3-right'}/></button>
      <ButtonGroupDivider
        direction={'column'}
      />
      <button
        className={`${styles.button} ${styles['button-uncolored']}`}
        onClick={onSave}
        disabled={isLoading}
      >{isLoading && <IconProgress stroke={'#000'}/>}{nameButtonSave}</button>
    </ButtonGroup>
  );
};