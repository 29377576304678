import styles from '../../mini-carousel/mini-carousel.module.scss';
import { Button } from '../button';
import React from 'react';
import { MiniCarouselControlsProps } from './mini-carousel-controls.types';


export const MiniCarouselControls = ({
  prevImage,
  isPreviousButtonDisabled,
  nextImage,
  isNextButtonDisabled,
  photoList = [] } : MiniCarouselControlsProps) => {
  
  return (
    <div className={styles['mini-carousel-container-scroll-footer']}>
      <div className={styles['mini-carousel-container-scroll-footer-controls']}>
        <Button
          elementType="button"
          typeBtn="uncolored"
          type="submit"
          showLeftIcon={true}
          className={
            styles['mini-carousel-container-scroll-footer-controls-align-start']
          }
          size="m"
          leftIcon={<i className="icon icon-arrow-1-left icon-middle"></i>}
          onClick={prevImage}
          disabled={isPreviousButtonDisabled}
          withBorder={false}
        >
        Назад
        </Button>
        <div className={styles['mini-carousel-container-scroll-footer-quantity']}>
          <i className="icon icon-camera icon-low"></i>
          <span>{photoList?.length}</span>
        </div>
        <Button
          elementType="button"
          typeBtn="uncolored"
          type="submit"
          size="m"
          className={styles['mini-carousel-container-scroll-footer-controls-align-end']}
          onClick={nextImage}
          showRightIcon={true}
          rightIcon={<i className="icon icon-arrow-1-right icon-middle"></i>}
          disabled={isNextButtonDisabled}
          withBorder={false}
        >
        Вперед
        </Button>
      </div>
    </div>
  );
};