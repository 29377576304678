import parkIcon from 'icons/prototype/park.svg';

import medicalIcon from 'icons/prototype/medical-institution.svg';

import worship from 'icons/prototype/religious-object.svg';
import worshipSelectIcon from 'icons/prototype/religious-object.svg';
import worshipActiveIcon from 'icons/prototype/religious-object.svg';

import linearIcon from 'icons/prototype/linear-object.svg';

import sportIcon from 'icons/prototype/sports-institution.svg';
import sportSelectIcon from 'icons/prototype/sports-institution.svg';
import sportActiveIcon from 'icons/prototype/sports-institution.svg';

import schoolIcon from 'icons/prototype/educational-institution.svg';
import schoolSelectIcon from 'icons/prototype/educational-institution.svg';
import schoolActiveIcon from 'icons/prototype/educational-institution.svg';

import medicineIcon from 'icons/prototype/medical-institution.svg';
import medicineSelectIcon from 'icons/prototype/medical-institution.svg';
import medicineActiveIcon from 'icons/prototype/medical-institution.svg';

import leisureIcon from 'icons/prototype/residential-complex.svg';
import leisureActiveIcon from 'icons/prototype/residential-complex.svg';
import leisureIconSelect from 'icons/prototype/residential-complex.svg';
import apartmentIcon from 'icons/prototype/apartment-building.svg';

export const selectMarkerIconHandler = (type: number) => {
  switch (type) {
    case 0: {
      return medicalIcon;
    }
    case 1: {
      return apartmentIcon;
    }
    case 2: {
      return schoolSelectIcon;
    }
    case 3: {
      return apartmentIcon;
    }
    case 4: {
      return schoolSelectIcon;
    }
    case 5: {
      return leisureActiveIcon;
    }
    case 6: {
      return worshipActiveIcon;
    }
    case 7: {
      return apartmentIcon;
    }
    default: {
      return parkIcon;
    }
  }
};
