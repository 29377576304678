
export const pad = (s: number) => {
  return (s < 10) ? '0' + String(s) : String(s);
};

export const convertDateToYYYYMMDD = (inputFormat: string | Date) => {
  const d = new Date(inputFormat);

  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
};
export const convertDateToDDMMYYYY = (inputFormat: string) => {
  const d = new Date(inputFormat);

  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()  ].join('.');
};

export const convertDateToddMM = (inputFormat: string) => {
  const d = new Date(inputFormat);
  
  const strM = [
    'янв', 'фев',
    'мар', 'апр',
    'май', 'июн',
    'июл', 'авг',
    'сен', 'окт',
    'ноя', 'дек',
  ];
  
  return [
    pad(d.getDate()),
    strM[d.getMonth()],
  ];
};
