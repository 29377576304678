export enum Breakpoints {
  DUL = 2048, // desktop ultra large
  DL = 1921, // desktop large
  DM = 1600, // desktop middle
  DS = 1367, // desktop small
  TL = 1200, // tablet landscape
  TP = 960, // tablet portrait
  ML = 640, // mobile landscape
  MP = 500, // mobile portrait
}

export enum ScreenSize {
  DUL = 'DUL',
  DL = 'DL',
  DM = 'DM',
  DS = 'DS',
  TL = 'TL',
  TP = 'TP', 
  ML = 'ML',
  MP = 'MP',
}
