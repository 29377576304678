export const createRowsAndCols = (val: string[][], editable: boolean) => {
  const createRows = (arr: string[]) =>
    arr.reduce((acc, curr, idx) => ({ ...acc, [`${idx}`]: curr }), {});

  const cols = val[0]; // массив заголовков колонок

  // const additional = [
  //   ['', '', '', '', '', '', ''],
  //   ['', '', '', '', '', '', ''],
  //   ['', '', '', '', '', '', ''],
  //   ['', '', '', '', '', '', ''],
  //   ['', '', '', '', '', '', ''],
  //   ['', '', '', '', '', '', ''],
  //   ['', '', '', '', '', '', ''],
  //   ['', '', '', '', '', '', ''],
  //   ['', '', '', '', '', '', ''],
  //   ['', '', '', '', '', '', ''],
  // ];
  //
  // const rows1 = val
  //   .slice(1)
  //   .concat(additional)
  //   .map((arr) => createRows(arr));

  const rows2 = val.slice(1).map((arr) => createRows(arr)); // массив объектов, где ключ объекта совпадает с индексом названия колоноки

  return { cols, rows: rows2 };
};
