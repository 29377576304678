import { Paths } from '../constants';


export const shareObject = (id: number) => {
  if (window && id) {
    const { location } = window;
    navigator.clipboard.writeText(
      `${location.origin}${Paths.MAIN_PAGE}${Paths.OBJECT}/${id}`,
    );
  } else {
    
  }
};