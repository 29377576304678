import { createClusterCustomIcon } from '../../../utils';
import type { ClusterContentProps } from './content.types';
import { Content } from './content';

export const ClusterContent = ({ items, types }: ClusterContentProps) => {
  return (
    <Content
      iconCreateFunction={createClusterCustomIcon}
      isGazpromChild={false}
      items={items}
      types={types}
    />
  );
};
