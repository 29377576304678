import { useRef } from 'react';
import './content.style.scss';
import type { MarkerClusterGroup as MarkerClusterGroupType } from 'leaflet';
import { MarkerClusterGroup } from '../../../utils';
import { MarkerContent } from './marker-content';
import type { ContentProps } from './content.types';

const MarkerClusterGroupElement = MarkerClusterGroup as any;

export const Content = ({
  items,
  types,
  iconCreateFunction,
  isGazpromChild,
}: ContentProps) => {
  const clusterGroupRef = useRef<MarkerClusterGroupType | null>(null);

  if (!items.length) return <></>;

  return (
    <MarkerClusterGroupElement
      ref={clusterGroupRef}
      maxClusterRadius={80}
      iconCreateFunction={iconCreateFunction}
      showCoverageOnHover={false}
    >
      <MarkerContent items={items} types={types} isGazpromChild={isGazpromChild} />
    </MarkerClusterGroupElement>
  );
};
