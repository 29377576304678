export const regions = [
  {
    data: null,
    name: 'Ярославская область',
    id: 78,
  },
  {
    data: null,
    name: 'Ямало-Ненецкий АО',
    id: 77,
  },
  {
    data: null,
    name: 'Воронежская область',
    id: 76,
  },
  {
    data: null,
    name: 'Брянская область',
    id: 9,
  },
  {
    data: null,
    name: 'Волгоградская область',
    id: 75,
  },
  {
    data: null,
    name: 'Вологодская область',
    id: 74,
  },
  {
    data: null,
    name: 'Владимирская область',
    id: 73,
  },
  {
    data: null,
    name: 'Ульяновская область',
    id: 72,
  },
  {
    data: null,
    name: 'Удмуртская Республика',
    id: 71,
  },
  {
    data: null,
    name: 'Тюменская область',
    id: 70,
  },
  {
    data: null,
    name: 'Тыва',
    id: 69,
  },
  {
    data: null,
    name: 'Тверская область',
    id: 68,
  },
  {
    data: null,
    name: 'Тульская область',
    id: 67,
  },
  {
    data: null,
    name: 'Томская область',
    id: 66,
  },
  {
    data: null,
    name: 'Республика Татарстан (Татарстан)',
    id: 65,
  },
  {
    data: null,
    name: 'Тамбовская область',
    id: 64,
  },
  {
    data: null,
    name: 'Свердловская область',
    id: 63,
  },
  {
    data: null,
    name: 'Ставропольский край',
    id: 62,
  },
  {
    data: null,
    name: 'Смоленская область',
    id: 61,
  },
  {
    data: null,
    name: 'Город федерального значения Севастополь',
    id: 60,
  },
  {
    data: null,
    name: 'Самарская область',
    id: 58,
  },
  {
    data: null,
    name: 'Сахалинская область',
    id: 56,
    coords: [50.58095542256008, 141.49011230468753],
  },
  {
    data: null,
    name: 'Республика Саха (Якутия)',
    id: 57,
  },
  {
    data: null,
    name: 'Республика Северная Осетия - Алания',
    id: 55,
  },
  {
    data: null,
    name: 'Ростовская область',
    id: 54,
  },
  {
    data: null,
    name: 'Рязанская область',
    id: 53,
  },
  {
    data: null,
    name: 'Псковская область',
    id: 52,
  },
  {
    data: null,
    name: 'Приморский край',
    id: 51,
  },
  {
    data: null,
    name: 'г. Санкт-Петербург',
    id: 50,
  },
  {
    data: null,
    name: 'Пермский край',
    id: 49,
  },
  {
    data: null,
    name: 'Пензенская область',
    id: 48,
  },
  {
    data: null,
    name: 'Орловская область',
    id: 47,
  },
  {
    data: null,
    name: 'Оренбургская область',
    id: 46,
  },
  {
    data: null,
    name: 'Омская область',
    id: 45,
  },
  {
    data: null,
    name: 'Нижегородская область',
    id: 44,
  },
  {
    data: null,
    name: 'Новосибирская область',
    id: 43,
  },
  {
    data: null,
    name: 'Новгородская область',
    id: 42,
  },
  {
    data: null,
    name: 'Ненецкий АО',
    id: 41,
  },
  {
    data: null,
    name: 'Мурманская область',
    id: 40,
  },
  {
    data: null,
    name: 'г. Москва',
    id: 39,
  },
  {
    data: null,
    name: 'Московская область',
    id: 38,
  },
  {
    data: null,
    name: 'Республика Мордовия',
    id: 37,
  },
  {
    data: null,
    name: 'Республика Марий Эл',
    id: 36,
  },
  {
    data: null,
    name: 'Магаданская область',
    id: 35,
  },
  {
    data: null,
    name: 'Липецкая область',
    id: 34,
  },
  {
    data: null,
    name: 'Ленинградская область',
    id: 33,
  },
  {
    data: null,
    name: 'Курская область',
    id: 32,
  },
  {
    data: null,
    name: 'Республика Крым',
    id: 79,
  },
  {
    data: null,
    name: 'Красноярский край',
    id: 85,
  },
  {
    data: null,
    name: 'Краснодарский край',
    id: 84,
  },
  {
    data: null,
    name: 'Костромская область',
    id: 83,
  },
  {
    data: null,
    name: 'Республика Коми',
    id: 82,
  },
  {
    data: null,
    name: 'Кировская область',
    id: 81,
  },
  {
    data: null,
    name: 'Курганская область',
    id: 31,
  },
  {
    data: null,
    name: 'Кемеровская область - Кузбасс',
    id: 30,
  },
  {
    data: null,
    name: 'Республика Карелия',
    id: 29,
  },
  {
    data: null,
    name: 'Карачаево-Черкесская Республика',
    id: 28,
  },
  {
    data: null,
    name: 'Калужская область',
    id: 27,
  },
  {
    data: null,
    name: 'Республика Калмыкия',
    id: 26,
  },
  {
    data: null,
    name: 'Калининградская область',
    id: 25,
  },
  {
    data: null,
    name: 'Кабардино-Балкарская Республика',
    id: 24,
  },
  {
    data: null,
    name: 'Ивановская область',
    id: 23,
  },
  {
    data: null,
    name: 'Иркутская область',
    id: 22,
  },
  {
    data: null,
    name: 'Республика Ингушетия',
    id: 21,
  },
  {
    data: null,
    name: 'Хабаровский край',
    id: 20,
  },
  {
    data: null,
    name: 'Ханты-Мансийский АО - Югра',
    id: 19,
  },
  {
    data: null,
    name: 'Республика Хакасия',
    id: 18,
  },
  {
    data: null,
    name: 'Еврейская АО',
    id: 17,
  },
  {
    data: null,
    name: 'Республика Дагестан',
    id: 16,
  },
  {
    data: null,
    name: 'Чукотский АО',
    id: 15,
  },
  {
    data: null,
    name: 'Чувашская Республика - Чувашия',
    id: 14,
  },
  {
    data: null,
    name: 'Челябинская область',
    id: 13,
  },
  {
    data: null,
    name: 'Чеченская Республика',
    id: 12,
  },
  {
    data: null,
    name: 'Камчатский край',
    id: 11,
  },
  {
    data: null,
    name: 'Республика Бурятия',
    id: 10,
  },
  {
    data: null,
    name: 'Республика Башкортостан',
    id: 80,
  },
  {
    data: null,
    name: 'Забайкальский край',
    id: 7,
  },
  {
    data: null,
    name: 'Астраханская область',
    id: 6,
  },
  {
    data: null,
    name: 'Белгородская область',
    id: 8,
  },
  {
    data: null,
    name: 'Архангельская область',
    id: 5,
  },
  {
    data: null,
    name: 'Амурская область',
    id: 4,
  },
  {
    data: null,
    name: 'Алтайский край',
    id: 3,
  },
  {
    data: null,
    name: 'Республика Алтай',
    id: 2,
  },
  {
    data: null,
    name: 'Республика Адыгея (Адыгея)',
    id: 1,
  },
  {
    data: null,
    name: 'Саратовская область',
    id: 59,
  },
];
