import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import type { RootState, TokenType } from '../../types';
import { logout, login } from '../';
import { userApi } from './user-api';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { formatObjectToQueryString } from '../../utils/format-object-to-query-string';
import { ThunkResult } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { baseQuery } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_API_EXT_PATH as string;

const baseMonitoringQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set('X-Requested-With', 'XMLHttpRequest');

    const token = (getState() as RootState).user.token.accessToken;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const mutex = new Mutex();

const authCheckQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseMonitoringQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            method: 'POST',
            credentials: 'include',
            url: '/refresh-token',
            body: {
              refresh_token: (api.getState() as RootState).user.token.refreshToken,
            },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          api.dispatch(login(refreshResult.data as TokenType));
          api.dispatch(userApi.endpoints.getUserInfo.initiate());

          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseMonitoringQuery(args, api, extraOptions);
    }
  }

  return result;
};

// const BASE_URL = process.env.REACT_APP_BACKEND_API2_PATH as string;

interface IdDateQuery {
  lineID: number;
  contentDate: string;
}

interface IdDateQueryWithType extends IdDateQuery {
  type: string;
}

export const monitoringApi = createApi({
  baseQuery: authCheckQuery,

  tagTypes: ['Companies'],

  endpoints: (builder) => ({
    getCompanies: builder.mutation({
      query() {
        return {
          url: '/user/api/companies',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getProject: builder.mutation({
      query(id: number) {
        return {
          url: `/user/api/projects/${id}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getContentTags: builder.mutation<IdDateQuery, any>({
      query({ lineID, contentDate }: any): any {
        return {
          url: `/user/api/lines/${lineID as number}/?date=${contentDate as string}`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getPanorama: builder.mutation<IdDateQuery, any>({
      query({ lineID, contentDate }: any): any {
        return {
          url: `/user/api/lines/${lineID as number}/content/panorama?dateFrom=${
            contentDate as string
          }`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getLineScheme: builder.query<any, any>({
      query({ lineID, contentType, contentDate }: any): any {
        return {
          url: `/user${encodeURIComponent(
            `/api/lineScheme/?${formatObjectToQueryString({
              lineId: lineID,
              contentType,
              date: contentDate,
            })}`,
          )}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getCalendarByType: builder.query<IdDateQuery, any>({
      query({ lineID, contentType }: { lineID: number; contentType: string }): any {
        return {
          url: `/user/api/lines/${lineID}/content/${contentType.toLowerCase()}/calendarByType`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getLineContentTabs: builder.query<IdDateQuery, any>({
      query({ lineID, contentDate }: any): any {
        const contentDateParam = contentDate ? `date=${contentDate as number}` : '';

        return {
          url: `/user/api/lines/${lineID as number}/contentsTabs?${contentDateParam}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getAllLinesByProject: builder.mutation<IdDateQueryWithType, ThunkResult>({
      query({ projectID }: any): any {
        return {
          url: `/user/api/projects/${projectID as number}/lines/`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getLineContentByType: builder.mutation<IdDateQueryWithType, ThunkResult>({
      query({ lineID, type, contentDate }: IdDateQueryWithType) {
        const contentDateParam = contentDate ? `date=${contentDate}` : '';

        return {
          url: `/user/api/lines/${lineID}/content/${type.toLowerCase()}?${contentDateParam}`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getLineContent: builder.mutation<IdDateQuery, ThunkResult>({
      query({ lineID, contentDate }: IdDateQuery) {
        const contentDateParam = contentDate ? `date=${contentDate}` : '';

        return {
          url: `/user/api/lines/${lineID}/content/lineContents?${contentDateParam}`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
  }),
});

export const {
  useGetCompaniesMutation,
  useGetLineSchemeQuery,
  useGetPanoramaMutation,
  useGetLineContentMutation,
  useGetLineContentTabsQuery,
  useGetLineContentByTypeMutation,
  useGetCalendarByTypeQuery,
  useGetProjectMutation,
} = monitoringApi;

// https://media-dev.interactivemap.ru/user/api/lineScheme/?lineId=1&contentType=PANORAMA&date=2022-08-29&
// https://media-dev.interactivemap.ru/user/api/lines/1/content/lineContents?date=2022-08-29
