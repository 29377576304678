import React, { memo, useLayoutEffect, useState } from 'react';
import { SidebarFilterProps } from './sidebar-filter.types';
import styles from './sidebar-filter.module.scss';
import { GazpromFilterButton } from 'components';
import { Button, FilterCheckbox, FilterGroup, H3Tag } from '../common';
import { FilterBottomPanel } from './filter-bottom-panel';
import { macroRegions, programs, realisationStatuses, typeOfWork } from './data';
import { MultiRangeSlider } from '../multi-range-slider';
import type { IDegreeOfSatisfaction } from '../../types';
import {
  RangeValues,
  nationalProjects,
  directions,
  realStatus,
  nonprofit,
  gazpromFilters,
} from '../../constants';
import { OptionsList } from '../options-list';

const setFunction = (value: number, arr: number[]): number[] => {
  if (arr.includes(value)) {
    const newState = [...arr];
    newState.splice(
      arr.findIndex((el: number) => el === value),
      1,
    );
    return [...newState];
  } else {
    return [...arr, value];
  }
};

export const SidebarFilter = memo(
  ({
    editorMode = false,
    onFilter,
    externalMacroRegions = [],
    externalProgram = [],
    externalStartOfBuilding = [],
    externalTypeOfWork = [],
    externalRealisationStatus = [],
    externalNationalProjects = [],
    externalDirections = [],
    externalDegreeOfSatisfaction = {
      min: RangeValues.MIN,
      max: RangeValues.MAX,
    },
    loading = false,
    resetFiltersHandler,
    externalShowOnlyFavorite = false,
    externalGazpromFilters = [],
  }: SidebarFilterProps) => {
    // const [objectsCounter, setObjectsCounter] = useState<number | null>(null);

    const [selectedMacroRegions, setSelectedMacroRegions] = useState<number[]>(
      () => externalMacroRegions,
    );
    const [selectedProgram, setSelectedProgram] = useState<number[]>(
      () => externalProgram,
    );
    const [selectedStartOfBuilding, setStartOfBuilding] = useState<number[]>(
      () => externalStartOfBuilding,
    );
    const [selectedTypeOfWork, setTypeOfWork] = useState<number[]>(
      () => externalTypeOfWork,
    );
    const [selectedRealisationStatus, setSelectedRealisationStatus] = useState<number[]>(
      () => externalRealisationStatus,
    );
    const [showOnlyFavorite, setShowOnlyFavorite] = useState<boolean>(
      () => externalShowOnlyFavorite,
    );

    const [selectedNationalProjects, setNationalProjects] = useState<number[]>(
      () => externalNationalProjects,
    );
    const [selectedDirections, setSelectedDirections] = useState<number[]>(
      () => externalDirections,
    );
    const [selectedGazpromFilters, setGazpromFilters] = useState<number[]>(
      () => externalGazpromFilters,
    );

    const [selectedDegreeOfSatisfaction, setSelectedDegreeOfSatisfaction] =
      useState<IDegreeOfSatisfaction>(() => externalDegreeOfSatisfaction);

    const handleSelectNationalProjects = (project: number) => {
      const newNationalProject = setFunction(project, selectedNationalProjects);
      setSelectedMacroRegions(newNationalProject);
      onFilter(
        selectedMacroRegions,
        selectedProgram,
        selectedStartOfBuilding,
        selectedTypeOfWork,
        selectedRealisationStatus,
        showOnlyFavorite,
        newNationalProject,
        selectedDirections,
        selectedDegreeOfSatisfaction,
        selectedGazpromFilters,
      );
    };

    const handleSelectDirections = (type: number) => {
      const newDirection = setFunction(type, selectedDirections);
      setSelectedDirections(newDirection);
      onFilter(
        selectedMacroRegions,
        selectedProgram,
        selectedStartOfBuilding,
        selectedTypeOfWork,
        selectedRealisationStatus,
        showOnlyFavorite,
        selectedNationalProjects,
        newDirection,
        selectedDegreeOfSatisfaction,
        selectedGazpromFilters,
      );
    };

    const handleDegreeOfSatisfaction = (range: IDegreeOfSatisfaction) => {
      setSelectedDegreeOfSatisfaction(range);
      onFilter(
        selectedMacroRegions,
        selectedProgram,
        selectedStartOfBuilding,
        selectedTypeOfWork,
        selectedRealisationStatus,
        showOnlyFavorite,
        selectedNationalProjects,
        selectedDirections,
        range,
        selectedGazpromFilters,
      );
    };

    const handleSelectRealisation = (status: number) => {
      const newStatus = setFunction(status, selectedRealisationStatus);
      setSelectedRealisationStatus(newStatus);
      onFilter(
        selectedMacroRegions,
        selectedProgram,
        selectedStartOfBuilding,
        selectedTypeOfWork,
        newStatus,
        showOnlyFavorite,
        selectedNationalProjects,
        selectedDirections,
        selectedDegreeOfSatisfaction,
        selectedGazpromFilters,
      );
    };

    const handleSelectGazpromFilters = (filter: number) => {
      const newStatus = setFunction(filter, selectedGazpromFilters);
      setGazpromFilters(newStatus);
      onFilter(
        selectedMacroRegions,
        selectedProgram,
        selectedStartOfBuilding,
        selectedTypeOfWork,
        selectedRealisationStatus,
        showOnlyFavorite,
        selectedNationalProjects,
        selectedDirections,
        selectedDegreeOfSatisfaction,
        newStatus,
      );
    };

    const removeAllNationalProjects = () => {
      setSelectedMacroRegions([]);
      onFilter(
        selectedMacroRegions,
        selectedProgram,
        selectedStartOfBuilding,
        selectedTypeOfWork,
        selectedRealisationStatus,
        showOnlyFavorite,
        [],
        selectedDirections,
        selectedDegreeOfSatisfaction,
        selectedGazpromFilters,
      );
    };

    const removeAllSelectDirections = () => {
      setSelectedDirections([]);
      onFilter(
        selectedMacroRegions,
        selectedProgram,
        selectedStartOfBuilding,
        selectedTypeOfWork,
        selectedRealisationStatus,
        showOnlyFavorite,
        selectedNationalProjects,
        [],
        selectedDegreeOfSatisfaction,
        selectedGazpromFilters,
      );
    };

    const removeAllDegreeOfSatisfaction = () => {
      setSelectedDegreeOfSatisfaction({ min: 0, max: 10 });
      onFilter(
        selectedMacroRegions,
        selectedProgram,
        selectedStartOfBuilding,
        selectedTypeOfWork,
        selectedRealisationStatus,
        showOnlyFavorite,
        selectedNationalProjects,
        selectedDirections,
        { min: 0, max: 10 },
        selectedGazpromFilters,
      );
    };

    const removeAllRealisationStatus = () => {
      setSelectedRealisationStatus([]);
      onFilter(
        selectedMacroRegions,
        selectedProgram,
        selectedStartOfBuilding,
        selectedTypeOfWork,
        [],
        showOnlyFavorite,
        selectedNationalProjects,
        selectedDirections,
        selectedDegreeOfSatisfaction,
        selectedGazpromFilters,
      );
    };

    useLayoutEffect(() => {
      setSelectedProgram(externalProgram);
      setSelectedMacroRegions(externalMacroRegions);
      setSelectedRealisationStatus(externalRealisationStatus);
      setSelectedDirections(externalDirections);
      setNationalProjects(externalNationalProjects);
      setSelectedDegreeOfSatisfaction(externalDegreeOfSatisfaction);
      setGazpromFilters(externalGazpromFilters);
    }, [
      externalProgram,
      externalMacroRegions,
      externalRealisationStatus,
      externalDegreeOfSatisfaction,
      externalGazpromFilters,
    ]);

    const resetAllFilters = () => {
      setSelectedProgram([]);
      setSelectedMacroRegions([]);
      setSelectedRealisationStatus([]);
      setStartOfBuilding([]);
      setTypeOfWork([]);
      setShowOnlyFavorite(false);
      setSelectedDirections([]);
      setNationalProjects([]);
      setSelectedDegreeOfSatisfaction({
        min: RangeValues.MIN,
        max: RangeValues.MAX,
      });
      setGazpromFilters([]);
      resetFiltersHandler();
    };

    const showedRealisationStatuses = realisationStatuses.filter((el) => el.show);

    return (
      <>
        <div className={styles['content-wrapper']}>
          <div className={styles.filters}>
            <div className={styles.body}>
              <div className={styles['body-content-wrapper']}>
                <GazpromFilterButton
                  activeFiltersList={selectedGazpromFilters}
                  options={gazpromFilters}
                  isActive={!!selectedGazpromFilters.length}
                  active={null}
                  onChange={handleSelectGazpromFilters}
                />
              </div>
              <div className={styles['body-content-wrapper']}>
                <div className={styles.title}>
                  <H3Tag weight={'3'}>Степень удовлетворённости</H3Tag>
                  {selectedDegreeOfSatisfaction.min !== RangeValues.MIN ||
                  selectedDegreeOfSatisfaction.max !== RangeValues.MAX ? (
                    <Button
                      onClick={removeAllDegreeOfSatisfaction}
                      className={styles['btn-clear']}
                      elementType={'button'}
                      size="s"
                      typeBtn={'uncolored'}
                      text={'Очистить'}
                      justifyContent={'end'}
                    />
                  ) : null}
                </div>
                <MultiRangeSlider
                  min={RangeValues.MIN}
                  max={RangeValues.MAX}
                  minVal={selectedDegreeOfSatisfaction.min}
                  maxVal={selectedDegreeOfSatisfaction.max}
                  onChange={handleDegreeOfSatisfaction}
                />
              </div>
              <div className={styles['body-content-wrapper']}>
                <div className={styles.title}>
                  <H3Tag weight={'3'}>Национальный проект</H3Tag>
                  {selectedNationalProjects.length ? (
                    <Button
                      onClick={removeAllNationalProjects}
                      className={styles['btn-clear']}
                      elementType={'button'}
                      size="s"
                      typeBtn={'uncolored'}
                      text={'Очистить'}
                      justifyContent={'end'}
                    />
                  ) : null}
                </div>
                <FilterGroup style={{ padding: 0, width: '100%' }}>
                  {nationalProjects.map(({ value, id }, i) => (
                    <FilterCheckbox
                      handleChange={() => handleSelectNationalProjects(id)}
                      key={i}
                      isChecked={selectedNationalProjects.includes(id)}
                    >
                      {value}
                    </FilterCheckbox>
                  ))}
                </FilterGroup>
              </div>
              <div className={styles['body-content-wrapper']}>
                <div className={styles.title}>
                  <H3Tag weight={'3'}>Направление</H3Tag>
                  {selectedDirections.length ? (
                    <Button
                      onClick={removeAllSelectDirections}
                      className={styles['btn-clear']}
                      elementType={'button'}
                      size="s"
                      typeBtn={'uncolored'}
                      text={'Очистить'}
                      justifyContent={'end'}
                    />
                  ) : null}
                </div>
                <OptionsList
                  name={'Неĸоммерчесĸие организации'}
                  activeFiltersList={selectedDirections}
                  options={nonprofit}
                  active={null}
                  onChange={handleSelectDirections}
                />
                <FilterGroup style={{ padding: 0, width: '100%' }}>
                  {directions.map(({ value, id }, i) => (
                    <FilterCheckbox
                      handleChange={() => handleSelectDirections(id)}
                      key={i}
                      isChecked={selectedDirections.includes(id)}
                    >
                      {value}
                    </FilterCheckbox>
                  ))}
                </FilterGroup>
              </div>

              <div className={styles['body-content-wrapper']}>
                <div className={styles.title}>
                  <H3Tag weight={'3'}>Статус работ</H3Tag>
                  {selectedRealisationStatus.length ? (
                    <Button
                      onClick={removeAllRealisationStatus}
                      className={styles['btn-clear']}
                      elementType={'button'}
                      size="s"
                      typeBtn={'uncolored'}
                      text={'Очистить'}
                      justifyContent={'end'}
                    />
                  ) : null}
                </div>
                <FilterGroup style={{ padding: 0, width: '100%' }}>
                  {realStatus.map(({ value, id }, i) => (
                    <FilterCheckbox
                      handleChange={() => handleSelectRealisation(id)}
                      key={i}
                      isChecked={selectedRealisationStatus.includes(id)}
                    >
                      {value}
                    </FilterCheckbox>
                  ))}
                </FilterGroup>
              </div>

              {editorMode && (
                <div className={styles['body-content-wrapper']}>
                  <div className={styles.title}>
                    <H3Tag>Статус реализации</H3Tag>
                    {selectedRealisationStatus.length > 0 && (
                      <Button
                        onClick={removeAllRealisationStatus}
                        className={styles['btn-clear']}
                        elementType={'button'}
                        size="s"
                        typeBtn={'uncolored'}
                        text={'Очистить'}
                        justifyContent={'end'}
                      />
                    )}
                  </div>
                  <FilterGroup rowLength={2} style={{ padding: 0, width: '100%' }}>
                    {showedRealisationStatuses.map(({ text, id }, i) => (
                      <FilterCheckbox
                        handleChange={() => handleSelectRealisation(id)}
                        key={i}
                        isChecked={selectedRealisationStatus.includes(id)}
                      >
                        {text}
                      </FilterCheckbox>
                    ))}
                  </FilterGroup>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.bottom__panel}>
          <FilterBottomPanel
            editorMode={editorMode}
            onReset={resetAllFilters}
            loading={loading}
            isResetButtonActive={
              !!externalMacroRegions.length ||
              !!externalProgram.length ||
              !!externalRealisationStatus.length ||
              !!externalStartOfBuilding.length ||
              !!externalTypeOfWork.length ||
              !!selectedNationalProjects.length ||
              !!selectedDirections.length ||
              showOnlyFavorite ||
              selectedDegreeOfSatisfaction.min !== RangeValues.MIN ||
              selectedDegreeOfSatisfaction.max !== RangeValues.MAX
            }
          ></FilterBottomPanel>
        </div>
      </>
    );
  },
);
