export const Layers = {
  D: 'districts',
  R: 'regions',
  O: 'objects',
} as const;

export enum ZoomBreakpoints {
  R = 7,
  O = 10,
}
