import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { objectApi } from '../api/object-api';
import { objectInitialState as initialState } from './initial-state';
import { ObjectInterface, Media } from '../../types';
import { loadIcons } from '../../utils';
import { FilterType } from '../../types/objects-state-type';

const objectSlice = createSlice({
  initialState,
  name: 'objects',
  reducers: {
    filterObjects: (state, { payload }: PayloadAction<FilterType>) => {
      state.filters = initialState.filters;
      const macroRegionFilter = [...state.filters.macro_region];
      const programFilter = [...state.filters.program];
      const statusFilter = [...state.filters.status];
      const startOfBuilding = [...state.filters.start_of_building];
      const typeOfWork = [...state.filters.type_of_work];
      const nationalProjects = [...state.filters.national_projects];
      const directions = [...state.filters.directions];
      const gazprom = [...state.filters.gazprom];

      if (payload?.macro_region && payload.macro_region.length) {
        payload?.macro_region.forEach((region: number) => {
          macroRegionFilter.push(region);
        });
      }
      if (payload?.program && payload?.program.length) {
        payload?.program.forEach((program: number) => {
          programFilter.push(program);
        });
      }
      if (payload?.status && payload?.status.length) {
        payload?.status.forEach((status: number) => {
          statusFilter.push(status);
        });
      }
      if (payload?.start_of_building && payload?.start_of_building.length) {
        payload?.start_of_building.forEach((startDate: number) => {
          startOfBuilding.push(startDate);
        });
      }
      if (payload?.type_of_work && payload?.type_of_work.length) {
        payload?.type_of_work.forEach((type: number) => {
          typeOfWork.push(type);
        });
      }
      if (payload?.national_projects && payload.national_projects.length) {
        payload?.national_projects.forEach((project: number) => {
          nationalProjects.push(project);
        });
      }
      if (payload?.directions && payload.directions.length) {
        payload?.directions.forEach((direction: number) => {
          directions.push(direction);
        });
      }
      if (payload?.gazprom && payload.gazprom.length) {
        payload?.gazprom.forEach((filter: number) => {
          gazprom.push(filter);
        });
      }
      const showOnlyFavorite = payload.showOnlyFavorite || false;
      state.filters = {
        national_projects: nationalProjects,
        directions: directions,
        macro_region: macroRegionFilter,
        status: statusFilter,
        program: programFilter,
        start_of_building: startOfBuilding,
        type_of_work: typeOfWork,
        showOnlyFavorite,
        degree_of_satisfaction: payload.degree_of_satisfaction,
        gazprom: gazprom,
      };
    },

    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },

  extraReducers: (builder) => {
    // GET OBJECTS
    builder.addMatcher(
      objectApi.endpoints.getBaseObjects.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.items = payload.data.filter((el: ObjectInterface) => {
          return el.macro_region !== '0';
        });
      },
    );
    builder.addMatcher(
      objectApi.endpoints.getObjects.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.items = payload;
      },
    );

    builder.addMatcher(
      objectApi.endpoints.addObject.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.items.push(payload.data);
      },
    );

    // DELETE OBJECT
    builder.addMatcher(
      objectApi.endpoints.deleteObject.matchFulfilled,
      (state, { payload }: AnyAction) => {
        const deleteElIdx = state.items.findIndex((el) => el.id === payload.data.id);

        if (deleteElIdx !== -1) {
          const newObjects = [...state.items];
          newObjects.splice(deleteElIdx, 1);
          (state.items as any) = [...newObjects];
        }
      },
    );

    // GET OBJECT TYPES
    builder.addMatcher(
      objectApi.endpoints.getObjectsTypes.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.types = loadIcons(payload.data);
      },
    );

    // GET OBJECTS PROGRAM
    builder.addMatcher(
      objectApi.endpoints.getObjectsProgram.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.programs = payload.data;
      },
    );

    // ADD OBJECT IMAGE
    builder.addMatcher(
      objectApi.endpoints.addObjectImage.matchFulfilled,
      (state, { meta, payload }: any) => {
        state.items.map((el: any) => {
          if (el.id === meta.arg.originalArgs.id) {
            el.media = [...el.media, payload.data];
          }
          return el;
        });
      },
    );

    // DELETE OBJECT IMAGE
    builder.addMatcher(
      objectApi.endpoints.deleteObjectImage.matchFulfilled,
      (state, { payload }: AnyAction) => {
        return state.items.forEach((el: any) => {
          if (el.id === payload.data.obj_id) {
            const index = el.media.findIndex((img: Media) => {
              return img.id === payload.data.id;
            }) as number;

            if (index !== -1) {
              const newMedia = [...el.media] as Media[];
              newMedia.splice(index, 1);
              el.media = [...newMedia];
            }
            return el;
          }
          return el;
        });
      },
    );

    // UPDATE OBJECT DATA
    builder.addMatcher(
      objectApi.endpoints.updateObjectData.matchFulfilled,
      (state, { payload }: AnyAction) => {
        const { id, type, value } = payload.data;
        const objectIndex = state.items.findIndex((el) => Number(el.id) === Number(id));
        // if (oldObjectId !== null && oldObjectId !== undefined) {
        (state.items as any)[objectIndex][type] = value;
        // const newState = [...state.items];
        // newState.splice(oldObjectId, 1);
        // newState.push(obj);
        // state.items = newState;

        // } else {
        //   state.items = [...state.items, obj];
        // }
      },
    );

    builder.addMatcher(
      objectApi.endpoints.updateObjectCoords.matchFulfilled,
      (state, { payload }: AnyAction) => {
        const { id, value } = payload.data;
        const objectIndex = state.items.findIndex((el) => Number(el.id) === Number(id));
        (state.items as any)[objectIndex].coords.coordinates = value;
      },
    );

    //ERROR UPDATE OBJECT DATA
    builder.addMatcher(
      objectApi.endpoints.updateObjectData.matchRejected,
      (state, { payload }: AnyAction) => {
        state.errors.updateObject = payload.status;
      },
    );
  },
});

export const { filterObjects, resetFilters } = objectSlice.actions;
export const { reducer: objectReducer } = objectSlice;
