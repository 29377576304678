import { AvatarProps } from './avatar.types';
import { IconEmptyProfile } from './icons';
import styles from './avatar.module.scss';


export const Avatar = ({ children,  src } : AvatarProps) => {
  if (!src) {
    return <IconEmptyProfile className={styles['empty-profile']}/>;
  }
  return  <img src={src} alt={'avatar'}/>;
};