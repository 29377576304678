import React, { forwardRef, RefAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ButtonProps } from './button-fabric.types';
import { Location } from '../../../types';


type CreateButton = {
  elementType: 'button' | 'anchor' | 'link' | 'input',
  classNames?: string,
  content?: JSX.Element,
  currentTitle?: string,
};


export const createButton = ({
  elementType,
  classNames,
  content,
  currentTitle,
} : CreateButton,
): RefAttributes<ButtonProps | HTMLAnchorElement> => {
  switch (elementType) {
    case 'button':
      return forwardRef<HTMLButtonElement>((props, ref) => {
        return (
          <button ref={ref} className={classNames} {...props}>
            {content}
          </button>
        );
      }) as RefAttributes<ButtonProps>;

    case 'anchor':
      return forwardRef<HTMLAnchorElement>((props, ref) => {
        return (
          <a ref={ref} className={classNames} {...props}>
            {content}
          </a>
        );
      }) as RefAttributes<ButtonProps>;

    case 'input':
      return forwardRef<any>((props, ref) => {
        return (
          <label ref={ref} htmlFor='file-input-button' className={classNames}>
            {content}
            <input id='file-input-button' type='file' value="" {...props} />
          </label>
        );
      }) as RefAttributes<any>;

    case 'link':
      return forwardRef<HTMLAnchorElement, any>((props, ref)=> {
        const { state, pathname } = useLocation() as Location;
        const initialState = [{ prevTitle: 'Главная', prevPath: '/' }];
        const setFrom = state?.from ? [...state.from] : initialState;


        return <Link ref={ref} state={{ from: [...setFrom, { prevTitle: currentTitle, prevPath: pathname }] }} className={classNames} {...props} >
          {content}
        </Link>;
        
      }) as RefAttributes<HTMLAnchorElement>;

    default:
      return <></>;
  }
};
