import styles from './text-element-fabric.module.scss';
import type { TextElementFabricProps, InsTagAttributesType, DelTagAttributesType } from './text-element-fabric.types';


export const TextElementFabric = ({
  x = '3.5',
  className = '',
  weight = 1,
  tag = 'span',
  children,
  ...props
}: TextElementFabricProps): JSX.Element => {

  if (tag === 'span') {
    return (
      <span
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props}
      >
        {children}
      </span>
    );
  }

  if (tag === 'b') {
    return (
      <b
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props}
      >
        {children}
      </b>
    );
  }

  if (tag === 'strong') {
    return (
      <strong
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props}
      >
        {children}
      </strong>
    );
  }

  if (tag === 'em') {
    return (
      <em
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props}
      >
        {children}
      </em>
    );
  }

  if (tag === 'mark') {
    return (
      <mark
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props}
      >
        {children}
      </mark>
    );
  }

  if (tag === 'small') {
    return (
      <small
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props}
      >
        {children}
      </small>
    );
  }

  if (tag === 'del') {
    return (
      <del
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props as DelTagAttributesType}
      >
        {children}
      </del>
    );
  }

  if (tag === 'ins') {
    return (
      <ins
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props as InsTagAttributesType}
      >
        {children}
      </ins>
    );
  }

  if (tag === 'sub') {
    return (
      <sub
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props}
      >
        {children}
      </sub>
    );
  }

  if (tag === 'sup') {
    return (
      <sup
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props}
      >
        {children}
      </sup>
    );
  }

  if (tag === 'i') {
    return (
      <i
        className={`${styles[`text-x${x.replace('.', '-')}-weight-${weight}`]} ${className}`}
        {...props}
      >
        {children}
      </i>
    );
  }
  return <></>;
};
