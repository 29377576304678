import React, { useEffect, useRef, useState } from 'react';
import styles from './card-view.module.scss';
import {
  setGalleryObjectId,
  useAppDispatch,
  useDeleteObjectSelector,
  useFilteredObjectsSelector,
} from '../../../state';
import { ObjectCard } from '../../object-card';
import { useEditorModeStateSelector } from '../../../state/selector';
import { useSelectObject } from '../../../hooks/depended/useSelectObject';
import { ObjectInterface } from '../../../types';
import { ViewWrapper } from '../view-wrapper';
import { getObjectPriorityList } from './utils';
import { objectPriorityList } from '../../../constants';
import { useViewModeSelector } from '../../../history-state/selectors';
import { Button } from '../../common';
import { HeaderMapWrapper } from '../../header';
// import { useFilteredProtoItems } from '../../../___prototype';

export const CardView = ({ isLoading = false }) => {
  const dispatch = useAppDispatch();
  //const objects = useFilteredObjectsSelector();
  const isEditor = useEditorModeStateSelector();
  const [selectedObjectId, setSelectedObjectId] = useSelectObject();
  //const list = getObjectPriorityList(objectPriorityList, objects);
  const [viewMode] = useViewModeSelector();
  const isDeleteObject = useDeleteObjectSelector();
  const cardViewContainerRef = useRef<HTMLDivElement | null>(null);
  const items = useFilteredObjectsSelector();
  // const items = useFilteredProtoItems()

  let selectedCard = null;
  const top = useRef<number | undefined>(null);

  const [showScrollTopButton, setShowScrollTopButton] = useState<boolean>(false);

  const scrollToCard = () => {
    if (cardViewContainerRef.current && top.current) {
      cardViewContainerRef.current.scrollBy({
        top: top.current - 100,
        behavior: 'auto',
      });
    }
  };

  const handleShowScrollTopBtn = () => {
    if (cardViewContainerRef.current) {
      cardViewContainerRef?.current?.scrollTop > 50
        ? setShowScrollTopButton(true)
        : setShowScrollTopButton(false);
    }
  };

  useEffect(() => {
    selectedCard = document.querySelector('[aria-selected="true"]');
    // @ts-ignore
    top.current = selectedCard?.getClientRects()[0]?.top;

    !isLoading && viewMode === 'list' && scrollToCard();

    if (cardViewContainerRef.current) {
      cardViewContainerRef?.current.addEventListener('scroll', handleShowScrollTopBtn);
    }

    return () => {
      if (cardViewContainerRef.current) {
        cardViewContainerRef?.current?.removeEventListener(
          'scroll',
          handleShowScrollTopBtn,
        );
      }
    };
  }, [viewMode, isLoading]);

  const handleScrollToTop = () => {
    if (cardViewContainerRef.current) {
      cardViewContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return viewMode === 'list' || isDeleteObject ? (
    <ViewWrapper>
      <HeaderMapWrapper isScrolling={showScrollTopButton} isLoading={isLoading} />
      <div className={styles.container} ref={cardViewContainerRef}>
        {items.length
          ? items.map((item: any) => {
              return (
                <ObjectCard
                  key={item.id}
                  object={item}
                  editorMode={isEditor}
                  role="redactor"
                  onClick={() => setSelectedObjectId(item.id)}
                  onImageClick={(image) => dispatch(setGalleryObjectId(image))}
                  selected={selectedObjectId === item.id}
                  onCloseSidebar={() => setSelectedObjectId(null)}
                />
              );
            })
          : null}
      </div>
      {showScrollTopButton && (
        <Button
          className={styles['scroll-to-top-btn']}
          onClick={handleScrollToTop}
          elementType={'button'}
          typeBtn={'elevation'}
          showRightIcon={true}
          rightIcon={<i className="icon icon-arrow-2-up icon-middle" />}
        />
      )}
    </ViewWrapper>
  ) : null;
};
