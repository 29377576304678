import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom';
import styles from './map-page.module.scss';
import {
  clearProject,
  useAppDispatch,
  useEditFieldSelector,
  useGetBaseObjectsMutation,
  useGetProjectMutation,
  useGetObjectsMutation,
  useGetUserInfoMutation,
  useObjectMonitoringProjectIdSelector,
  useDeleteObjectImageMutation,
  useAddObjectImageMutation,
} from '../../state';
import {
  AnalyticsViewWrapper,
  CardView,
  EditorView,
  Footer,
  MapZoomButtons,
  ObjectCreationView,
} from '../../components';
import SidebarFilterWrapper from '../../components/sidebar-filter/sidebar-filter-wrapper';
import { SidebarWrapper } from '../../components/sidebar/sidebar-wrapper';
import { useAppSelector, useGetObjectByIdSelector } from '../../state/selector';
import { HeaderMapWrapper } from '../../components/header/header-map/header-map-wrapper';
import { CoordsPanel } from '../../components/coords-panel/coords-panel';
import { ImageGalleryMap } from '../../components/image-gallery-map';
import { MapPageTypes } from './map-page.types';
import {
  HistoryCreateObject,
  HistorySelectedFilters,
} from '../../components/history-components';
import { MapLoader } from '../../components/common';
import { useObjectMediaState, useViewModeSelector } from '../../history-state/selectors';
import { GalleryType } from 'components/photos-switcher/photos-switcher.types';

const MapView = lazy(() =>
  import('../../components/view/map-view').then((module) => ({
    default: module.MapView,
  })),
);

export const MapPage = ({ children }: MapPageTypes): JSX.Element => {
  const [, { isLoading }] = useGetBaseObjectsMutation();
  const [getUserInfo] = useGetUserInfoMutation();
  const { user } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const editField = useEditFieldSelector();
  const { objectID } = useParams();
  const [getProject] = useGetProjectMutation();
  const monitoringProjectId = useObjectMonitoringProjectIdSelector(objectID);
  const object = useGetObjectByIdSelector(objectID);
  // const [getLines] = getAllLinesByProject
  const [viewMode] = useViewModeSelector();
  const [getObjectsMutate] = useGetObjectsMutation();
  const [searchObjectMedia, setSearchObjectMedia] = useObjectMediaState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteImageMutate] = useDeleteObjectImageMutation();
  const [addObjectImageMutate, { isError }] = useAddObjectImageMutation();

  const deleteImageHamdler = () => {
    deleteImageMutate({ id: Number(searchObjectMedia?.itemId) });
  };

  const addImageHandler = (image: File) => {
    addObjectImageMutate({ image, id: Number(objectID) });
  };

  const slideImageHandler = (imageId: number) => {
    searchObjectMedia &&
      setSearchObjectMedia(imageId, searchObjectMedia.type as GalleryType | undefined);
  };

  const closeGalleryHandler = () => {
    if (searchObjectMedia) {
      searchParams.delete(searchObjectMedia.type);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (monitoringProjectId) {
      getProject(monitoringProjectId);
    } else {
      dispatch(clearProject());
    }
  }, [monitoringProjectId]);

  useEffect(() => {
    // !items.length && getObjects();
    // !types.length && getObjectsTypes();
    // !favoriteItems.length && getFavorites();
    !user.profile && getUserInfo();
    getObjectsMutate();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={`${styles['map-wrapper']} map-container`}>
          {viewMode === 'map' && <HeaderMapWrapper isLoading={isLoading} />}
          {children}

          <Suspense fallback={<MapLoader />}>
            <MapView />
          </Suspense>

          {viewMode === 'map' && <MapZoomButtons />}
          <Footer />
          <CardView isLoading={isLoading} />
          {editField && <EditorView label={'Сохранить изменения'} />}
          <ObjectCreationView />

          <AnalyticsViewWrapper />
          <CoordsPanel />
          {object && searchObjectMedia && searchObjectMedia.type === 'object' && (
            <ImageGalleryMap
              items={object.media}
              closeGalleryHandler={closeGalleryHandler}
              deleteImageHamdler={deleteImageHamdler}
              addObjectImageHandler={addImageHandler}
              slideImageHandler={slideImageHandler}
            />
          )}
          {object && searchObjectMedia && searchObjectMedia.type === 'gratitude' && (
            <ImageGalleryMap
              items={object.gratitude}
              closeGalleryHandler={closeGalleryHandler}
              deleteImageHamdler={deleteImageHamdler}
              addObjectImageHandler={addImageHandler}
              slideImageHandler={slideImageHandler}
            />
          )}
          <HistorySelectedFilters />
          <HistoryCreateObject />
        </div>
        <SidebarFilterWrapper />
        <Routes>
          <Route path={'*'} element={<SidebarWrapper />}>
            <Route path={'group/:groupID'} element={<SidebarWrapper />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};
