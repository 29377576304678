import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './sidebar.module.scss';
import styles from './sidebar.module.scss';
import { ISidebarProps } from './sidebar.types';
import { StatusSwitcher } from '../status-switcher';
import {
  setAnalyticsViewOpen,
  setGalleryObjectId,
  setMonitoringOpen,
  useGalleryObjectIdSelector,
  useGetProjectSelector,
  useIsAnalyticsViewOpenSelector,
} from '../../state';
import { useDispatch, batch } from 'react-redux';
import { ObjectInterface } from '../../types';
import { SidebarHeader } from './sidebar-header';
import { SidebarImage } from './sidebar-image';
import { SidebarObjectInfo } from './sidebar-object-info';
import {
  fetchImage,
  getGroupData,
  getSortedProjectData,
  sortMonitoringContentItems,
} from 'utils';
import { useParams } from 'react-router-dom';
import { SidebarSwitchTabButtons } from './sidebar-switch-tab-buttons';
import { SidebarMonitoring } from 'components/sidebar-monitoring';
import { getMappedGalleryObject, getObjectImage } from 'utils/get-mapped-gallery-object';

export const Sidebar = ({
  object,
  onCloseClick,
  onImageClick,
  editorMode,
  onEditField,
  isObjectUpdating = false,
  onChangeObject,
  group,
  setGroup,
  isMonitoringActive = false,
}: ISidebarProps): JSX.Element => {
  const [tabId, setTabId] = useState<number>(0);
  // const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const project = useGetProjectSelector();
  const { groupID } = useParams();
  const objectIdRef = useRef<number | null | undefined>(null);
  const isAnalyticsViewOpen = useIsAnalyticsViewOpenSelector();
  const galleryImgId = useGalleryObjectIdSelector();
  const dispatch = useDispatch();

  const objectInfoSelectorHandler = useCallback(() => {
    setTabId(0);
  }, [tabId]);

  const monitoringSelectorHandler = useCallback(() => {
    setTabId(1);
  }, [tabId]);

  const handleChangeStatus = (value: boolean, id: number) => {
    return onChangeObject({
      id: id,
      type: 'real_status',
      value: value ? '2' : '1',
    });
  };

  const handleOpenAnalytics = () => {
    if (galleryImgId !== null) {
      batch(() => {
        dispatch(setGalleryObjectId(null));
        dispatch(setMonitoringOpen(false));
        dispatch(setAnalyticsViewOpen(!isAnalyticsViewOpen));
      });
    }
    if (galleryImgId !== null && isAnalyticsViewOpen) {
      dispatch(setAnalyticsViewOpen(!isAnalyticsViewOpen));
    }
    batch(() => {
      dispatch(setAnalyticsViewOpen(!isAnalyticsViewOpen));
      dispatch(setMonitoringOpen(false));
    });
  };

  useEffect(() => {
    if (groupID && project) {
      !tabId && setTabId(1);
      const group = getGroupData(project, Number(groupID));
      group && setGroup(group);
    } else {
      group && setGroup(null);
    }
  }, [groupID, project, object]);

  useEffect(() => {
    if (objectIdRef.current && objectIdRef.current !== object?.id) {
      tabId && setTabId(0);
    }
    objectIdRef.current = object?.id;
  }, [object]);

  useEffect(() => {
    return () => {
      group && setGroup(null);

      objectIdRef.current = null;
    };
  }, []);

  const getObjectImageUrl = useCallback(() => {
    if (object) {
      const photo = getObjectImage(object?.media, object.name, object.id);

      return fetchImage((photo?.compressed || photo?.thumb) as string);
    }
  }, [object]);

  return (
    <div className={styles.sidebar}>
      <>
        <div className={styles['fill-width-bg-element']}>
          <SidebarHeader
            title={object?.name}
            subtitle={object?.description_of_charitable_works}
            onCloseClick={onCloseClick}
          />
        </div>
        <SidebarImage getImage={getObjectImageUrl} alt={object?.name || ''} />
        <div className={styles['buttons-container']}>
          <SidebarSwitchTabButtons
            isMonitoringActive={!!tabId}
            isMonitoringDisabled={!isMonitoringActive}
            setInfo={objectInfoSelectorHandler}
            setMonitoring={monitoringSelectorHandler}
          />
        </div>
        <StatusSwitcher
          editorMode={editorMode}
          loading={isObjectUpdating}
          checked={object ? Number(object?.real_status) === 2 : false}
          onChangeStatus={(value) => handleChangeStatus(value, Number(object?.id))}
        />
        {tabId === 0 && (
          <SidebarObjectInfo
            object={object as unknown as ObjectInterface}
            editorMode={editorMode}
            onCloseClick={onCloseClick}
            onImageClick={onImageClick}
            onEditField={onEditField}
            isObjectUpdating={isObjectUpdating}
            onChangeObject={onChangeObject}
            isMonitoringActive={isMonitoringActive}
            handleOpenAnalytics={handleOpenAnalytics}
            isAnalyticsViewOpen={isAnalyticsViewOpen}
          />
        )}
        {tabId === 1 && project && (
          <SidebarMonitoring
            isGroup={!!groupID}
            items={
              !groupID
                ? getSortedProjectData(project)
                : sortMonitoringContentItems(group ? group.lines : [])
            }
          />
        )}
      </>
    </div>
  );
};
