import type { Group, LineData, ProjectData } from '../types/monitoring';


export const sortMonitoringContentItems = (list: Group[] | LineData[]) => {
  return [...list]
    .sort((prev, cur) => prev.updatedAt < cur.updatedAt ? 1 : -1) as Group[] | LineData[]; 
};

export const getGroupData = (project: ProjectData, groupId: number) => {
  return project.groups.find(groupItem => groupItem.id === groupId) ;
};

export const getLastDateForGroup = (lines: LineData[]) => {
  const list = sortMonitoringContentItems(lines);

  return list[0].contentLastUpdated.length 
    ? list[0].contentLastUpdated
    : list[0].updatedAt;
};

export const getSortedProjectData = (project: ProjectData) => {
  let items: Group[] | LineData | LineData[] = [];

  if (project.groups) {
    items = [
      ...sortMonitoringContentItems(project.groups), 
    ] as Group[];
  }

  if (project.lines) {
    items = [
      ...items, 
      ...sortMonitoringContentItems(project.lines),
    ] as Group[] | LineData[];
  }
  return items;

  return [];
};