import { FilterBottomPanelProps } from './filter-bottom-panel.types';
import styles from './filter-bottom-panel.module.scss';
import React from 'react';
import { Button } from '../../common';
import { IconProgress } from '../progress-icon';


export const FilterBottomPanel = ({ children, editorMode, onReset, loading, isResetButtonActive = false } : FilterBottomPanelProps) => {
  return  <div className={styles['filter-bottom-panel']}>
    <Button 
      onClick={onReset} 
      elementType={'button'} 
      size="m" 
      typeBtn={'secondary'} 
      text={isResetButtonActive ? 'Очистить все' : 'Выберете фильтры'}
      justifyContent={'center'}
      disabled={!isResetButtonActive}
    />
    {/* <div className={styles['filter-bottom-panel-content-wrapper']}>
      {children}
      {editorMode && <Button
        onlyIcon={true}
        elementType={'button'}
        size='l'
        typeBtn={'secondary'}
        showRightIcon={true}
        rightIcon={loading ? <IconProgress stroke={'#000'}/> : <i className="icon icon-download icon-middle"></i>}/>}
    </div> */}
  </div>;
};