import { devNull } from 'os';
import { Media } from '../types';

export const getMappedGalleryObject = (
  media: Media[],
  name: string,
  objectId: number,
) => {
  return media && media.length > 0
    ? media.map(({ id, path, path_thumbnail }) => {
        return {
          image: !!path ? path : null,
          compressed: !!path ? path.replace('/media', '/media/compressed') : null,
          thumb: path_thumbnail ? path_thumbnail : null,
          alt: `${name} фотография`,
          id,
          object_id: objectId,
        };
      })
    : [];
};

export const getObjectImage = (media: Media[], name: string, objectId: number) => {
  if (media && media.length) {
    return {
      image: !!media[0].path ? media[0].path : null,
      compressed: !!media[0].path
        ? media[0].path.replace('/media', '/media/compressed')
        : null,
      thumb: !!media[0].path_thumbnail ? media[0].path_thumbnail : null,
      alt: `${name} фотография`,
      id: media[0].id,
      object_id: objectId,
    };
  }

  return;
};
