import { addTokens, store } from 'state';

const getUser = () => {
  try {
    const { user } = JSON.parse(localStorage.getItem('persist:root') as string);

    return JSON.parse(user);
  } catch (error) {
    return undefined;
  }
};

const refreshTokenQuery = async (token: string | undefined) => {
  try {
    return await fetch(`${process.env.REACT_APP_BACKEND_API_PATH}/refresh-token`, {
      method: 'POST',
      body: JSON.stringify({
        refresh_token: token,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      return Promise.reject();
    });
  } catch (error) {
    console.error(error);
  }
};

export async function fetchImage(url: string): Promise<string | undefined> {
  const imgSrc = url[0] === '/' ? url : `/${url}`;

  const user = getUser();
  const { token } = user;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API_PATH}/media?media_path=${encodeURIComponent(
        imgSrc,
      )}`,
      {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      },
    );

    if (response && response.ok) {
      const imageBlob = await response.blob();

      return URL.createObjectURL(imageBlob);
    }

    if (response && response.status === 401) {
      const { access_token: accessToken, refresh_token: refreshToken } =
        await refreshTokenQuery(token.refreshToken);
      store.dispatch(
        addTokens({
          accessToken,
          refreshToken,
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}
