import './creation-form.scss';


const CreationFormTextarea = props => {
  const { label, placeholder, value, type = 'text', autocomplete = 'off', isError, error, ...rest } = props;

  return (
    <label className="creation-form-textarea-container">
      <span className={`${isError ? 'creation-form-label-error' : ''}`}>{label}</span>
      <textarea
        className={`creation-form-textarea ${isError ? 'error' : ''}`}
        placeholder={placeholder}
        value={value}
        autoComplete={autocomplete}
        {...rest}
      />
      {isError && (
        <span className="creation-form-invalid-field">{error}</span>
      )}
    </label>
  );
};

export default CreationFormTextarea;
