import { useNavigate, useParams } from 'react-router-dom';
import { useProjectByIdSelector, useSelectedLineContentSelector } from '../../state';
import { ContentTypes, LineData } from '../../types/monitoring';
import {
  LINK_LINE_DATE_TYPE_CONTENT_PATH,
  LINK_LINE_DATE_TYPE_PATH,
  ScreenSize,
} from '../../constants';
import { useBreakpointsComparison } from '../../hooks';
import { ViewingContentDesktop } from './viewing-content.desktop';
import { useEffect, useMemo } from 'react';

export const ViewingContent = () => {
  const { projectID, lineID, contentDate, contentType, groupID, contentID, objectID } =
    useParams();
  const navigate = useNavigate();
  const lineContent = useSelectedLineContentSelector(lineID as string);
  const project = useProjectByIdSelector(projectID);
  const isMobile = useBreakpointsComparison(ScreenSize.MP);
  const projectLines =
    project && [...project.lines, ...project?.groups?.map((group) => group.lines)].flat();
  const line =
    projectLines &&
    projectLines.find((line: LineData) => line.id === parseInt(lineID as string));
  const lineContentDate = contentDate;

  const createPath = (localContentType: ContentTypes, line: LineData) => {
    if (contentID && localContentType === contentType) {
      return LINK_LINE_DATE_TYPE_CONTENT_PATH(
        objectID as string,
        projectID as string,
        lineID as string,
        lineContentDate as string,
        localContentType,
        contentID,
      );
    } else {
      return LINK_LINE_DATE_TYPE_PATH(
        objectID as string,
        projectID as string,
        lineID as string,
        lineContentDate as string,
        localContentType,
      );
    }
  };

  const isContentBtnDisabled = (contentType: ContentTypes) =>
    lineContent?.payload.some(
      (obj: any) => obj?.contentType === contentType && obj?.data.length > 0,
    );

  const avaibleTypes = useMemo(() => {
    return lineContent?.payload.filter((obj: any) => !!obj?.data.length);
  }, [lineContent]);

  const handleClickContentBtn = (type: ContentTypes) => {
    navigate(createPath(type, line as LineData));
  };

  return (
    <ViewingContentDesktop
      activeView={contentType}
      isContentBtnDisabled={isContentBtnDisabled}
      handleClickContentBtn={handleClickContentBtn}
    />
  );
};
