import type { LayerType } from '../../../types';


export const mapInitialState: MapInitialStateType = {
  level: {
    type: 'main',
    object: null,
    color: null,
    render: false,
  },
  layer: 'districts',
  galleryObject: null,
  isMouseZoomActive: true,
  typeMap: 'normal',
  variationsHeatMap: ['blue', 'green', 'yellow', 'red'],
  clickPosition: null,
};

interface MapInitialStateType {
  level: LevelType,
  layer: LayerType,
  galleryObject: null,
  isMouseZoomActive: boolean,
  typeMap: string,
  variationsHeatMap: string[],
  clickPosition: null | any,
}

interface LevelType {
  type: string,
  object: null,
  color: null,
  render: boolean,
}
