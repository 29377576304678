import { useState, useRef } from 'react';
import { Image } from 'components';
import { useIntersectionObserver } from 'hooks';
import type { ImageWrapperProps } from './image-wrapper.types';
import { fetchImage } from 'utils';
import styles from './image-wrapper.styles.module.scss';

export const ImageWrapper = ({
  src,
  alt,
  thumb,
  onLoad = () => {},
  wrapperStyles = {},
  ...props
}: ImageWrapperProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [image, setImage] = useState<string | undefined>(undefined);

  const getImageHandler = async () => {
    const resImage = await fetchImage(src);

    setImage(resImage);
  };

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        if (!isVisible) {
          if (!!src) {
            setIsVisible(true);

            getImageHandler();
          }
        }
        ref.current && observerElement.unobserve(ref.current);
      }
    },
  });

  return (
    <div
      ref={ref}
      className={styles.container}
      style={{ width: '100%', ...wrapperStyles }}
    >
      <Image
        className={styles.image}
        onLoad={onLoad}
        src={image}
        thumb={thumb}
        alt={alt}
        {...props}
      />
    </div>
  );
};
