import { regions } from './regions';

export const getActiveRegions = async (activeIdsList: number[]) => {
  const result = await Promise.all(
    regions.map(async (item: any) => {
      if (activeIdsList.includes(item.id)) {
        return {
          ...item,
          data: await import(`./${item.id}.json`).then((data) => {
            return data.default;
          }),
        };
      }
      return;
    }),
  );

  return result.filter((item: any) => !!item);
};
