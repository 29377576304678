import styles from './button-group.module.scss';
import cn from 'classnames';
import { Buttongroup } from './button-group.types';


export const ButtonGroup = ({ 
  direction, 
  children, 
  withBackground, 
  gap = 0, 
  justifyContent = 'center', 
  maxWidth = 'content',  
  className = '' }: Buttongroup): JSX.Element => {
  const buttonGroupClasses = cn(styles['button-group'], {
    [styles['button-group-row']]: direction === 'row',
    [styles['button-group-col']]: direction === 'column',
    [styles['button-group-gap-0']]: gap === 0,
    [styles['button-group-gap-4']]: gap === 4,
    [styles['button-group-gap-8']]: gap === 8,
    [styles['button-group-gap-12']]: gap === 12,
    [styles['button-group-gap-16']]: gap === 16,
    [styles['button-group-gap-20']]: gap === 20,
    [styles['button-group-gap-24']]: gap === 24,
    [styles['button-group-transparent']]: withBackground === false,
    [`${styles.contentEnd}`]: justifyContent === 'end',
    [`${styles.contentStart}`]: justifyContent === 'start',
    [`${styles.contentCenter}`]: justifyContent === 'center',
    [`${styles.contentBetween}`]: justifyContent === 'between',
    [`${styles['w-fill']}`]: maxWidth === 'fill',
    [`${styles['w-content']}`]: maxWidth === 'content',
  });

  return <div className={`${buttonGroupClasses} ${className}`}>{children}</div>;
};
