import { SwitchProps } from './switch.types';
import styles from './switch.module.scss';


export const Switch = ({
  showLabel,
  text = 'Label',
  isDisabled = false,
  checked = false,
  onToggle,
  id,
}: SwitchProps) => {
  
  const handleToggle = () => {
    onToggle(!checked);
  };

  return (
    <div className={styles['switch-container']}>
      <input
        disabled={isDisabled}
        className={styles['switch-checkbox']}
        id={id}
        checked={checked}
        // defaultChecked={checked}
        type="checkbox"
        onChange={handleToggle}
      />
      <label className={styles['switch-label']} htmlFor={id}>
        <span className={styles['switch-button']} />
      </label>
      {showLabel && <span className={styles['switch-label-text']}>{text}</span>}
    </div>
  );
};
