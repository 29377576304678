import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ObjectInterface, RootState } from '../../types';
import { DispatchType } from '../../types';

export const useAppDispatch = () => useDispatch<DispatchType>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppObjects = () => {
  const { items } = useAppSelector(({ objects }) => objects);

  return items;
};
