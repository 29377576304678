import type { ObjectType } from '../types';
// import leisureIcon from '../images/leisure.svg';
// import leisureActiveIcon from '../images/leisure-active.svg';
// import leisureSelectIcon from '../images/leisure-select.svg';
// import worship from '../images/worship.svg';
// import worshipSelectIcon from '../images/worship-select.svg';
// import worshipActiveIcon from '../images/worship-active.svg';
// import sportIcon from '../images/sport.svg';
// import sportSelectIcon from '../images/sport-select.svg';
// import sportActiveIcon from '../images/sport-active.svg';
// import schoolIcon from '../images/school.svg';
// import schoolSelectIcon from '../images/school-select.svg';
// import schoolActiveIcon from '../images/school-active.svg';
// import medicineIcon from '../images/medicine.svg';
// import medicineSelectIcon from '../images/medicine-select.svg';
// import medicineActiveIcon from '../images/medicine-active.svg';

import leisureIcon from '../icons/prototype/residential-complex.svg';
import leisureActiveIcon from '../icons/prototype/residential-complex.svg';
import leisureSelectIcon from '../icons/prototype/residential-complex.svg';

import worship from '../icons/prototype/religious-object.svg';
import worshipSelectIcon from  '../icons/prototype/religious-object.svg';
import worshipActiveIcon from  '../icons/prototype/religious-object.svg';

import sportIcon from '../icons/prototype/sports-institution.svg';
import sportSelectIcon from  '../icons/prototype/sports-institution.svg';
import sportActiveIcon from  '../icons/prototype/sports-institution.svg';

import schoolIcon from '../icons/prototype/educational-institution.svg';
import schoolSelectIcon from '../icons/prototype/educational-institution.svg';
import schoolActiveIcon from '../icons/prototype/educational-institution.svg';

import medicineIcon from '../icons/prototype/medical-institution.svg';
import medicineSelectIcon from '../icons/prototype/medical-institution.svg';
import medicineActiveIcon from '../icons/prototype/medical-institution.svg';


export const loadIcons = (types: ObjectType[]) => {
  return types.map(item => {
    switch (item.name) {
      case 'культовый': {
        return {
          ...item,
          icon: worship,
          iconActive: worshipActiveIcon,
          iconSelect: worshipSelectIcon,
        };
      }
      case 'культурно-досуговый': {
        return {
          ...item,
          icon: leisureIcon,
          iconActive: leisureActiveIcon,
          iconSelect: leisureSelectIcon,
        };
      }
      case 'физкультурно-оздоровительный': {
        return {
          ...item,
          icon: sportIcon,
          iconActive: sportActiveIcon,
          iconSelect: sportSelectIcon,
        };
      }
      case 'образовательный': {
        return {
          ...item,
          icon: schoolIcon,
          iconActive: schoolActiveIcon,
          iconSelect: schoolSelectIcon,
        };
      }
      case 'медицинский': {
        return {
          ...item,
          icon: medicineIcon,
          iconActive: medicineActiveIcon,
          iconSelect: medicineSelectIcon,
        };
      }
      default: {
        return item;
      }
    }
  });
};