import { useState } from 'react';
import { Button, FilterCheckbox, FilterGroup, PTag, SpanTag } from '../common';
import styles from './options-list.styles.module.scss';
import type { OptionsListProps } from './options-list.types';

export const OptionsList = ({
  children,
  options,
  onChange,
  name,
  activeFiltersList,
}: OptionsListProps) => {
  const [isOpen, setOpen] = useState<boolean>(() => {
    const items = options.filter((item) => activeFiltersList.includes(item.id));
    if (items.length) return true;
    return false;
  });

  return (
    <div className={styles.container}>
      <PTag className={styles['container-header']} onClick={() => setOpen(!isOpen)}>
        {name}
        <SpanTag
          className={`${styles['container-header-arrow']} ${
            isOpen ? styles['container-header-arrow_opened'] : ''
          }`}
        >
          <i className="icon icon-arrow-2-down icon-middle"></i>
        </SpanTag>
      </PTag>
      <FilterGroup
        className={`${styles['container-list']} ${
          isOpen ? styles['container-list_opened'] : ''
        }`}
      >
        {options.length &&
          options.map((item: { value: string; id: number }) => {
            return (
              <FilterCheckbox
                handleChange={() => onChange(item.id)}
                key={item.id}
                isChecked={activeFiltersList.includes(item.id)}
              >
                {item.value}
              </FilterCheckbox>
            );
          })}
      </FilterGroup>
    </div>
  );
};
