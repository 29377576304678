import type { GazpromClusterContentProps } from './content.types';
import { createClusterGazpromIcon } from 'components/utils';
import { Content } from './content';

export const GazpromClusterContent = ({ items, types }: GazpromClusterContentProps) => {
  return (
    <Content
      iconCreateFunction={createClusterGazpromIcon}
      isGazpromChild={true}
      items={items}
      types={types}
    />
  );
};
