import React, { useState } from 'react';
import { DatePicker } from '../date-picker';
import { Button, Dropdown, PTag } from '../common';
import {
  convertDateToddMM,
  convertDateToDDMMYYYY,
  convertDateToYYYYMMDD,
} from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';

import { useBreakpointsComparison, useSearchParamsAsObject } from '../../hooks';
import { ContentTypes } from '../../types/monitoring';
import { RouteComponentProps } from '../../types';
import styles from './calendar-media.module.scss';
import { useAppSelector, useAvailableDatesAllContentSelector } from '../../state';
import {
  LINK_LINE_DATE_TYPE_CONTENT_PANORAMA_PATH,
  LINK_LINE_DATE_TYPE_PATH,
  ScreenSize,
} from '../../constants';
import type { HeaderProps } from './calendar-media.types';

export const CalendarMediaHeader = ({
  date = new Date(),
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  months,
  years,
  changeYear,
  changeMonth,
}: HeaderProps) => {
  const isTablet = useBreakpointsComparison(ScreenSize.TP);

  return (
    <div className={styles['date-picker-header']}>
      <div className={styles['date-picker-header-content ']}>
        <div className={styles['date-picker__buttons-container_inner']}>
          <Button
            className={styles['date-picker__buttons-container_inner-arrow-button']}
            typeBtn={'uncolored'}
            elementType={'button'}
            showRightIcon
            rightIcon={
              <i
                className={`icon icon-arrow-2-left icon-${isTablet ? 'tiny' : 'middle'}`}
              ></i>
            }
            onClick={decreaseMonth}
            size={'m'}
            disabled={prevMonthButtonDisabled}
          />
          <PTag
            x={'4'}
            weight={3}
            className={styles['date-picker__buttons-container_inner-date']}
          >
            {months[date.getMonth()]} {date.getFullYear()}
          </PTag>
          <Button
            className={styles['date-picker__buttons-container_inner-arrow-button']}
            typeBtn={'uncolored'}
            elementType={'button'}
            showRightIcon
            rightIcon={
              <i
                className={`icon icon-arrow-2-right icon-${isTablet ? 'tiny' : 'middle'}`}
              ></i>
            }
            onClick={increaseMonth}
            size={'m'}
            disabled={prevMonthButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export const CalendarMedia = ({
  location,
}: RouteComponentProps<{
  projectID: string;
  lineID: string;
  contentDate: string;
  contentType: ContentTypes;
  contentID: string;
}>) => {
  const navigate = useNavigate();
  const { projectID, lineID, contentDate, contentType, contentID, groupID, objectID } =
    useParams();
  const [startDate, setStartDate] = useState(
    () => new Date((contentDate as string) || new Date()),
  );
  const availableDates = useAvailableDatesAllContentSelector(lineID as string);
  const [params] = useSearchParamsAsObject();
  const isTablet = useBreakpointsComparison('TL');
  const isMobile = useBreakpointsComparison('MP');
  const [open, setOpen] = useState(false);
  const indexOfDate = availableDates ? availableDates.indexOf(String(contentDate)) : null;
  const nextDateAvailable = indexOfDate !== null && indexOfDate > 0;
  const prevDateAvailable =
    indexOfDate !== null &&
    !!availableDates &&
    indexOfDate >= 0 &&
    indexOfDate < availableDates.length - 1;

  const rightDateChange = () => {
    if (indexOfDate && indexOfDate > 0) {
      const prevDate = availableDates[Number(indexOfDate) - 1];
      setStartDate(new Date(prevDate));
      if (contentID) {
        navigate(
          LINK_LINE_DATE_TYPE_CONTENT_PANORAMA_PATH(
            String(objectID),
            String(projectID),
            String(lineID),
            convertDateToYYYYMMDD(prevDate),
            String(contentType),
            contentID,
            params,
            groupID,
          ),
        );
      } else {
        navigate(
          LINK_LINE_DATE_TYPE_PATH(
            String(objectID),
            String(projectID),
            String(lineID),
            convertDateToYYYYMMDD(prevDate),
            contentType as string,
            groupID,
          ),
        );
      }
    }
  };
  const leftDateChange = () => {
    if (indexOfDate !== availableDates.length - 1) {
      const nextDate = indexOfDate >= 0 && availableDates[Number(indexOfDate) + 1];
      setStartDate(new Date(nextDate));
      if (contentID) {
        navigate(
          LINK_LINE_DATE_TYPE_CONTENT_PANORAMA_PATH(
            String(objectID),
            String(projectID),
            String(lineID),
            convertDateToYYYYMMDD(nextDate),
            contentType as string,
            contentID,
            params,
            groupID,
          ),
        );
      } else {
        navigate(
          LINK_LINE_DATE_TYPE_PATH(
            String(objectID),
            String(projectID),
            String(lineID),
            convertDateToYYYYMMDD(nextDate),
            contentType as string,
            groupID,
          ),
        );
      }
    }
  };
  const datePickerButton = (
    <div className={styles['date-picker-trigger-container']}>
      <Button
        size={'l'}
        typeBtn={'elevation'}
        elementType={'button'}
        showRightIcon
        rightIcon={<i className="icon icon-calendar icon-middle"></i>}
        onClick={() => setOpen(!open)}
        isActive={open}
        text={`${convertDateToDDMMYYYY(contentDate ? contentDate : String(new Date()))}`}
      />
    </div>
  );

  const datePickerButtonMobile = (
    <div className={styles['date-picker-trigger-container']}>
      <Button
        size={isMobile ? 's' : 'm'}
        typeBtn={'elevation'}
        className={styles['date-picker-wrapper-trigger-container-button']}
        elementType={'button'}
        showRightIcon
        onClick={() => setOpen(!open)}
        isActive={open}
      >
        <div className={styles['date-picker__button-mobile']}>
          <div className={styles['date-picker__button-mobile_first-title']}>
            {convertDateToddMM(contentDate || String(new Date()))[0]}
          </div>
          <div className={styles['date-picker__button-mobile_second-title']}>
            {convertDateToddMM(contentDate || String(new Date()))[1]}
          </div>
        </div>
      </Button>
    </div>
  );

  const onSelected = (date: Date) => {
    setOpen(false);
    setStartDate(date);
    if (date) {
      if (contentID) {
        navigate(
          LINK_LINE_DATE_TYPE_CONTENT_PANORAMA_PATH(
            String(objectID),
            String(projectID),
            String(lineID),
            convertDateToYYYYMMDD(date),
            contentType as string,
            contentID,
            params,
            groupID,
          ),
        );
      } else {
        navigate(
          LINK_LINE_DATE_TYPE_PATH(
            String(objectID),
            String(projectID),
            String(lineID),
            convertDateToYYYYMMDD(date),
            contentType as string,
            groupID,
          ),
        );
      }
    }
  };

  const isHasOnAvailable = (date: Date) =>
    availableDates && availableDates.includes(convertDateToYYYYMMDD(date));

  const range = (start: number, end: number) => {
    return new Array(end - start).fill(0).map((d, i) => i + start);
  };

  const years = range(1900, new Date().getFullYear() + 1);
  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  return (
    <div className={styles['date-picker-wrapper']}>
      {!isTablet && (
        <div className={styles['date-picker-wrapper-trigger-arrow-wrapper']}>
          <Button
            size={'l'}
            typeBtn={'elevation'}
            elementType={'button'}
            showLeftIcon
            leftIcon={<i className="icon icon-arrow-2-left icon-middle"></i>}
            onlyIcon
            onClick={leftDateChange}
            disabled={!prevDateAvailable}
          />
        </div>
      )}
      {!open && !isTablet ? (
        datePickerButton
      ) : !open && isTablet ? (
        datePickerButtonMobile
      ) : (
        <Dropdown
          trigger={isTablet ? datePickerButtonMobile : datePickerButton}
          body={
            <DatePicker
              renderCustomHeader={(props: HeaderProps) => (
                <CalendarMediaHeader {...props} years={years} months={months} />
              )}
              inline
              onChange={onSelected}
              selected={new Date((contentDate as string) || new Date())}
              filterDate={isHasOnAvailable}
              startDate={startDate}
              useShortMonthInDropdown
            />
          }
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isVisible={open}
          bottomSheetHeight={60}
          isBottomSheet={isMobile}
          shouldCloseOnSelect={false}
        />
      )}
      {!isTablet && (
        <div className={styles['date-picker-wrapper-trigger-arrow-wrapper']}>
          <Button
            onlyIcon
            size={'l'}
            typeBtn={'elevation'}
            elementType={'button'}
            showRightIcon
            disabled={!nextDateAvailable}
            rightIcon={<i className="icon icon-arrow-2-right icon-middle"></i>}
            onClick={rightDateChange}
          />
        </div>
      )}
    </div>
  );
};
